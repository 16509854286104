import authEvents from "./auth";
import twilioEvents from "./twilio";
import callEvents from "./call";
import realtimeSubscriptions from "./realtime";

export default {
  init(EventBus: any) {
    authEvents();
    twilioEvents();
    callEvents();
    EventBus.$on("realtime/init", () => {
      const cleanupRealtimeSubscriptions = realtimeSubscriptions();
      EventBus.$on("realtime/stop", () => {
        cleanupRealtimeSubscriptions();
      });
    });
  }
};
