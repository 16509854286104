import { GetterTree } from "vuex";
import { QueueState, RootState } from "@/types/store";
import find from "lodash/find";
import cloneDeep from "lodash/cloneDeep";

const findQueueByLabel = (queueCollection: QueueState[], label: string) =>
  find(queueCollection, { label });

export const getters: GetterTree<QueueState[], RootState> = {
  size: state => (label: string) => {
    if (typeof label !== "string") {
      return Number.NaN;
    }
    const queue = findQueueByLabel(state, label);
    if (!queue) {
      return Number.NaN;
    }

    return queue.size;
  },
  visitors: state => (label: string) => {
    if (typeof label !== "string") {
      return [];
    }
    const queue = findQueueByLabel(state, label);
    if (!queue) {
      return [];
    }

    return queue.visitors;
  },
  queue: state => (label: string) => {
    if (typeof label !== "string") {
      return null;
    }

    const queue = findQueueByLabel(state, label);
    if (!queue) {
      return null;
    }

    return queue;
  },
  all: state => state
};
