














































































































import { Component, Vue } from "vue-property-decorator";
import voiceConfig from "@/config/voice";
import conf from "@/config";
import ReferencePreview from "./ReferencePreview.vue";
import ClientTabs from "./client-tabs/Main.vue";
import Scripts from "./Scripts.vue";

@Component({
  components: {
    ReferencePreview,
    ClientTabs,
    Scripts
  }
})
export default class Panels extends Vue {
  get microphoneAccess() {
    return this.$store.getters["voice/microphone"];
  }

  get isAuthenticated() {
    return this.$store.getters["auth/loggedIn"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get isHandlingCall() {
    return (
      this.isAuthenticated &&
      [
        voiceConfig.status.ongoing,
        voiceConfig.status.wrappingUp,
        voiceConfig.status.handingOff,
        voiceConfig.status.supervising,
        voiceConfig.status.ended
      ].includes(this.callStatus) &&
      [
        conf.agent.statuses.inCall,
        conf.agent.statuses.wrappingUpCall,
        conf.agent.statuses.supervisingCall,
        conf.agent.statuses.surveillingCall,
        conf.agent.statuses.assessingCall
      ].includes(this.sessionStatus)
    );
  }
}
