


























































import { Component, Prop, Vue } from "vue-property-decorator";
import VoiceHeader from "./voice/Header.vue";
import conf, { app } from "@/config";
import Sidebar from "./layout/sidebar/Main.vue";
import auth from "@/services/auth";
import References from "./common/references/Main.vue";
import Census from "./common/census/Main.vue";
import Panels from "./layout/panels/Main.vue";
import UserActions from "./User.vue";
import config from "@/config";
import { EventBus } from "@/eventBus";
import { failure } from "@/config/helpers";

@Component({
  components: {
    VoiceHeader,
    Sidebar,
    References,
    Panels,
    UserActions,
    Census
  }
})
export default class MainLayout extends Vue {
  private contexts = app.contexts;
  private showReferenceOrCensusTab = 0;
  private tenantAbbr: string = process.env.VUE_APP_TENANT || "NSAH";
  private s3Baseurl: string = process.env.VUE_APP_S3_BASEURL || "";

  get dnd() {
    return this.status === config.agent.statuses.dnd;
  }

  get status() {
    return this.$store.getters["session/status"];
  }

  get sidebarsState() {
    return this.$store.getters["layout/sidebars"];
  }

  mounted() {
    EventBus.$on("show-references-tab", () => {
      this.showReferenceOrCensusTab = 0;
    });
    EventBus.$on("show-census-tab", () => {
      this.showReferenceOrCensusTab = 1;
    });

    EventBus.$on("error-notification", (message: string) => {
      failure(this, message);
    });
  }

  private get application() {
    return this.$store.getters.application;
  }

  private get logo() {
    switch (this.tenantAbbr.trim().toLowerCase()) {
      case "nsah":
        return !this.sidebarsState.left && this.dnd
          ? `${this.s3Baseurl}/images/rainn_logo_dnd.svg`
          : `${this.s3Baseurl}/images/rainn_logo.svg`;
      case "ncvc":
        return `${this.s3Baseurl}/images/ncvc_logo.png`;
      case "demo":
        return `${this.s3Baseurl}/images/demo_logo.png`;
      case "shl":
        return `${this.s3Baseurl}/images/shl_logo.png`;
      default:
        return `${this.s3Baseurl}/images/rainn_logo.svg`;
    }
  }
}
