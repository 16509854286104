







































import Vue from "vue";
import auth from "@/services/auth";
import config from "@/config";
import { FormError } from "@/types/validation";
import router from "../router";
import { session } from "../services/session";
import isPlainObject from "lodash/isPlainObject";
export default Vue.extend({
  data() {
    return {
      email: {
        value: "",
        error: {
          type: "",
          message: ""
        }
      },
      password: {
        value: "",
        error: {
          type: "",
          message: ""
        }
      }
    };
  },
  computed: {
    hasErrors() {
      const nonEmptyString = (x: any) =>
        typeof x === "string" && Boolean(x.trim().length);

      const { email, password } = this.$data;

      const emailHasErrors =
        isPlainObject(email) &&
        isPlainObject(email.error) &&
        nonEmptyString(email.error.message);

      const passwordHasErrors =
        isPlainObject(password) &&
        isPlainObject(password.error) &&
        nonEmptyString(password.error.message);

      return emailHasErrors || passwordHasErrors;
    }
  },
  methods: {
    async submit() {
      if (!this.email.value || this.email.value.length < 1) {
        const err = {
          type: "is-danger",
          message: "E-mail is required to login."
        };

        this.email.error = err;
      } else {
        this.password.error = {
          type: "",
          message: ""
        };
      }

      if (!this.password.value || this.password.value.length < 1) {
        const err = {
          type: "is-danger",
          message: "A password is required to login."
        };

        this.password.error = err;
      } else {
        this.password.error = {
          type: "",
          message: ""
        };
      }

      if (!this.hasErrors) {
        const data = {
          email: this.email.value,
          password: this.password.value
        };

        await this.$store.dispatch("auth/login", data);
        await session.generate();

        if(this.$route.name !== 'home'){
          this.$router.push({ name: "home" }).catch(error => {
          console.error(
            "error occured on homepage redirect after successful auth",
            error
          );
        });
        }
      }
    }
  }
});
