var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{class:{
    'fg-white bg-primary-dark': !_vm.rightSidebar && !_vm.dnd,
    'fg-white bg-orange-dark': !_vm.rightSidebar && _vm.dnd,
  },attrs:{"id":"user"}},[_c('b-tabs',{class:{ 'is-invisible': !(_vm.rightSidebar && _vm.shouldDisplayCensus) },attrs:{"size":"is-small","type":"is-toggle"},model:{value:(_vm.activeTab),callback:function ($$v) {_vm.activeTab=$$v},expression:"activeTab"}},[_c('b-tab-item',{attrs:{"icon":"folder-open","title":"Resources Tab"}}),_c('b-tab-item',{attrs:{"icon":"phone-log","title":"Census Tab"}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.rightSidebar),expression:"!rightSidebar"}],staticClass:"is-invisible"},[_vm._v(" hello world ")]),_c('b-dropdown',{attrs:{"position":"is-bottom-left","aria-role":"list"}},[_c('div',{attrs:{"slot":"trigger","role":"button","id":"user-actions","aria-live":"polite"},slot:"trigger"},[(_vm.online)?_c('span',{staticClass:"online-dot"}):_c('span',{staticClass:"flashit offline-dot"}),_vm._v(" "+_vm._s(_vm.formattedFullName)+" "),_c('div',{class:{
          'avatar avatar-online': _vm.online,
          'avatar avatar-offline flashit': !_vm.online,
          'avatar-dnd': _vm.dnd,
        },staticStyle:{"margin-left":"0.25em"}},[_vm._v(" "+_vm._s(_vm.initials)+" ")])]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.copyDebugInfo}},[_c('div',{staticStyle:{"display":"flex","align-items":"center","justify-content":"space-between"}},[_c('span',[_vm._v("Copy Debug Info")])])]),_c('b-dropdown-item',{attrs:{"aria-role":"listitem"},on:{"click":_vm.logout}},[_vm._v(" Logout ")])],1),_c('b-modal',{attrs:{"active":_vm.showLogoutModal,"has-modal-card":""},on:{"update:active":function($event){_vm.showLogoutModal=$event}}},[_c('div',{staticClass:"fg",attrs:{"id":"logout-modal"}},[_c('div',{staticClass:"modal-card"},[_c('header',{staticClass:"modal-card-head"},[_c('p',{staticClass:"modal-card-title"},[_vm._v("Logout Conifrmation")])]),_c('section',{staticClass:"modal-card-body content"},[_c('div',{staticClass:"h4"},[_vm._v(" "+_vm._s(_vm.logoutMessage)+" ")])])]),_c('footer',{staticClass:"modal-card-foot"},[_c('button',{staticClass:"button",attrs:{"type":"button"},on:{"click":_vm.closeLogoutModal}},[_vm._v(" Cancel ")]),_c('button',{staticClass:"button is-danger",on:{"click":function($event){return _vm.$store.dispatch('auth/logout')}}},[_vm._v(" Reject Call and Logout ")])])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }