





































































































import { Component, Vue } from "vue-property-decorator";
import conf, { events, voice } from "@/config";
import { EventBus } from "@/eventBus";
import { Client, ClientIVR, Voicemail } from "@/types/domain";
import { agent } from "@/services/agent";
import { call } from "@/services/call";
import { session } from "@/services/session";
import { client } from "@/services/client";
import groupBy from "lodash/groupBy";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import pick from "lodash/pick";
import phoneValidator from "phone";
import startCase from "lodash/startCase";
import { AxiosError } from "axios";

@Component
export default class WrapUp extends Vue {
  private phone: string = "";
  mounted() {
    EventBus.$on("close-wrap-up-modal", () => this.$emit("closeModal"));
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get sessionassessmentlabel() {
    switch (this.tenant) {
      case "shl": {
        return "Call Wrap Up";
      }
      default: {
        return "Session Assessment";
      }
    }
  }

  get client(): Client {
    return this.$store.getters["call/client"];
  }

  get clientId() {
    return isPlainObject(this.client) &&
      !isEmpty(String(this.client.id).trim()) &&
      !isNaN(Number(this.client.id))
      ? Number(this.client.id)
      : Number.NaN;
  }

  get clientName() {
    return isPlainObject(this.client) &&
      typeof this.client.name === "string" &&
      this.client.name.trim().length
      ? this.client.name
      : "";
  }

  get callHandOffState() {
    return Boolean(this.$store.getters["call/handOffState"]);
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get pendingSessionAssessment() {
    const callStatus = this.callStatus;
    const sessionStatus = this.sessionStatus;
    return (
      this.callStatus === voice.status.ended &&
      this.sessionStatus === conf.agent.statuses.assessingCall
    );
  }

  get pendingWarmHandOff() {
    return (
      voice.status.handingOff === this.callStatus &&
      this.sessionStatus === conf.agent.statuses.wrappingUpCall
    );
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get preventCallEndAction() {
    return this.pendingSessionAssessment || this.transferring;
  }

  get ivrs(): ClientIVR[] {
    return this.$store.getters["call/ivrs"];
  }

  get voicemails(): Voicemail[] {
    return this.$store.getters["call/voicemails"];
  }

  get transferring() {
    if (this.phone && this.phoneValidation(this.phone)) {
      return phoneValidator(this.phone.trim(), "USA", true)[0];
    } else if (this.voicemails.find((vm) => vm.id === Number(this.phone))) {
      return Number(this.phone);
    }
    return false;
  }

  get NoOp() {
    return (
      (!Array.isArray(this.ivrs) || !this.ivrs.length) &&
      (!Array.isArray(this.voicemails) || !this.voicemails.length)
    );
  }

  private phoneValidation(input: any) {
    return (
      typeof input === "string" &&
      !isEmpty(phoneValidator(input.trim(), "USA", true))
    );
  }

  private transfer() {
    const onsuccess = ({ hangup }: { hangup: boolean }) => {
      this.close();
      const opts = {
        hangupOnCaller: hangup,
      };
      EventBus.$emit(events.call.end, opts);
    };

    const onerror = (err: Error | AxiosError | string) => {
      this.$buefy.toast.open({
        type: "is-danger",
        message: "Error: unable to send call.",
      });
      console.error("failed to send call (during wrap up)", err);
    };

    if (this.transferring) {
      switch (typeof this.transferring) {
        case "string": {
          const phoneNumber = this.transferring.replace("+1", "");

          const isIVR =
            Array.isArray(this.ivrs) &&
            this.ivrs.some(
              (ivr: ClientIVR) =>
                phoneValidator(ivr.phoneNumber.trim(), "USA")[0].replace(
                  "+1",
                  ""
                ) === phoneNumber.trim()
            );

          call
            .warmHandOff(phoneNumber, isIVR)
            .then(() => onsuccess({ hangup: false }))
            .catch(onerror);
          return;
        }

        case "number": {
          const voicemail = this.voicemails.find(
            (vm) => vm.id === this.transferring
          );

          if (!voicemail) return;

          call
            .sendToVoicemail(voicemail)
            .then(() => {
              this.close();
            })
            .catch(onerror);
          return;
        }

        default: {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Invalid transfer phone number for call wrap up.",
          });

          console.debug(
            "Invalid transfer phone number for call wrap up...impossible state.",
            this.phone,
            new Date().toISOString()
          );
        }
      }
    } else {
      this.$buefy.toast.open({
        type: "is-danger",
        message: "Invalid transfer phone number for call wrap up.",
      });
      console.debug(
        "Invalid transfer phone number for call wrap up",
        this.phone,
        new Date().toISOString()
      );
    }
  }

  private endCall() {
    const predicate = [voice.status.ongoing, voice.status.wrappingUp].includes(
      this.callStatus
    );

    if (predicate) {
      const opts = {
        hangupOnCaller: true,
        blockCaller: false,
        assessCallOnLeave: true,
      };

      this.close();
      EventBus.$emit(events.call.end, opts);
    }
  }

  private getErrorMessage(err: any) {
    return err instanceof Error ? err.message : new Error(err).message;
  }

  private close() {
    this.reset();
    this.$emit("closeModal");
  }

  private reset() {
    this.phone = "";
  }

  private beforeDestroy() {
    this.reset();
  }
}
