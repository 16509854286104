import { EventBus } from "../eventBus";
import store from "@/store";
import Vue from "vue";
import conf from "@/config";
import { agent } from "@/services/agent";

export default () => {
  EventBus.$on("auth/success", () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        store.dispatch("voice/updateMicrophoneAccess", true);
        Vue.prototype.$twilio.setup();
      })
      .catch(async (err: any) => {
        console.error("access to microphone was denied", err);
        try {
          const dnd = conf.agent.statuses.dnd;
          await agent.updateStatus(dnd);
          await store.dispatch("session/updateStatus", dnd);
          return;
        } catch (error) {
          console.error(
            "failed to put user into dnd mode after no answer to an incoming call",
            error
          );
          return;
        }
      });
  });

  EventBus.$on("auth/failure", () => {
    store.dispatch("auth/logout");
  });
};
