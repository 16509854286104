var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"client-panels-grid"},[_c('main',{staticClass:"panel-grid main-grid-wrapper",attrs:{"id":"main"}},[_c('div',{staticClass:"row panel-grid-top"},[_c('div',{staticClass:"columns h-100"},[_c('div',{staticClass:"column h-100 break-all"},[_c('Scripts')],1),_c('div',{staticClass:"column break-all"},[_c('ReferencePreview')],1)])]),_c('div',{staticClass:"row panel-grid-bottom"},[_c('div',{staticClass:"columns h-100 break-all"},[_c('div',{staticClass:"column mw-100"},[_c('ClientTabs')],1)])])]),_c('aside',{staticClass:"toolbar"},[(_vm.$store.getters['layout/leftSidebar'])?_c('b-tooltip',{attrs:{"label":"Close left sidebar","position":"is-right","type":"is-dark"}},[_c('div',{staticClass:"search-close",on:{"click":function () { return _vm.$store.dispatch('layout/toggleSidebar', {
              side: 'left',
              state: false
            }); }}},[_c('i',{staticClass:"icon ion-md-arrow-round-back"})])]):(!_vm.$store.getters['layout/leftSidebar'])?_c('b-tooltip',{attrs:{"label":"Open left sidebar","position":"is-right","type":"is-dark"}},[_c('div',{staticClass:"search-close",on:{"click":function () { return _vm.$store.dispatch('layout/toggleSidebar', {
              side: 'left',
              state: true
            }); }}},[_c('i',{staticClass:"icon ion-md-arrow-round-forward"})])]):_vm._e(),(!_vm.microphoneAccess)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" Note: Please allow microphone access to receive or take calls. ")]):(_vm.isHandlingCall)?_c('span',{staticStyle:{"color":"red","font-weight":"bold"}},[_vm._v(" Note: Do not refresh the page during a call. ")]):_vm._e(),(_vm.$store.getters['layout/rightSidebar'])?_c('b-tooltip',{attrs:{"label":"Close right sidebar","position":"is-left","type":"is-dark"}},[_c('div',{staticClass:"search-close",on:{"click":function () { return _vm.$store.dispatch('layout/toggleSidebar', {
              side: 'right',
              state: false
            }); }}},[_c('i',{staticClass:"icon ion-md-arrow-round-forward"})])]):(!_vm.$store.getters['layout/rightSidebar'])?_c('b-tooltip',{attrs:{"label":"Open right sidebar","position":"is-left","type":"is-dark"}},[_c('div',{staticClass:"search-close",on:{"click":function () { return _vm.$store.dispatch('layout/toggleSidebar', {
              side: 'right',
              state: true
            }); }}},[_c('i',{staticClass:"icon ion-md-arrow-round-back"})])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }