







































































































































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import { Agent, Caller } from "@/types/domain";
import conf, { voice as voiceConfig } from "@/config";
import { queue as queueService } from "@/services/queue";
import store from "@/store";
import ModalListItem from "@/components/common/modals/ModalListItem.vue";
import { EventBus } from "@/eventBus";
import { QueueState } from "../../../types/store";
import moment from "moment";

const statuses = conf.agent.statuses;
const roles = conf.agent.roles;

const INTERVAL_TIMEOUT = 5 * 1000; // 5 seconds (in milliseconds)

@Component({
  components: {
    ModalListItem,
  },
})
export default class TakeFromQueueModal extends Vue {
  @Prop(Object) queue!: QueueState;
  private statuses = statuses;
  private now: number = new Date().getTime();
  private interval!: NodeJS.Timeout;
  private roles = roles;
  public agent: Agent | null = null;
  public visitor: Caller | null = null;
  public selectedAgent: string | number | null = null;
  public selectedVisitor: string | null = null;
  private takeFromQueueActionDisabled: boolean = false;
  private tab: number = 0;

  mounted() {
    this.reset();
    EventBus.$on("reset-queue-modal-model", this.reset);
    this.interval = setInterval(() => {
      this.now = new Date().getTime();
    }, INTERVAL_TIMEOUT);
  }

  @Watch("agents.length", { immediate: true })
  private onAgentsCountChange() {
    if (!this.agents.length) {
      this.selectedAgent = null;
      this.agent = null;
    }
  }

  @Watch("visitors.length", { immediate: true })
  private onVisitorsCountChange() {
    if (!this.visitors.length) {
      this.selectedVisitor = null;
      this.visitor = null;
    }
  }

  get role() {
    return this.$store.getters["session/role"];
  }

  get session() {
    return this.$store.state.session;
  }

  get visitors(): Caller[] {
    return this.$store.getters["queue/visitors"](this.queue.label);
  }

  get size() {
    return this.$store.getters["queue/size"](this.queue.label);
  }

  get agents() {
    const agents: Agent[] = this.$store.getters["agent/online"];
    return Array.isArray(agents)
      ? agents.filter(
          (agent: Agent) =>
            [
              conf.agent.statuses.available,
              conf.agent.statuses.noRotation,
            ].includes(agent.status) && this.notMe(agent)
        )
      : [];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  private setAgent(agent: Agent) {
    this.selectedAgent = agent.id;
    this.agent = agent;
  }

  private setVisitor(visitor: Caller) {
    this.selectedVisitor = visitor.id;
    this.visitor = visitor;
  }

  private visitorWaitTime(visitor: Caller) {
    const duration = this.now - new Date(visitor.startTime).getTime();
    return this.showTimestampAsDuration(duration);
  }

  private showTimestampAsDuration(timestampInput: number): string {
    if (!timestampInput) {
      return "";
    }

    const toMilliseconds = (
      timestamp: number,
      unit: "milliseconds" | "seconds" | "minutes" = "milliseconds"
    ) => {
      let value: number = Number.NaN;
      switch (unit) {
        case "milliseconds":
          value = typeof timestamp === "number" ? timestamp : Number.NaN;
          break;

        case "seconds":
          value = typeof timestamp === "number" ? timestamp * 1000 : Number.NaN;
          break;

        case "minutes":
          value =
            typeof timestamp === "number" ? timestamp * 60 * 1000 : Number.NaN;
          break;
        default:
      }

      return value;
    };

    const resolver = (
      timestamp: number,
      unit: "milliseconds" | "seconds" | "minutes" = "milliseconds"
    ) => {
      if (timestamp < 0) {
        return "0 minutes";
      }

      const duration =
        unit === "milliseconds" ? timestamp : toMilliseconds(timestamp, unit);

      if (isNaN(duration)) {
        return "0 minutes";
      }

      const minutes = Math.floor(duration / (60 * 1000));
      const labels = {
        minute: minutes % 60 === 1 ? "minute" : "minutes",
        hour: Math.floor(minutes / 60) === 1 ? "hour" : "hours",
      };

      const format = `${minutes > 59 ? `H [${labels.hour}]` : ""} m [${
        labels.minute
      }]`;

      return moment
        .utc(moment.duration(duration / 1000, "s").asMilliseconds())
        .format(format);
    };

    return resolver(timestampInput);
  }

  private notMe(agent: Agent) {
    return (
      String(agent.key)
        .trim()
        .toLowerCase() !==
      String(this.$store.getters["session/id"])
        .trim()
        .toLowerCase()
    );
  }

  private async takeCall(id: string | number) {
    if (!id) {
      throw new Error("Invalid agent id.");
    }

    const canTakeCallFromQueue = () => {
      if (this.session && this.session.id && this.session.id === id) {
        return (
          [null, voiceConfig.status.ready].includes(this.callStatus) &&
          [
            conf.agent.statuses.available,
            conf.agent.statuses.noRotation,
          ].includes(this.sessionStatus) &&
          !this.takeFromQueueActionDisabled &&
          this.selectedVisitor &&
          Boolean(this.selectedVisitor.trim()) &&
          [conf.agent.roles.vas, conf.agent.roles.supervisor].includes(
            this.role
          )
        );
      } else {
        return (
          !this.takeFromQueueActionDisabled &&
          this.selectedVisitor &&
          Boolean(this.selectedVisitor.trim()) &&
          [conf.agent.roles.supervisor].includes(this.role) &&
          this.agents.find(
            (agent: Agent) =>
              agent.id === id &&
              [
                conf.agent.statuses.available,
                conf.agent.statuses.noRotation,
              ].includes(agent.status) &&
              this.notMe(agent)
          )
        );
      }
    };

    if (canTakeCallFromQueue()) {
      try {
        await queueService.take(String(id), this.selectedVisitor);
        this.takeFromQueueActionDisabled = true;
        // @ts-ignore
        this.$parent.close();
        const timeout = setTimeout(() => {
          this.takeFromQueueActionDisabled = false;
          clearTimeout(timeout);
        }, 5000);
      } catch (error) {
        console.error("error occured while taking call from queue", error);
      }
    } else {
      console.warn(
        "unable to take call from queue... predicate returned false",
        new Date().toISOString()
      );
    }
  }

  private isSelectedAgent(agent: Agent | null) {
    return agent && agent.id === this.selectedAgent;
  }

  private isSelectedVisitor(visitor: Caller | null) {
    return visitor && visitor.id === this.selectedVisitor;
  }

  private reset() {
    this.agent = null;
    this.selectedAgent = null;
    this.visitor = null;
    this.selectedVisitor = null;
    this.tab = 0;
    this.interval && clearInterval(this.interval);
  }

  beforeDestroy() {
    this.reset();
  }
}
