














































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Center, CenterSearchType } from "@/types/domain";
import { client } from "@/services/client";
import { EventBus } from "@/eventBus";
import { events as eventsConfig } from "@/config";
import isEmpty from "lodash/isEmpty";
import isString from "lodash/isString";
import debounce from "lodash/debounce";

@Component
export default class CenterSearch extends Vue {
  private searchTypes: string[] = [
    CenterSearchType.STATE,
    CenterSearchType.ZIP,
  ];
  private searchType: string = CenterSearchType.STATE;
  private searchText: string = "";
  private isPaginationSimple: boolean = true;
  private currentPage: number = 1;
  private perPage: number = 5;
  private defaultSortDirection: string = "asc";
  private centers: Center[] = [];
  private minSearchTextLength: number = 2;
  private loading: boolean = false;
  private isNarrowed: boolean = true;
  private selectedCenter: Center | null = null;

  mounted() {
    EventBus.$on(eventsConfig.interactions.clearPreviewPanel, () => {
      this.selectedCenter = null;
    });
  }

  get query() {
    const searchTextIsValid =
      isString(this.searchText) &&
      !isEmpty(this.searchText.trim()) &&
      this.searchText.trim().length >= this.minSearchTextLength;

    const searchTypeIsValid =
      isString(this.searchType) &&
      !isEmpty(this.searchType.trim()) &&
      this.searchTypes.includes(this.searchType.trim());

    return searchTextIsValid && searchTypeIsValid
      ? { type: this.searchType.trim(), search: this.searchText.trim() }
      : null;
  }

  get searchTextPlaceholder() {
    switch (this.searchType) {
      case CenterSearchType.STATE: {
        return "Enter a valid state abbreviation (ex. MD, DC, CA)";
      }

      case CenterSearchType.ZIP: {
        return "Enter a valid zip code";
      }

      default: {
        return "Enter search text";
      }
    }
  }

  @Watch("searchText")
  private onSearchTextUpdate() {
    if (this.searchText == "") {
      this.centers = [] as Center[];
    }
    if (this.query) {
      this.search(this);
    }
  }

  @Watch("searchType")
  private onSearchTypeUpdate() {
    if (this.query) {
      this.search(this);
    }
  }

  @Watch("selectedCenter", { immediate: true, deep: true })
  private onSelectedCenterUpdate() {
    if (this.selectedCenter) {
      EventBus.$emit(eventsConfig.center.preview, this.selectedCenter);
    }
  }

  private search = debounce((vm) => {
    vm.$data.loading = true;
    client
      .centerSearch(vm.query)
      .then((result: any) => {
        if (Array.isArray(result) && result.length) {
          vm.$data.centers = result as Center[];
          vm.$data.loading = false;
          return;
        }

        console.warn("center search api returned invalid data", result);

        vm.$data.centers = [] as Center[];
        vm.$data.loading = false;
      })
      .catch((error) => {
        vm.$data.centers = [] as Center[];
        vm.$data.loading = false;
        // vm.$buefy.toast.open({
        //   type: "is-danger",
        //   message: "Error: unable to resolve center search query."
        // });

        console.error("failed to resolve center search query", error);
      });
  }, 1000);
}
