import Vue from "vue";
import Buefy from "buefy";
import "@/assets/styles/app.scss";
import "vue-image-lightbox/dist/vue-image-lightbox.min.css";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueTwilio from "./plugins/twilio";
import { EventBus } from "./eventBus";
import subscriptions from "./subscriptions";
import VueLazyLoad from "vue-lazyload";

/* Vue.config.productionTip = process.env.NODE_ENV !== "production"; */
Vue.config.productionTip = true;

Vue.use(Buefy);
Vue.use(VueTwilio);
Vue.use(VueLazyLoad);

Vue.prototype.$eventBus = EventBus;
subscriptions.init(EventBus);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
