import { AxiosResponse, AxiosError } from "axios";
import Vue from "vue";
import { QueueState } from "./../types/store";
import { Caller, Queue } from "@/types/domain";
import appConfig from "@/config/app";

import axios from "axios";
import store from "@/store";
import { agent } from "@/services/agent";
import { isPlainObject, truncate, startCase } from "lodash";
// import { EventBus } from "@/eventBus";
import Cookies from "js-cookie";

import { Connection } from "twilio-client/es5/twilio";
import { pick } from "lodash";

export default function realtimeSubscriptions() {
  /* SET UP SSE UPDATES: server notifications for what's changed */
  // const source: EventSource = new EventSource(appConfig.sseUrl);
  const source: EventSource = new EventSource(appConfig.sseUrl, {
    withCredentials: true,
  });

  const listener = async (message: MessageEvent) => {
    const data = message.data ? JSON.parse(message.data) : {};
    console.debug(
      "[SSE REALTIME SUBSCRIPTIONS] Listener received data",
      new Date().toISOString(),
      data.staffers,
      data.queue
    );

    if (Array.isArray(data.staffers) && Array.isArray(data.queue)) {
      const staffer = data.staffers.find(
        (staff: any) =>
          String(staff.key).trim().toLowerCase() ===
          String(store.getters["session/id"]).trim().toLowerCase()
      );

      if (staffer) {
        buildQueueCollection(staffer, data.queue)
          .then((queues) => store.dispatch("queue/update", queues))
          .then(() => true)
          .catch((err) => {
            console.error(
              "[SSE REALTIME SUBSCRIPTIONS] an unexpected error occurred while updating the queues...",
              err
            );
          });
      } else {
        console.warn(
          "[SSE REALTIME SUBSCRIPTIONS] staffer not found in sse data",
          new Date().toISOString()
        );
      }

      store.dispatch(
        "agent/update",
        data.staffers.map((staff: any) => {
          if (staffer && staff.key === staffer.key) {
            const session = store.getters["session/state"];
            store.dispatch("session/set", {
              ...session,
              queueRotations: staffer.queueRotations,
              status: staffer.status,
              statusChangedAt: Number(staffer.statusChangedAt),
            });
          }
          return { ...staff, id: staff.key };
        })
      );
    }
  };

  source.addEventListener("message", listener);

  /* SET UP PRESENCE: letting the server know I am present */
  const sessionDebugInfo = async (evt: Record<string, any>) => {
    const jwt = () => Cookies.get("api_jwt") || "n/a";
    const network =
      // @ts-ignore
      navigator.connection ||
      // @ts-ignore
      navigator.mozConnection ||
      // @ts-ignore
      navigator.webkitConnection ||
      null;

    const info = {
      timestamp: new Date().getTime(),
      health: evt.status,
      agent: {
        ref: store.getters["session/id"],
        name: store.getters["session/fullName"],
      },
      tokens: {
        jwt: {
          deprecated: evt.data.deprecated_api_jwt || "n/a",
          active:
            evt.data &&
            isPlainObject(evt.data) &&
            typeof evt.data.api_jwt === "string"
              ? evt.data.api_jwt
              : jwt(),
        },
        access: store.getters["auth/authToken"],
        refresh: store.getters["auth/refreshToken"],
        capability: store.getters["session/capabilityToken"],
      },
      statuses: {
        jwt: evt.data && isPlainObject(evt.data) ? evt.data.status : Number.NaN,
        microphone: store.getters["voice/microphone"],
        authentication: store.getters["auth/loggedIn"],
        queues: {
          list: store.getters["queue/all"],
          rotation: store.getters["session/queueRotations"],
        },
        staff: store.getters["agent/online"],
        call: {
          client: store.getters["call/client"] || "n/a",
          id: store.getters["call/id"] || "n/a",
          timestamp: store.getters["call/timestamp"] || "n/a",
          status: store.getters["call/status"] || "n/a",
        },
        activity: {
          type: store.getters["session/status"],
          timestamp: store.getters["session/statusTimestamp"],
        },
        internet: {
          active: navigator.onLine,
          type:
            isPlainObject(network) && network.effectiveType
              ? network.effectiveType
              : "n/a",
        },
        twilio: {
          initialized: Vue.prototype.$twilio.ref.isInitialized,
          token: Vue.prototype.$twilio.ref.token,
          status: Vue.prototype.$twilio.getDeviceStatus(),
          connections: Vue.prototype.$twilio.ref.connections.map(
            (c: Connection) => pick(c, ["isMuted", "callerInfo", "parameters"])
          ),
        },
      },
    };

    sessionStorage.setItem("thl-session-debug-info", JSON.stringify(info));

    return true;
  };

  const connection = () => {
    const HEARTBEAT_INTERVAL = 4 * 1000; // 4 second(s)
    const getJWT = () => Cookies.get("api_jwt") || "n/a";
    const checkingIn = () => {
      const apiJwt = getJWT();
      const accessToken = store.getters["auth/authToken"];
      const refreshToken = store.getters["auth/refreshToken"];

      if (!accessToken || !refreshToken) {
        console.warn(
          "[THL SESSION HEALTH CHECK::HEARTBEAT ENDPOINT] skipping API call since there are no auth tokens",
          new Date().toISOString()
        );

        sessionDebugInfo({
          status: Number.NaN,
          data: {
            status: Number.NaN,
            deprecated_api_jwt: apiJwt,
          },
        }).catch((err: unknown) =>
          console.error(
            "[THL SESSION HEALTH CHECK::HEARTBEAT ENDPOINT] an error occurred (after attempting to collect and save session debug info)",
            err
          )
        );

        return;
      }

      return axios
        .get(`${appConfig.apiUrl}/heartbeat`)
        .then((response: AxiosResponse) =>
          sessionDebugInfo({
            status: response.status,
            data: {
              ...response.data,
              deprecated_api_jwt: apiJwt,
            },
          })
        )
        .catch((error: AxiosError) => {
          console.error(
            "[THL SESSION HEALTH CHECK::HEARTBEAT ENDPOINT] an error occurred on request",
            error
          );
          sessionDebugInfo({
            status: error.response?.status || Number.NaN,
            data: {
              status: Number.NaN,
              deprecated_api_jwt: apiJwt,
            },
          }).catch((err: unknown) =>
            console.error(
              "[THL SESSION HEALTH CHECK::HEARTBEAT ENDPOINT] an error occurred (after attempting to collect and save session debug info)",
              err
            )
          );
        });
    };

    const interval$ = setInterval(checkingIn, HEARTBEAT_INTERVAL);

    return () => clearInterval(interval$);
  };

  const disconnection = connection();

  /* CLEANUP: clean up both sse subscription & presence signaling */
  const cleanup = () => {
    source.removeEventListener("message", listener);
    source.close();
    disconnection();
    console.debug(
      "[REALTIME SUBSCRIPTIONS] cleanup callback called...",
      new Date().toISOString()
    );

    window.removeEventListener("beforeunload", cleanup);
  };

  window.addEventListener("beforeunload", cleanup);

  return cleanup;
}

export async function buildQueueCollection(
  staff: Record<string, unknown>,
  callers: Caller[]
): Promise<QueueState[]> {
  return new Promise((resolve) => {
    agent
      .getAssignedQueues(staff)
      .then((queues: Queue[]) => {
        return queues.reduce((state: QueueState[], queue) => {
          const clients = queue.clients
            .map(Number)
            .filter((c: number) => !isNaN(c));
          const filteredCallers = callers.filter((caller: Caller) =>
            clients.includes(Number(caller.client.id))
          );
          const size = filteredCallers.length;
          return state.concat({
            id: Number(queue.id),
            size,
            visitors: filteredCallers,
            specialized: true,
            label: getQueueDisplayName(queue),
            clients,
            sound: getQueueSoundURL(queue.soundUrl, queue.id),
          });
        }, [] as QueueState[]);
      })
      .then(resolve)
      .catch((err: unknown) => {
        console.error("Queue(s) realtime update error(s).", err);
        Vue.prototype.$buefy.toast.open({
          type: "is-danger",
          position: "is-top-right",
          message: "Queue(s) realtime update error(s).",
        });
        resolve([]);
      });
  });
}

export function getQueueSoundURL(x: string, queueId: string | number) {
  const isValidString = typeof x === "string" && x.trim().length;

  if (!isValidString) {
    return "";
  }

  try {
    return new URL(x.trim()).href.trim();
  } catch (error) {
    console.warn(
      "invalid queue sound url for queue ",
      new Date().toISOString(),
      x,
      queueId,
      error
    );
    return "";
  }
}

export function getQueueDisplayName(queue: Queue) {
  return isPlainObject(queue) &&
    typeof queue.name === "string" &&
    queue.name.trim().length
    ? queue.name.trim().length < 8
      ? queue.name.trim()
      : truncate(startCase(queue.name.trim()), {
          length: 8,
          separator: /,? +/,
          omission: "...",
        })
    : typeof queue.abbreviation === "string" && queue.abbreviation.trim().length
    ? queue.abbreviation.trim()
    : "Queue (no name)";
}
