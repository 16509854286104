import { SessionAssessmentResponse, Voicemail } from "./../../../types/domain";
import { ActionTree } from "vuex";
import { CallState, RootState, HandOffStates } from "@/types/store";
import {
  Call,
  Client,
  Reference,
  ClientReference,
  Center,
} from "@/types/domain";
import { call } from "@/services/call";
import { EventBus } from "@/eventBus";

type CensusResponsesUpdatePayload =
  | {
      type: "select";
      question: string | number;
      selected: Array<string | number>;
    }
  | {
      type: "freeform";
      question: string | number;
      option: string | number;
      input: string;
    };

export const actions: ActionTree<CallState, RootState> = {
  update({ commit }, prop: object) {
    commit("setProp", prop);
  },
  updateStatus({ commit }, status: string) {
    commit("setStatus", status);
  },
  updateTransferredTo({ commit }, to: string) {
    commit("setTransferredTo", to);
  },
  set({ commit }, current: Call) {
    commit("setCurrent", current);
  },
  updateClient({ commit }, client: Client | null) {
    commit("setClient", client);
  },
  reset({ commit }) {
    commit("reset");
  },
  resetCall({ commit }) {
    commit("resetCall");
  },
  setStartDate({ commit }) {
    commit("setStartDate", new Date());
  },
  updateVoicemails({ commit }, voicemails: Voicemail[]) {
    if (Array.isArray(voicemails)) {
      commit("setVoicemails", voicemails);
    }
  },
  updateNotepad({ commit }, content: string) {
    commit("updateNotepad", content);
  },
  updateHandOffState({ commit }, status: HandOffStates) {
    commit("setHandOffState", status);
  },
  startTimer({ commit }) {
    if (call.callTimer) {
      // @ts-ignore
      clearInterval(call.callTimer);
      call.callTimer = null;
    }

    // @ts-ignore
    call.callTimer = setInterval(() => commit("incrementCallLength"), 1000);
  },
  stopTimer({ commit }) {
    // @ts-ignore
    if (call.callTimer) {
      // @ts-ignore
      clearInterval(call.callTimer);
      call.callTimer = null;
    }

    commit("clearCallLength");
  },
  addReference({ commit }, reference: Reference) {
    commit("addReference", reference);
  },
  removeReference({ commit }, reference: Reference) {
    commit("removeReference", reference);
  },
  addClientReference({ commit }, reference: ClientReference) {
    commit("addClientReference", reference);
  },
  removeClientReference({ commit }, reference: ClientReference) {
    commit("removeClientReference", reference);
  },
  addCenter({ commit }, center: Center) {
    commit("addCenter", center);
  },
  removeCenter({ commit }, center: Center) {
    commit("removeCenter", center);
  },
  updateCensusResponses({ commit }, payload: CensusResponsesUpdatePayload) {
    if (payload.type === "select") {
      commit("setCensusResponses", {
        question: Number(payload.question),
        selected: payload.selected.map(Number),
      });
    } else if (payload.type === "freeform") {
      commit("setCensusFreeFormResponse", {
        question: Number(payload.question),
        option: Number(payload.option),
        input: payload.input.trim(),
      });
    }
  },
  updateSessionAssessmentState({ commit }, data: SessionAssessmentResponse[]) {
    commit("setSessionAssessmentState", data);
  },
  clearCensusResponses({ commit }) {
    // TODO: this event should probably NOT be here, but....
    EventBus.$emit("toggle-right-sidebar-panel", 0);
    commit("clearCensusResponses");
  },
};
