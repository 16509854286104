import isPlainObject from "lodash/isPlainObject";
import Vue from "vue";
import axios from "axios";
import { agent } from "./agent";
import { call } from "./call";
import { app } from "@/config";
import store from "@/store";
import { Session } from "@/types/domain";
import conf, { voice as voiceConfig } from "@/config";
import { EventBus } from "@/eventBus";
import events from "@/config/events";
import {
  Connection as TwilioConnection,
  Device as TwilioDevice,
} from "twilio-client/es5/twilio";

export const session = {
  async generate() {
    return axios
      .get(`${app.apiUrl}/users/authenticate`)
      .then((res) => {
        const newSession: Session = {
          id: res.data.key,
          role: res.data.role,
          queueRotations: isPlainObject(res.data.queueRotations)
            ? res.data.queueRotations
            : {},
          displayName: res.data.displayName,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          capabilityToken: res.data.capabilityToken,
          status: res.data.status,
          statusChangedAt: Number(res.data.statusChangedAt),
        };

        store.dispatch("session/set", newSession);

        return res.data;
      })
      .catch((err) => {
        // TODO: code auth failure stuff, and use `auth/failure` instead.
        EventBus.$emit(events.session.authFailure);
        console.error(err);
      });
  },
  async updateStatus(status: string) {
    try {
      await agent.updateStatus(status);
    } catch (err) {
      throw err;
    }
  },
  async reset(destroy: boolean = false) {
    return new Promise(async (resolve, reject) => {
      try {
        const isAuthenticated = store.getters["auth/loggedIn"];
        const sessionStatus = store.getters["session/status"];
        const callStatus = store.getters["call/status"];
        if (
          !destroy &&
          isAuthenticated &&
          sessionStatus === conf.agent.statuses.assessingCall &&
          callStatus === voiceConfig.status.ended
        ) {
          return;
        }

        await store.dispatch("agent/reset");
        await store.dispatch("queue/reset");
        await store.dispatch("call/reset");

        if (destroy) {
          await store.dispatch("auth/reset");
          await store.dispatch("session/reset");
          await store.dispatch("voice/reset");
        }

        const deviceStatus = Vue.prototype.$twilio.getDeviceStatus();
        const connectionStatus = Vue.prototype.$twilio.getConnectionStatus();

        const incomingCallStatus =
          sessionStatus === conf.agent.statuses.incomingCall ||
          callStatus === voiceConfig.status.incoming ||
          callStatus === voiceConfig.status.answering ||
          callStatus === voiceConfig.status.taking ||
          [
            TwilioConnection.State.Pending,
            TwilioConnection.State.Ringing,
          ].includes(connectionStatus);

        const ongoingCallStatus =
          sessionStatus === conf.agent.statuses.inCall ||
          sessionStatus === conf.agent.statuses.wrappingUpCall ||
          callStatus === voiceConfig.status.ongoing ||
          callStatus === voiceConfig.status.handingOff ||
          callStatus === voiceConfig.status.wrappingUp ||
          [
            TwilioConnection.State.Connecting,
            TwilioConnection.State.Open,
            TwilioConnection.State.Reconnecting,
          ].includes(connectionStatus);

        const putCallInQueue =
          deviceStatus === TwilioDevice.Status.Busy ||
          ongoingCallStatus ||
          incomingCallStatus;

        if (putCallInQueue) {
          await call.didNotAnswer();
        }

        resolve(true);
      } catch (error) {
        console.error(
          "an error occurred while resetting session (page reload or lost of authentication)",
          error
        );
        EventBus.$emit(
          "error-notification",
          `Error: unexpected error occurred on session reset procedure. Details: ${
            new Error(error).message
          }`
        );
        reject(error);
      }
    });
  },
};
