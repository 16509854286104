import { AgentState, RootState } from '@/types/store';
import { Module } from 'vuex';
import { actions } from './actions';
import { getters } from './getters';
import { mutations } from './mutations';

export function initialState(): AgentState {
  return {
    online: []
  };
}

const state: AgentState = initialState();

const namespaced: boolean = true;

export const agentStore: Module<AgentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
