var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('aside',{attrs:{"id":"sidebar"}},[(Array.isArray(_vm.queues) && _vm.queues.length)?_c('div',{staticStyle:{"margin-top":"0.4rem","overflow-y":"scroll"}},_vm._l((_vm.queues),function(button,idx){return _c('div',{key:(idx + "::" + (Math.random())),staticClass:"queue-item"},[_c('span',[_vm._v(" "+_vm._s(_vm.formatQueueButtonLabel(button.label))+" ")]),_c('div',{staticClass:"queue-action-btns"},[(typeof button.sound === 'string' && button.sound.trim())?_c('div',{staticClass:"btn-mute",on:{"click":function($event){return _vm.toggleQueueNotificationSoundStatus(button.label)}}},[(_vm.queueNotificationSoundStatus[button.label])?_c('b-tooltip',{attrs:{"label":"Sound On","position":"is-left","type":"is-dark"}},[_c('i',{staticClass:"icon ion-ios-volume-high primary",attrs:{"title":"Sound On"}})]):_c('b-tooltip',{attrs:{"label":"Sound Off","position":"is-left","type":"is-dark"}},[_c('i',{staticClass:"icon ion-ios-volume-off",staticStyle:{"color":"red"},attrs:{"title":"Sound Off"}})])],1):_vm._e(),(_vm.showQueueNoRotationStatusToggle(button))?_c('div',{staticClass:"btn-queue-rotations",on:{"click":function($event){_vm.toggleQueueNoRotationStatus(
              button,
              _vm.queueNoRotationStatus[button.label] ||
                [undefined, null].includes(
                  _vm.queueNoRotationStatus[button.label]
                )
                ? false
                : true
            )}}},[(
              _vm.queueNoRotationStatus[button.label] ||
              [undefined, null].includes(_vm.queueNoRotationStatus[button.label])
            )?_c('b-tooltip',{attrs:{"label":"In Rotation","position":"is-left","type":"is-dark"}},[_c('i',{staticClass:"icon ion-md-notifications"})]):_c('b-tooltip',{attrs:{"label":"Out Of Rotation","position":"is-left","type":"is-dark"}},[_c('i',{staticClass:"icon ion-md-notifications-off",staticStyle:{"color":"red"}})])],1):_vm._e(),_c('div',{class:("queue-count " + (button.size ? 'flashit' : '')),style:(_vm.queueSizeBtnBg(button)),attrs:{"title":"Queue"},on:{"click":function($event){return _vm.takeFromQueue(button)}}},[_vm._v(" "+_vm._s(button.size)+" ")])])])}),0):_c('div',[_vm._m(0)]),_c('div',{attrs:{"id":"buddylist"}},[_c('h5',{staticClass:"uppercase fg-grey panel-label"},[_vm._v(" Online ("+_vm._s(_vm.agents.length)+") ")]),_c('ul',{staticStyle:{"position":"static"},attrs:{"id":"buddies"}},_vm._l((_vm.agents),function(agent,idx){return _c('Buddy',{key:("agent-" + idx),attrs:{"agent":_vm.determineProp(agent)}})}),1)]),_c('b-modal',{attrs:{"has-modal-card":"","active":_vm.showQueueModal,"on-cancel":_vm.closeQueueModal},on:{"update:active":function($event){_vm.showQueueModal=$event}}},[_c('TakeFromQueueModal',{attrs:{"queue":_vm.selectedQueue}})],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fg flex"},[_c('i',{staticClass:"fg-primary ion-ios-information-circle-outline mar-r"}),_vm._v(" No queue(s) assigned. ")])}]

export { render, staticRenderFns }