import { GetterTree } from "vuex";
import { CallState, RootState } from "@/types/store";

export const getters: GetterTree<CallState, RootState> = {
  current: (state) => state.current,
  id: (state) => state.id,
  status: (state) => state.status,
  client: (state) => state.client,
  ivrs: (state) =>
    state.client && Array.isArray(state.client.ivr) ? state.client.ivr : [],
  voicemails: (state) =>
    state.voicemails && Array.isArray(state.voicemails) ? state.voicemails : [],
  notepad: (state) => state.notepad,
  handOffState: (state) => state.handOffState,
  references: (state) =>
    Array.isArray(state.references) ? state.references : [],
  clientReferences: (state) =>
    Array.isArray(state.clientReferences) ? state.clientReferences : [],
  centers: (state) => (Array.isArray(state.centers) ? state.centers : []),
  census: (state) => (state.census ? state.census : null),
  pin: (state) => (state.pin.trim() ? state.pin.trim() : ""),
  assessment: (state) =>
    Array.isArray(state.assessment) ? state.assessment : [],
  transferredTo: (state) => state.transferredTo,
  // @ts-ignore
  prop: (state) => (prop: string) => state.current[prop],
};
