import { app } from "@/config";
import axios, { AxiosResponse, AxiosError, AxiosPromise } from "axios";
import {
  CenterSearchType,
  DemographicQuestion,
  DemographicQuestionOption,
  ClientReference,
  ClientReferenceApiResponse,
  CensusFormQuestion,
  SessionAssessment,
  SessionAssessmentClient,
  SessionAssessmentQuestion,
  SessionAssessmentQuestionOption,
  AssessmentNest,
  AssessmentQuestion,
  AssessmentAnswer,
  SessionAssessmentQuestionnaire,
  SessionAssessmentResponse,
  AssessmentAPIResponse,
  AssessmentDetails,
  Voicemail,
} from "@/types/domain";
import auth from "@/services/auth";
import startcase from "lodash/startCase";
import setKey from "lodash/set";
import getKeys from "lodash/pick";
import store from "@/store";

const centerUrl = `${app.apiUrl}/centers`;

const getFromApi = (url: string, params: object = {}): AxiosPromise => {
  return axios.get(url, {
    params: {
      jwt: auth.jwt(),
      ...params,
    },
  });
};

export const client = {
  hasSessionAssessmentQuestionnaireFreeformQuestionOptions(
    clientId?: number | string
  ) {
    return Boolean(clientId) || true;
  },
  async centerSearch({
    type,
    search,
  }: {
    type: keyof typeof CenterSearchType;
    search: string;
  }) {
    return new Promise((resolve, reject) => {
      if (type !== CenterSearchType.STATE && type !== CenterSearchType.ZIP) {
        return reject("Invalid search type");
      }

      axios
        .get(`${centerUrl}/${type.toLowerCase()}/${search}`)
        .then((res: AxiosResponse) => resolve(res.data))
        .catch(reject);
    });
  },
  async getCenter(id: number | string) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${centerUrl}/${id}`)
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch(reject);
    });
  },
  async getScript(id: number | string) {
    return getFromApi(`${app.appUrl}/blurb/?clientId=${id}&type=${2}`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async getServices(id: number | string) {
    return getFromApi(`${app.appUrl}/blurb/?clientId=${id}&type=${1}`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async getUpdates(id: number | string) {
    return getFromApi(`${app.appUrl}/blurb/?clientId=${id}&type=${3}`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async getOnCall(id: number | string, params: object = {}) {
    return getFromApi(`${app.appUrl}/client/on-call`, params)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async getVoicemails() {
    return new Promise<Voicemail[]>((resolve) => {
      getFromApi(`${app.appUrl}/endMessage/all`)
        .then((res: AxiosResponse<Voicemail[]>) => {
          resolve(
            Array.isArray(res.data)
              ? res.data.filter((voicemail) => voicemail.isActive)
              : ([] as Voicemail[])
          );
        })
        .catch((err: AxiosError) => {
          console.error("failed to get voicemails data", err);
          resolve([] as Voicemail[]);
        });
    });
  },
  async getReferences(id: string): Promise<ClientReferenceApiResponse> {
    return new Promise((resolve, reject) => {
      getFromApi(`${app.appUrl}/clientResource/${id}`)
        .then((res: AxiosResponse) => {
          if (!Array.isArray(res.data.references)) {
            resolve({ references: [], categories: [], types: [] });
            return;
          }

          const referenceRecordKeyTransformer = (key: string) =>
            key === "id" ? "ID" : startcase(key).split(" ").join("");

          const data = {
            references: res.data.references.map(
              (record: Record<string, string>) => {
                return Object.keys(record).reduce(
                  (
                    transformedRecord: Partial<ClientReference>,
                    key: string
                  ) => {
                    return {
                      ...transformedRecord,
                      [referenceRecordKeyTransformer(key)]: record[key],
                    };
                  },
                  {}
                );
              }
            ),
            types: res.data.categories as string[],
            categories: res.data.issues as string[],
          };
          resolve(data);
        })
        .catch((err: AxiosError) => reject(err.message));
    });
  },
  async getAssessment(id: number | string) {
    return getFromApi(`${app.appUrl}/client/${id}/session-assessment-nested`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async submitAssessment(payload: {
    sessionAssessmentId: number;
    callId: string | number | null;
    answers: any[];
    notes: string | null | undefined;
    // references: Reference[];
    // clientReferences: ClientReference[];
    // demographics: number[];
    // centers: Center[];
  }) {
    // const addArrayToFormData = (
    //   formDataObj: FormData,
    //   key: string,
    //   arr: any[]
    // ) => {
    //   return arr.reduce((result: FormData, item: any, idx: number) => {
    //     result.append(`${key}[]`, item);
    //     return result;
    //   }, formDataObj);
    // };

    // const formData = new FormData();
    // formData.set("sessionAssessmentId", String(payload.sessionAssessmentId));
    // formData.set("callId", String(payload.callId));
    // formData.set("notes", String(payload.notes));

    // addArrayToFormData(
    //   formData,
    //   "answers",
    //   Array.isArray(payload.answers) ? payload.answers : []
    // );

    // addArrayToFormData(
    //   formData,
    //   "references",
    // Array.isArray(payload.references)
    //   ? payload.references.map((ref: Reference) => Number(ref.id))
    //   : []
    // );

    // addArrayToFormData(
    //   formData,
    //   "clientReferences",
    //   Array.isArray(payload.clientReferences)
    //     ? payload.clientReferences.map((ref: ClientReference) => String(ref.ID))
    //     : []
    // );

    // addArrayToFormData(
    //   formData,
    //   "demographics",
    //   Array.isArray(payload.demographics) ? payload.demographics : []
    // );

    // addArrayToFormData(
    //   formData,
    //   "centers",
    //   Array.isArray(payload.centers) ? payload.centers : []
    // );

    const getFormData = () =>
      Object.entries(payload).reduce((formData: any, payloadEntry: any) => {
        const [key, value] = payloadEntry;
        switch (key) {
          // case "references":
          //   return Object.assign({}, formData, {
          //     [key]: Array.isArray(payload.references)
          //       ? payload.references.map((ref: Reference) => Number(ref.id))
          //       : []
          //   });
          // case "clientReferences":
          //   return Object.assign({}, formData, {
          //     [key]: Array.isArray(payload.clientReferences)
          //       ? payload.clientReferences.map((ref: ClientReference) =>
          //           String(ref.ID)
          //         )
          //       : []
          //   });
          // case "centers":
          //   return Object.assign({}, formData, {
          //     [key]: Array.isArray(payload.centers)
          //       ? payload.centers.map((ref: Center) => Number(ref.id))
          //       : []
          //   });
          case "answers": {
            return Object.assign({}, formData, {
              [key]: Array.isArray(value) ? value : [],
            });
          }
          default: {
            return Object.assign({}, formData, {
              [key]: value,
            });
          }
        }
      }, {});

    return axios
      .post(
        `${app.appUrl}/session-assessment-completion-answer`,
        getFormData(),
        {
          params: { jwt: auth.jwt() },
        }
      )
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  getCensusData(
    clientId: string | number,
    setDefaultValues: boolean = true
  ): Promise<CensusFormQuestion[]> {
    const transformer = (responseData: DemographicQuestion[]) => {
      const questionChoiceTransformer = (
        questionChoiceRecord: DemographicQuestionOption
      ) => {
        return {
          id: questionChoiceRecord.id,
          label: questionChoiceRecord.choice,
          position: questionChoiceRecord.ordinalPosition,
          freeform: questionChoiceRecord.isFreeform === true,
        };
      };

      const questionTransformer = (questionRecord: DemographicQuestion) => {
        const defaults = Array.isArray(questionRecord.defaultChoices)
          ? questionRecord.defaultChoices
              .map((value: unknown) =>
                value === null ? Number.NaN : Number(value)
              )
              .filter((value) => !isNaN(value))
          : [];

        if (setDefaultValues && defaults.length) {
          store
            .dispatch("call/updateCensusResponses", {
              type: "select",
              question: questionRecord.id,
              selected: defaults,
            })
            .catch((err) => {
              console.warn(
                "[Census Form] failed to set default selected option(s) for demographics question",
                new Date().toISOString(),
                questionRecord.id,
                defaults,
                err
              );
            });
        }

        const options = questionRecord.demographicChoices.map(
          questionChoiceTransformer
        );

        const isInput = () => {
          if (questionRecord.isInput === true) {
            return true;
          }

          if (options.length === 1 && defaults.length) {
            const option = options[0];
            if (defaults.includes(option.id) && option.freeform) {
              return true;
            }
          }

          return false;
        };

        return {
          id: questionRecord.id,
          label: questionRecord.name,
          description: questionRecord.description,
          options,
          defaults,
          required: questionRecord.isRequired,
          multiple: questionRecord.isMultiselect,
          input: isInput(),
        };
      };

      return Array.isArray(responseData)
        ? responseData
            .filter((record: DemographicQuestion) => record.isActive)
            .map(questionTransformer)
        : [];
    };

    return new Promise((resolve, reject) => {
      axios
        .get(`${app.apiUrl}/demographics?clientId=${clientId}`)
        .then((res: AxiosResponse) => {
          resolve(transformer(res.data));
        })
        .catch((error: AxiosError) => reject(error.message));
    });
  },
};

export function transformAssessmentDataIntoQuestionnaire(
  input: AssessmentAPIResponse
): SessionAssessmentQuestionnaire {
  const getAssessmentData = (
    somedata: AssessmentDetails
  ): SessionAssessment => ({ id: somedata.id, active: somedata.active });

  const getClientData = (
    somedata: AssessmentDetails
  ): SessionAssessmentClient => ({
    id: somedata.client.id,
    name: somedata.client.name,
  });

  const getQuestionData = (
    someQuestionObject: Pick<
      AssessmentQuestion,
      "id" | "questionText" | "ordinalPosition"
    >
  ): Omit<SessionAssessmentQuestion, "options"> => {
    return Object.entries(
      getKeys(someQuestionObject, ["id", "questionText", "ordinalPosition"])
    ).reduce(
      (
        q: Omit<SessionAssessmentQuestion, "options">,
        [key, value]: [string, number | string]
      ) => {
        switch (key) {
          case "questionText": {
            return Object.assign({}, q, { text: value });
          }
          case "ordinalPosition": {
            return Object.assign({}, q, { position: value });
          }
          default: {
            return Object.assign({}, q, { [key]: value });
          }
        }
      },
      {} as Omit<SessionAssessmentQuestion, "options">
    );
  };

  const getOptionData = (
    someQuestionOptionObject: AssessmentAnswer
  ): SessionAssessmentQuestionOption =>
    Object.entries(
      getKeys(someQuestionOptionObject, [
        "id",
        "answerText",
        "ordinalPosition",
        "terminationAnswer",
        "freeFormAnswer",
      ])
    ).reduce(
      (
        q: SessionAssessmentQuestionOption,
        [key, value]: [string, number | string | boolean]
      ) => {
        switch (key) {
          case "answerText": {
            return Object.assign({}, q, { text: value });
          }
          case "ordinalPosition": {
            return Object.assign({}, q, { position: value });
          }
          case "terminationAnswer": {
            return Object.assign({}, q, { termination: value });
          }
          case "freeFormAnswer": {
            return Object.assign({}, q, { freeform: value });
          }
          default: {
            return Object.assign({}, q, { [key]: value });
          }
        }
      },
      {} as SessionAssessmentQuestionOption
    );

  const getQuestionsAndOptions = (
    someArrayOfQuestions: AssessmentNest[],
    shouldOmitFreeformQuestionOptions: boolean = false
  ) => {
    return someArrayOfQuestions.reduce(
      (
        result: {
          questions: SessionAssessmentQuestion[];
          options: SessionAssessmentQuestionOption[];
        },
        someQuestionObject: AssessmentNest
      ) => {
        const questionWithoutOptions = getQuestionData(
          someQuestionObject.sessionAssessmentQuestion
        );
        const options = someQuestionObject.sessionAssessmentQuestion.sessionAssessmentAnswerList
          .map(getOptionData)
          .filter((option) => {
            return !(shouldOmitFreeformQuestionOptions && option.freeform);
          });

        const question = Object.assign({}, questionWithoutOptions, {
          options: options.map((item) => item.id),
        }) as SessionAssessmentQuestion;
        setKey(result, "questions", result.questions.concat(question));
        setKey(result, "options", result.options.concat(options));

        return result;
      },
      {
        questions: [] as SessionAssessmentQuestion[],
        options: [] as SessionAssessmentQuestionOption[],
      }
    );
  };
  const shouldOmitFreeformQuestionOptions = !client.hasSessionAssessmentQuestionnaireFreeformQuestionOptions(
    input.sessionAssessment.client.id
  );

  return {
    client: getClientData(input.sessionAssessment),
    assessment: getAssessmentData(input.sessionAssessment),
    completed: false,
    ...getQuestionsAndOptions(
      input.sessionAssessmentQuestionList as AssessmentNest[],
      shouldOmitFreeformQuestionOptions
    ),
    responses: [] as SessionAssessmentResponse[],
  };
}

export default client;
