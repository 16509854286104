



















































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import debounce from "lodash/debounce";
import conf from "@/config";
import { reference } from "@/services/reference";
import { Reference } from "@/types/domain";
import ReferenceSearchResult from "./Result.vue";

@Component({
  components: {
    ReferenceSearchResult
  }
})
export default class ReferenceSearch extends Vue {
  @Prop() private tab!: number;

  private query: string = "";
  private results: Reference[] = [];
  private loading: boolean = false;

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get shouldDisplayReferencesNavTab() {
    switch (this.tenant) {
      case "demo":
      case "nsah":
      case "ncvc": {
        return true;
      }

      default: {
        return false;
      }
    }
  }

  get searching() {
    return this.query.trim().length > 1;
  }

  private updateResults = debounce((vm, query) => {
    reference
      .search(query, this.tab + 1)
      .then(results => {
        vm.$emit("updatedResults", results);
      })
      .catch(error => {
        vm.$emit("updatedResults", []);
        console.error("references search error", error);
      });
  }, 1000);

  private clear() {
    this.query = "";
    this.loading = false;
    this.results = [];
  }

  private created() {
    this.$on("updatedResults", (results: Reference[]) => {
      this.results = results;
      this.loading = false;
    });
  }

  private toggleLoader(state: boolean) {
    this.loading = state;
  }

  @Watch("query")
  private onChildChange(val: string, oldVal: string) {
    this.loading = true;
    this.updateResults(this, val);
  }
}
