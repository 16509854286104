
























import { Component, Vue, Prop } from "vue-property-decorator";
import { Reference } from "@/types/domain";
import { EventBus } from "@/eventBus";
import { events } from "@/config";

@Component
export default class ReferenceSearchResult extends Vue {
  @Prop() private reference!: Reference;
  @Prop() private query!: string;
  @Prop() private idx!: number;

  private preview() {
    EventBus.$emit(events.reference.preview, {
      reference: this.reference,
      parent: null,
      path: `Search>Search Query>"${this.query}">Results>#${this.idx + 1}>${
        this.reference.title
      } (ID: ${this.reference.id})`
    });
  }
}
