export default (conf: any): { statusIcons: Record<string, string> } => {
  return {
    statusIcons: {
      [conf.agent.statuses.available]: "ion-ios-checkmark-circle",
      // [conf.agent.statuses.noRotation]: "ion-ios-checkmark-circle",
      [conf.agent.statuses.noRotation]: "ion-ios-remove-circle",
      [conf.agent.statuses.dnd]: "ion-ios-close-circle",
      [conf.agent.statuses.incomingCall]: "ion-md-call",
      [conf.agent.statuses.inCall]: "ion-ios-call",
      [conf.agent.statuses.wrappingUpCall]: "ion-ios-exit",
      [conf.agent.statuses.assessingCall]: "ion-ios-create",
      [conf.agent.statuses.supervisingCall]: "ion-ios-eye",
      [conf.agent.statuses.surveillingCall]: "ion-ios-call"
    }
  };
};
