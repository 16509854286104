import {
  Caller,
  Center,
  SessionAssessmentResponse,
  Voicemail,
} from "@/types/domain";
import { AxiosPromise } from "axios";
import {
  Agent,
  Session,
  Call,
  Client,
  Reference,
  ClientReference,
} from "./domain";

export interface RootState {
  application: string;
}

export interface AuthRetry {
  refresh: boolean;
  call: AxiosPromise | null;
}

export interface AuthState {
  loggedIn: boolean;
  authToken: string | null;
  refreshToken: string | null;
  retry: AuthRetry;
}

export interface AgentState {
  online: Agent[];
}

export interface VoiceState {
  microphone: boolean;
  muted: boolean;
  supervision: {
    status: boolean;
    surveil: boolean;
    agent: string | null;
  };
  iso366Data: null | Record<
    string,
    Record<string, string | string[] | number | number[]>
  >;
}

export interface QueueButtonConfig {
  label: string;
  sound: string;
  clients: number[];
}

export interface QueueState extends QueueButtonConfig {
  id: number;
  size: number;
  visitors: Caller[];
  specialized?: boolean;
}

export interface CallState {
  status: string | null;
  handOffState: HandOffStates;
  client: Client | null;
  references: Reference[];
  clientReferences?: ClientReference[];
  centers?: Center[];
  voicemails: Voicemail[];
  notepad?: string;
  pin: string;
  census?: Record<string, string | number | number[]> | null;
  assessment?: SessionAssessmentResponse[];
  transferredTo: string | number | null;
  current: Call;
  id: string | number | null;
  callStartDate: string | Date | null;
  queueStartDate: string | Date | null;
}

export enum HandOffStates {
  IDLE,
  ON_CALL_WITH_THIRD_PARTY,
  ON_CALL_WITH_THIRD_PARTY_AND_CALLER,
}

// export interface QueueButton extends QueueButtonConfig, QueueState {}
