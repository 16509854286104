import { MutationTree } from 'vuex';
import { AgentState } from '@/types/store';
import { Agent } from '@/types/domain';
import { initialState } from './index';
import { propset } from '../../../config/helpers';

export const mutations: MutationTree<AgentState> = {
    setOnline(state, online: Agent[]) {
        state.online = online;
    },
    reset(state) {
        const init = initialState();
        propset(state, init);
    },
};
