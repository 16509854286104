import isPlainObject from "lodash/isPlainObject";
import { ActionTree } from "vuex";
import { Session } from "@/types/domain";
import { RootState } from "@/types/store";
import { initialState } from "./index";

export const actions: ActionTree<Session, RootState> = {
  set({ commit }, session: Session) {
    commit("setSession", session);
  },
  updateStatus({ commit }, status: string) {
    commit("setStatus", status);
  },
  updateQueueRotations(
    { state, commit },
    queueRotations: Record<number | string, boolean>
  ) {
    if (
      isPlainObject(queueRotations) &&
      Object.keys(queueRotations)
        .map(Number)
        .every((key: number) => !isNaN(key)) &&
      Object.values(queueRotations).every(
        (value: any) => typeof value === "boolean"
      )
    ) {
      commit("setSession", Object.assign({}, state, { queueRotations }));
    }
  },
  updateStatusTimestamp({ state, commit }, timestamp: number) {
    if (typeof timestamp === "number") {
      commit(
        "setSession",
        Object.assign({}, state, {
          statusChangedAt: isNaN(timestamp) ? 0 : timestamp,
        })
      );
    }
  },
  updateSessionDebugInfo(_, info: Record<string, unknown> | null) {
    console.debug(
      "[STORE ACTION]... update session debug info... NoOp",
      new Date().toISOString(),
      info
    );
  },
  reset({ commit }) {
    commit("setSession", initialState());
  },
};
