import { GetterTree } from "vuex";
import { AuthState, RootState } from "@/types/store";

export const getters: GetterTree<AuthState, RootState> = {
  loggedIn: state => {
    return state.loggedIn;
  },
  authToken: state => {
    return state.authToken;
  },
  refreshToken: state => {
    return state.refreshToken;
  },
  retry: state => {
    return state.retry;
  }
};
