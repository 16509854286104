import { ActionTree } from "vuex";
import { QueueState, RootState } from "@/types/store";

export const actions: ActionTree<QueueState[], RootState> = {
  updateSize({ commit }, payload: { label: string; size: number }) {
    const validation =
      typeof payload.label === "string" &&
      payload.label.trim() &&
      Number(payload.size);

    if (validation) {
      commit("setSize", payload);
    }
  },
  updateVisitors({ commit }, payload: { label: string; visitors: any[] }) {
    const validation =
      typeof payload.label === "string" &&
      payload.label.trim() &&
      Array.isArray(payload.visitors);

    if (validation) {
      commit("setVisitors", payload);
    }
  },
  updateQueue({ commit }, payload: QueueState) {
    commit("setQueue", payload);
  },
  update({ commit }, payload: QueueState[]) {
    commit("setQueues", Array.isArray(payload) ? payload : []);
  },
  reset({ commit }) {
    commit("reset");
  }
};
