import app from "./app";
import events from "./events";
import voice from "./voice";
import sessionConfig from "./session";

const config = {
  env: process.env.NODE_ENV,
  mode: process.env.VUE_APP_MODE,
  tenant: process.env.VUE_APP_TENANT,
  launchpadUrl: process.env.VUE_APP_LAUNCHPAD_BASEURL,
  auth: {
    clientId: process.env.VUE_APP_AUTH_CLIENT_ID,
    secret: process.env.VUE_APP_AUTH_CLIENT_SECRET,
    logoutRedirect: process.env.VUE_APP_LAUNCHPAD_BASEURL,
  },
  authEvents: {
    loginSuccess: "auth-login-success",
    loginFailed: "auth-login-failed",
    logoutSuccess: "auth-logout-success",
  },
  agent: {
    roles: {
      all: "*",
      supervisor: (process.env.VUE_APP_SUPERVISOR_ROLE_CODE ||
        "ROLE_ADMIN") as string,
      vas: (process.env.VUE_APP_VAS_ROLE_CODE || "ROLE_VAS") as string,
      staffer: (process.env.VUE_APP_AGENT_ROLE_CODE || "ROLE_USER") as string,
      trainee: (process.env.VUE_APP_TRAINEE_ROLE_CODE ||
        "ROLE_TRAINEE") as string,
      shlStaffer: (process.env.VUE_APP_SHL_STAFFER_ROLE_CODE ||
        "ROLE_SHL_STAFFER") as string,
      readable(role: string) {
        const roles = {
          [this.supervisor]: process.env.VUE_APP_SUPERVISOR_ROLE_LABEL,
          [this.vas]: process.env.VUE_APP_VAS_ROLE_LABEL,
          [this.staffer]: process.env.VUE_APP_AGENT_ROLE_LABEL,
          [this.shlStaffer]: process.env.VUE_APP_SHL_STAFFER_ROLE_LABEL,
          [this.trainee]: process.env.VUE_APP_TRAINEE_ROLE_LABEL,
        };

        return roles[role];
      },
    },
    statuses: {
      available: "AVAILABLE",
      noRotation: "NO_ROTATION",
      dnd: "DND",
      inCall: "ON_CALL",
      incomingCall: "INCOMING_CALL",
      wrappingUpCall: "WRAPPING_UP_CALL",
      supervisingCall: "SUPERVISING_CALL",
      surveillingCall: "SURVEILLING_CALL",
      assessingCall: "ASSESSING_CALL",
      readable(status: string) {
        const statuses = {
          [this.available]: "Available",
          [this.noRotation]: "Available (NR)",
          [this.dnd]: "Do Not Disturb",
          [this.inCall]: "On Call",
          [this.incomingCall]: "Taking Call",
          [this.wrappingUpCall]: "Wrapping Up",
          [this.supervisingCall]: "Supervising Call",
          [this.surveillingCall]: "On Call", // Done intentionally.
          [this.assessingCall]: "Assessing Call",
        };

        return statuses[status];
      },
    },
  },
  stafferEvents: {
    status: "staffer-status-change",
  },
  callEvents: {
    start: "call-start-event",
    end: "call-end-event",
    reset: "call-reset-event",
    wrappingUp: "call-wrapping-up-event",
  },
  environments: {
    development: "dev",
    staging: "staging",
    production: "production",
  },
  referencesFilter: getReferencesFilter(),
  referenceTypes: {
    node: "NODE",
    leaf: "LEAF",
  },
  tabs: {
    servicesOffered: "SERVICES_OFFERED",
    centerSearch: "CENTER_SEARCH",
    onCall: "ON_CALL",
    notes: "NOTES",
  },
  queueEvents: {
    increase: "queue-increase",
  },
};

const session = sessionConfig(config);

export { app, events, voice, session };

export default config;

function getReferencesFilter() {
  const configValues = String(process.env.VUE_APP_REFERENCES_FILTER || "")
    .split(",")
    .map(Number)
    .filter((item: number) => !isNaN(item));
  return Array.isArray(configValues) && configValues.length ? configValues : [];
}
