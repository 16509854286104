import { HandOffStates } from "@/types/store";
import { CallState } from "@/types/store";
import { Module } from "vuex";
import { RootState } from "@/types/store";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";

export function initialState(): CallState {
  return {
    status: null,
    handOffState: HandOffStates.IDLE,
    client: null,
    references: [],
    centers: [],
    clientReferences: [],
    voicemails: [],
    census: null,
    assessment: [],
    notepad: "",
    pin: "",
    transferredTo: null,
    id: null,
    callStartDate: null,
    queueStartDate: null,
    current: {
      id: null,
      languagePreference: null,
      callStartDate: null,
      queueStartDate: null,
      callEndDate: null,
      callLengthSecs: 0,
      hangupOnCaller: true
    }
  };
}

const state: CallState = initialState();

const namespaced: boolean = true;

export const callStore: Module<CallState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
