















import { Component, Vue } from "vue-property-decorator";
import conf, { events } from "@/config";
import { EventBus } from "@/eventBus";
const statuses = conf.agent.statuses;

import isPlainObject from "lodash/isPlainObject";
import startcase from "lodash/startCase";
import truncate from "lodash/truncate";

@Component
export default class IncomingCall extends Vue {
  get client() {
    return this.$store.getters["call/client"];
  }

  get clientName() {
    return isPlainObject(this.client) &&
      typeof this.client.name === "string" &&
      this.client.name.trim().length
      ? truncate(startcase(this.client.name), {
          length: 50,
          separator: /,? +/,
          omission: "...",
        })
      : "";
  }

  private takeCall() {
    console.warn(
      "[TWILIO CALL ACTIVITY] agent initiated 'call.answer' workflow...",
      new Date().toISOString()
    );
    EventBus.$emit(events.call.answer);
  }
}
