import { Reference } from "./../types/domain";
import axios, { AxiosError, AxiosResponse } from "axios";
import conf, { app } from "@/config";

export const reference = {
  async top(tabID: number = 1) {
    return axios
      .get(`${app.apiUrl}/references/top/?tab=${tabID}`)
      .then((res: AxiosResponse) => {
        const data = res.data;
        return Array.isArray(data)
          ? data.filter(
              (item: Reference) =>
                !conf.referencesFilter.includes(Number(item.id))
            )
          : [];
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async children(id: string | number) {
    return axios
      .get(`${app.apiUrl}/references/${id}/children`)
      .then((res: AxiosResponse) => {
        const data = res.data;
        return Array.isArray(data)
          ? data.filter(
              (item: Reference) =>
                !conf.referencesFilter.includes(Number(item.id))
            )
          : [];
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  search(query: string, tab: number = 1) {
    const params = { query, tab, limit: 10 };
    return new Promise((resolve, reject) => {
      axios
        .get(`${app.apiUrl}/references/`, { params })
        .then((res: AxiosResponse) => {
          resolve(res.data);
        })
        .catch((err: AxiosError) => {
          reject(err.message);
        });
    });
  }
};
