











































































import { Component, Vue, Watch } from "vue-property-decorator";
import conf, { events } from "@/config";
import { EventBus } from "@/eventBus";
import config, { voice as voiceConfig } from "@/config";
const statuses = config.agent.statuses;
import auth from "@/services/auth";
import isPlainObject from "lodash/isPlainObject";
import truncate from "lodash/truncate";
import startCase from "lodash/startCase";

@Component
export default class UserActions extends Vue {
  private activeTab: number = 0;
  private showLogoutModal: boolean = false;
  private logoutMessage: string = "Please confirm exit";
  private online: boolean = false;

  get shouldDisplayCensus() {
    const predicate = !["shl"].includes(this.tenant);
    return predicate;
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get dnd() {
    return this.status === statuses.dnd;
  }

  get status() {
    return this.$store.getters["session/status"];
  }

  get rightSidebar() {
    return this.$store.getters["layout/rightSidebar"];
  }

  get canLogout() {
    return [
      statuses.available,
      statuses.noRotation,
      statuses.incomingCall,
      statuses.dnd,
    ].includes(this.status);
  }

  get fullName() {
    return this.$store.getters["session/fullName"];
  }

  get formattedFullName() {
    return typeof this.fullName === "string" && this.fullName.trim().length
      ? truncate(startCase(this.fullName.trim()), {
          length: 12,
          separator: /,? +/,
          omission: "...",
        })
      : "";
  }

  // get debugInfo(): Record<string, unknown> {
  //   return (
  //     this.$store.getters["session/debug"] || {
  //       info: "No session debug info avaliable (yet)",
  //     }
  //   );
  // }

  async copyDebugInfo() {
    try {
      const info = btoa(
        sessionStorage.getItem("thl-session-debug-info") ||
          JSON.stringify({
            info: "No session debug info avaliable (yet)",
          })
      );

      await navigator.clipboard.writeText(info);
      this.$buefy.toast.open({
        type: "is-info",
        message: "Session debug info successfully copied to clipboard.",
      });
    } catch (error) {
      this.$buefy.toast.open({
        type: "is-danger",
        message: "Failed to copy session debug info to clipboard.",
      });
      console.error("Failed to copy session debug info to clipboard", error);
    }
  }

  mounted() {
    this.online = navigator.onLine;
    this.removeUnusedTabContent();
    EventBus.$on("toggle-right-sidebar-panel", (tabIdx: number) => {
      this.activeTab = tabIdx;
    });

    EventBus.$on("close-logout-modal", () => (this.showLogoutModal = false));

    window.addEventListener(
      "online",
      this.onNetworkConnectionChange.bind(this)
    );

    window.addEventListener(
      "offline",
      this.onNetworkConnectionChange.bind(this)
    );
  }

  get initials() {
    const nonEmptyString = (x: any) =>
      typeof x === "string" && Boolean(x.trim().length);

    const first = this.$store.getters["session/firstName"];
    const last = this.$store.getters["session/lastName"];

    return nonEmptyString(first) && nonEmptyString(last)
      ? first.substr(0, 1) + last.substr(0, 1)
      : "";
  }

  logout() {
    if (this.canLogout) {
      const incomingCallStatus =
        this.status === statuses.incomingCall ||
        this.$store.getters["call/status"] === voiceConfig.status.incoming;
      const connection = Vue.prototype.$twilio.getConnection();
      if (incomingCallStatus && connection) {
        this.showLogoutModal = true;
      } else {
        this.$store.dispatch("auth/logout");
      }
    } else {
      this.$buefy.toast.open({
        type: "is-info",
        message:
          "You can't log out while handling, supervising, or assessing a call.",
      });
    }
  }

  closeLogoutModal() {
    this.showLogoutModal = false;
  }

  @Watch("activeTab")
  private navTabs() {
    this.removeUnusedTabContent();
    if (this.activeTab === 0) {
      EventBus.$emit("show-references-tab");
    } else if (this.activeTab === 1) {
      EventBus.$emit("show-census-tab");
    }
  }

  @Watch("rightSidebar")
  private sidebarToggleAction() {
    this.removeUnusedTabContent();
  }

  private removeUnusedTabContent() {
    const $user = document.getElementById("user");
    if ($user) {
      const $tabContent = $user.querySelector(".tab-content");
      if ($tabContent) {
        $tabContent.remove();
      }
    }
  }

  onNetworkConnectionChange(evt: Event) {
    console.info("[NETWORK CONNECTION STATUS EVENT]", evt);
    const connected = navigator.onLine;
    if (!connected) {
      Vue.prototype.$buefy.snackbar.open({
        message: "Network Disconnection Detected.",
        type: "is-danger",
        position: "is-bottom-right",
        actionText: "Clear",
        duration: 5 * 1000,
        // indefinite: true,
        queue: false,
      });
    } else if (!this.online && connected) {
      Vue.prototype.$buefy.snackbar.open({
        message: "Network Connection Resumed.",
        type: "is-success",
        position: "is-bottom-right",
        actionText: "Clear",
        duration: 5 * 1000,
        // indefinite: true,
        queue: false,
      });
    }

    this.online = connected;
  }

  beforeDestroy() {
    window.removeEventListener(
      "online",
      this.onNetworkConnectionChange.bind(this)
    );

    window.removeEventListener(
      "offline",
      this.onNetworkConnectionChange.bind(this)
    );
  }
}
