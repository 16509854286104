const events = {
  twilio: {
    deviceSetup: "twilio-event-device-setup",
    deviceReset: "twilio-event-device-reset",
    incoming: "twilio-event-incoming",
    disconnect: "twilio-event-disconnect",
    cancel: "twilio-event-cancel",
    connect: "twilio-event-connect",
    offline: "twilio-event-offline",
    ready: "twilio-event-ready"
  },
  session: {
    authSuccess: "session-auth-success",
    authFailure: "session-auth-failure"
  },
  call: {
    answer: "call/answer",
    start: "call-start-event",
    end: "call-end-event",
    reset: "call-reset-event",
    wrappingUp: "call-wrapping-up-event",
    handingOff: "call-handing-off-event",
    assess: "call-assess-event",
    supervise: "call-supervise-event"
  },
  reference: {
    preview: "reference/preview",
    verify: "reference/verify"
  },
  clientReference: {
    preview: "client-reference/preview",
    verify: "client-reference/verify"
  },
  center: {
    preview: "center/preview",
    verify: "center/verify"
  },
  onCallAdvocate: {
    preview: "on-call-schedule/preview",
    verify: "on-call-schedule/verify"
  },
  interactions: {
    clearPreviewPanel: "interactions/clear-preview-panel"
  }
};

export default events;
