import _Vue from "vue";

export function propset(target: any, properties: any) {
  // Replaces target props with source props.
  Object.keys(properties).forEach((key: string) => {
    target[key] = properties[key];
  });
}

export function paramstr(key: string, value: string | number) {
  // Returns a single URL parameter string;
  return `${key}=${value}`;
}

export function queryStr(params: any) {
  // Returns URL string of params object.
  return Object.keys(params)
    .map((key: string) => key + "=" + params[key])
    .join("&");
}

export function success(vue: any, message: string) {
  vue.$buefy.toast.open({
    // type: "is-success",
    type: "is-info",
    message
  });
}

export function failure(vue: any, message: string) {
  vue.$buefy.toast.open({
    type: "is-danger",
    message
  });
}

export function warning(vue: any, message: string) {
  vue.$buefy.toast.open({
    type: "is-warning",
    message
  });
}

export function info(vue: any, message: string) {
  vue.$buefy.toast.open({
    type: "is-info",
    message
  });
}

export function rekey(
  obj: { [oldKey: string]: any },
  oldKey: string,
  newKey: string
) {
  if (oldKey !== newKey) {
    delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey];
  }
}

export default { propset, paramstr };
