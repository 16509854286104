import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";
import Login from "./views/Login.vue";
import store from "./store";
import conf from "@/config";
import Cookies from "js-cookie";

Vue.use(Router);

const router: Router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/login",
      name: "login",
      component: Login,
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "/external-login",
      name: "external-login",
      component: () => import("./views/ExternalLogin.vue"),
      meta: {
        requiresGuest: true,
      },
    },
    {
      path: "*",
      name: "not-found",
      component: () => import("./views/NotFound.vue"),
    },
  ],
});

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authTokens = () => {
      const fromStore = {
        accessToken: store.getters["auth/authToken"],
        refreshToken: store.getters["auth/refreshToken"],
      };

      const fromCookies = {
        accessToken: Cookies.get("access_token"),
        refreshToken: Cookies.get("refresh_token"),
      };

      return { fromStore, fromCookies };
    };

    const tokens = authTokens();

    if (tokens.fromStore.accessToken && tokens.fromStore.refreshToken) {
      next();
    } else if (
      tokens.fromCookies.accessToken &&
      tokens.fromCookies.refreshToken
    ) {
      try {
        await store.dispatch("auth/sso", tokens.fromCookies);
        next();
      } catch (error) {
        console.error("SSO Login failed...", error);
        next({ name: "login" });
      }
    } else {
      if (conf.env !== "development") {
        window.location.replace(conf.auth.logoutRedirect || "/login");
      } else {
        next({ name: "login" });
      }
    }
  } else if (to.matched.some((record) => record.meta.requiresGuest)) {
    if (store.getters["auth/authToken"]) {
      next({ name: "home" });
    } else if (to.name === "external-login") {
      next({ name: "login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
