import { ActionTree } from 'vuex';
import { Agent } from '@/types/domain';
import { AgentState, RootState } from '@/types/store';

export const actions: ActionTree<AgentState, RootState> = {
  update({ state, commit }, online: Agent[]) {
    commit('setOnline', online);
  },
  reset({ commit }) {
    commit('reset');
  }
};
