import { actions } from "./actions";
import { mutations } from "./mutations";
import { getters } from "./getters";
import { RootState, QueueState } from "@/types/store";
import { Module } from "vuex";

const state: QueueState[] = [];

const namespaced: boolean = true;

export const queueStore: Module<QueueState[], RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations
};
