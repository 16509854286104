











































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { client as clientService } from "@/services/client";
import { Client, OnCallAdvocate } from "@/types/domain";
import { EventBus } from "@/eventBus";
import { events } from "@/config";
// import truncate from "lodash/truncate";
import moment from "moment";
import momentTZ from "moment-timezone";
// import DataTable from "@/components/common/panels/DataTable.vue";

const FlexSearch = require("flexsearch");

const MIN_TIMESTAMP = moment().startOf("hour").subtract(24, "hour").toDate();

const MAX_TIMESTAMP = moment().startOf("hour").add(24, "hour").toDate();

@Component
export default class OnCall extends Vue {
  private isPaginationSimple: boolean = true;
  private currentPage: number = 1;
  private perPage: number = 5;
  private defaultSortDirection: string = "asc";
  private isNarrowed: boolean = true;
  private openedDetails: any[] = [];
  private schedule: any[] = [];
  private queriedSchedule: any[] = [];
  private minTimestamp: Date = MIN_TIMESTAMP;
  private maxTimestamp: Date = MAX_TIMESTAMP;
  private selectedTimestamps: Date[] = [MIN_TIMESTAMP, MAX_TIMESTAMP];
  private selectedOnCallAdvocate: OnCallAdvocate | null = null;
  private search: string = "";
  private queryIndex!: any;

  mounted() {
    this.queryIndex = FlexSearch.create({
      doc: {
        id: "id",
        field: ["priority", "name", "phone"],
      },
    });
    this.useSubscriptions();
  }

  @Watch("selectedOnCallAdvocate", { deep: true })
  private onSelectedOnCallAdvocateUpdate() {
    if (this.selectedOnCallAdvocate) {
      EventBus.$emit(
        events.onCallAdvocate.preview,
        this.selectedOnCallAdvocate
      );
    }
  }

  get startDate() {
    return moment(this.selectedTimestamps[0] || MIN_TIMESTAMP)
      .toDate()
      .getTime();
  }

  get endDate() {
    return moment(this.selectedTimestamps[1] || MAX_TIMESTAMP)
      .toDate()
      .getTime();
  }

  get timestamps() {
    return `${this.startDate}-${this.endDate}`;
  }

  private useSubscriptions() {
    EventBus.$on(events.call.reset, this.reset);
    // EventBus.$on(events.call.end, this.reset);
    EventBus.$on(events.call.start, this.fetchContent);
    EventBus.$on(events.interactions.clearPreviewPanel, () => {
      this.selectedOnCallAdvocate = null;
    });
  }

  get totalScheduleRecords() {
    return Array.isArray(this.schedule) ? this.schedule.length : 0;
  }

  @Watch("totalScheduleRecords", { immediate: true })
  private onScheduleRecordsChange(newVal: number, oldVal: number) {
    if (!isNaN(newVal) && newVal !== oldVal && newVal > 0) {
      this.clientId && this.queryIndex && this.queryIndex.remove(this.schedule);
      this.clientId && this.queryIndex && this.queryIndex.add(this.schedule);
      this.find();
    } else {
      this.queryIndex && this.queryIndex.remove();
      this.find();
    }
  }

  @Watch("search", { immediate: true })
  private onSearchQueryUpdate() {
    this.find();
  }

  @Watch("timestamps", { immediate: true })
  private onTimestampsQueryUpdate() {
    this.fetchContent();
  }

  private find() {
    if (this.search.trim()) {
      this.queryIndex.search(this.search, {
        field: ["name", "phone"],
        suggest: true,
        callback: (results: any[]) => {
          this.queriedSchedule = results;
        },
      });
    } else {
      this.queriedSchedule = this.schedule;
    }
  }

  private onCallRecordTransformer(recordObj: any): OnCallAdvocate {
    const {
      id,
      firstName,
      lastName,
      startShiftTime,
      endShiftTime,
      phoneNumber,
      notes,
      priority,
    } = recordObj;

    const dateRangeSeparator = " - ";

    return {
      id,
      priority: isNaN(priority) ? 1 : Number(priority),
      name: `${firstName} ${lastName}`,
      start: `
      ${momentTZ(new Date(startShiftTime))
        .tz(momentTZ.tz.guess())
        .format("MM/DD/YYYY h:mm A (z)")}`,
      end: `
      ${momentTZ(new Date(endShiftTime))
        .tz(momentTZ.tz.guess())
        .format("MM/DD/YYYY h:mm A (z)")}
      `,
      shift: `
      ${momentTZ(new Date(startShiftTime))
        .tz(momentTZ.tz.guess())
        .format("MM/DD/YYYY h:mm A")}
        ${dateRangeSeparator}
      ${momentTZ(new Date(endShiftTime))
        .tz(momentTZ.tz.guess())
        .format("h:mm A (z)")}
      `,
      phone: String(phoneNumber).replace(/\D/g, ""),
      // notes: truncate(notes, {
      //   length: 150,
      //   separator: /,? +/,
      //   omission: "..."
      // })
      notes,
      shiftTimestamps: {
        start: momentTZ(new Date(startShiftTime))
          .tz(momentTZ.tz.guess())
          .valueOf(),
        end: momentTZ(new Date(endShiftTime)).tz(momentTZ.tz.guess()).valueOf(),
      },
    };
  }

  public setOpenedDetails(row: any) {
    this.openedDetails = [row.id];
  }

  public sortByStartDate(a: any, b: any, isAsc: boolean) {
    if (isAsc) {
      return (
        b.shiftTimestamps.start - a.shiftTimestamps.start ||
        b.priority - a.priority
      );
    } else {
      return (
        a.shiftTimestamps.start - b.shiftTimestamps.start ||
        a.priority - b.priority
      );
    }
  }

  public sortByEndDate(a: any, b: any, isAsc: boolean) {
    if (isAsc) {
      return b.shiftTimestamps.end - a.shiftTimestamps.end;
    } else {
      return a.shiftTimestamps.end - b.shiftTimestamps.end;
    }
  }

  get clientId() {
    const client: Client = this.$store.getters["call/client"];
    return client && client.id ? client.id : null;
  }

  public async fetchContent() {
    if (this.clientId) {
      const now = momentTZ(new Date()).tz(momentTZ.tz.guess()).valueOf();

      const _2hrsFromNow = momentTZ(
        moment().startOf("hour").add(2, "hour").toDate()
      )
        .tz(momentTZ.tz.guess())
        .valueOf();

      const params = {
        clientId: this.clientId,
        endDateAfter: this.startDate,
        startDateBefore: this.endDate,
      };

      const data = await clientService.getOnCall(this.clientId, params);
      this.schedule = data
        .map(this.onCallRecordTransformer)
        .filter(
          (record: OnCallAdvocate) =>
            record.shiftTimestamps.start <= _2hrsFromNow &&
            record.shiftTimestamps.end >= now
        );
    } else {
      this.reset();
    }
  }

  private reset() {
    this.schedule = [];
    this.queriedSchedule = [];
    this.minTimestamp = MIN_TIMESTAMP;
    this.maxTimestamp = MAX_TIMESTAMP;
    this.selectedTimestamps = [MIN_TIMESTAMP, MAX_TIMESTAMP];
    this.openedDetails = [];
  }

  beforeDestroy() {
    this.queryIndex.destroy();
  }
}
