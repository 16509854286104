import { Session } from "@/types/domain";
import { RootState } from "@/types/store";
import { getters } from "./getters";
import { actions } from "./actions";
import { mutations } from "./mutations";
import { Module } from "vuex";

export function initialState(): Session {
  return {
    id: null,
    statusChangedAt: 0,
    queueRotations: {},
    role: null,
    displayName: null,
    firstName: null,
    lastName: null,
    capabilityToken: null,
    status: null,
    debug: null,
    callQueues: [],
  };
}

const state: Session = initialState();

const namespaced: boolean = true;

export const sessionStore: Module<Session, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
