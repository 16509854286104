import axios, { AxiosError, AxiosResponse } from "axios";
import { app } from "@/config";
import { EventBus } from "@/eventBus";
import { paramstr } from "@/config/helpers";
const logger = (...info: any[]) => console.debug("[QUEUE SERVICE]", ...info);

export const queue = {
  async take(stafferId: string, callerId: string | null = null) {
    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    const formData = new FormData();
    if (stafferId) {
      formData.append("stafferId", stafferId);
    }

    if (callerId) {
      formData.append("callId", callerId);
    }

    return axios
      .post(`${app.apiUrl}/call/take-call`, formData, config)
      .then((res: AxiosResponse) => {
        logger("takeCall() success...");
        EventBus.$emit("queue/take");

        return res;
      })
      .catch((err: AxiosError) => {
        logger("takeCall() error...");
        EventBus.$emit("queue/failure");

        throw err;
      });
  }
};
