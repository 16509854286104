





































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { client as clientService } from "@/services/client";
import { Client } from "@/types/domain";
import { EventBus } from "@/eventBus";
import { events } from "@/config";
import LightBox from "vue-image-lightbox";
import isEmpty from "lodash/isEmpty";
import { failure, success } from "../../../config/helpers";

@Component({
  components: {
    LightBox,
  },
})
export default class Scripts extends Vue {
  private html: string = "";
  private images: any[] = [];
  private data: any | null = null;
  private s3Baseurl: string = process.env.VUE_APP_S3_BASEURL || "";

  get clientId() {
    const client: Client = this.$store.getters["call/client"];
    return client && client.id ? client.id : null;
  }

  get callpin() {
    const pin = this.$store.getters["call/pin"];
    return pin;
  }

  mounted() {
    this.useSubscriptions();
  }

  private useSubscriptions() {
    EventBus.$on(events.call.start, () => {
      this.fetchContent();
    });

    EventBus.$on(events.call.reset, this.reset);
    // EventBus.$on(events.call.end, this.reset);
  }

  private async copytoclipboard() {
    if (this.callpin) {
      try {
        await navigator.clipboard.writeText(this.callpin);
        success(this, "Call pin copied to clipboard");
      } catch (error) {
        failure(this, "Failed to copy call pin to clipboard");
        console.error(
          "Failed to copy call pin to clipboard",
          error,
          this.callpin
        );
      }
    } else {
      failure(this, "Failed to copy call pin to clipboard");
      console.error("Failed to copy call pin to clipboard", this.callpin);
    }
  }

  private async fetchContent() {
    if (this.clientId) {
      clientService
        .getScript(this.clientId)
        .then((data: any) => {
          if (Array.isArray(data) && data.length) {
            const script = data[0];
            this.html = script.text;
            this.images = this.loadScriptImages(script);
            this.data = {
              html: this.html,
              images: this.images,
            };
          } else {
            this.reset();
          }
        })
        .catch((err) => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Error: Script failed to loaded.",
          });
          console.error(err);
        });
    } else {
      this.reset();
    }
  }

  private loadScriptImages(script: any) {
    const images = script.images || script.scriptImages || [];
    return images.map((image: any) => ({
      caption: image.name
        .split("::")[1]
        .replace(/\[/, "")
        .replace(/\]/, ""),
      src: image.url.replace(".png", "-optimized.png"),
      thumb: image.url.replace(".png", "-small.png"),
    }));
  }

  private openGallery(index: number) {
    //@ts-ignore
    this.$refs.lightbox.showImage(index);
  }

  private reset() {
    this.data = null;
    this.images = [];
    this.html = "";
  }
}
