


















import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ErrorMessages extends Vue {
  @Prop() private errors!: string[];

  private close() {
    this.$emit("close");
  }
}
