var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card h-100"},[_c('header',{staticClass:"card-header"},[_c('div',{staticClass:"card-header-title"},[_c('span',[_vm._v(" Script ")]),(_vm.callpin)?_c('div',{staticClass:"call-pin-container"},[_c('span',{staticClass:"call-pin-code"},[_vm._v(_vm._s(_vm.callpin))]),_c('div',{staticStyle:{"cursor":"pointer","display":"flex","flex-direction":"column","align-items":"center","margin-left":".25em"}},[_c('img',{staticClass:"image",attrs:{"src":(_vm.s3Baseurl + "/images/copy_to_clipboard.svg"),"alt":"Copy Call PIN Clipboard"},on:{"click":function($event){$event.preventDefault();return _vm.copytoclipboard($event)}}})])]):_vm._e()])]),_c('div',{staticClass:"card-content h-100"},[_c('div',{staticClass:"tile is-ancestor h-100"},[_c('div',{staticClass:"tile is-vertical"},[_c('div',{staticClass:"tile"},[_c('div',{staticClass:"tile is-parent"},[_c('article',{staticClass:"tile is-child"},[(
                  !_vm.data || typeof _vm.html !== 'string' || !_vm.html.trim().length
                )?_c('div',{staticClass:"content",staticStyle:{"word-break":"break-word !important"}},[_vm._m(0)]):_c('div',{staticClass:"ql-editor",staticStyle:{"padding":"none !important"}},[_c('div',{staticStyle:{"word-break":"break-word"},domProps:{"innerHTML":_vm._s(_vm.html)}})])])])]),(
            _vm.data &&
              typeof _vm.html === 'string' &&
              _vm.html.trim().length &&
              Array.isArray(_vm.images) &&
              _vm.images.length
          )?_c('LightBox',{ref:"lightbox",attrs:{"images":_vm.images,"show-caption":true,"show-light-box":false}}):_vm._e()],1)])]),(
      _vm.data &&
        typeof _vm.html === 'string' &&
        _vm.html.trim().length &&
        Array.isArray(_vm.images) &&
        _vm.images.length
    )?_c('footer',{staticClass:"card-footer"},[(typeof _vm.html === 'string' && _vm.html.trim().length)?_c('div',{staticClass:"is-flex",staticStyle:{"padding":"0.5rem !important"}},_vm._l((_vm.images),function(image,index){return _c('div',{key:index,staticStyle:{"padding-right":"0.5rem !important","padding-left":"0.5rem !important"}},[_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(image.thumb),expression:"image.thumb"}],staticClass:"image",staticStyle:{"height":"45px","width":"45px","cursor":"pointer"},on:{"click":function($event){return _vm.openGallery(index)}}})])}),0):_vm._e()]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fg flex"},[_c('i',{staticClass:"fg-primary ion-ios-information-circle-outline mar-r"}),_vm._v(" Script content will be displayed (if available) once a call begins. ")])}]

export { render, staticRenderFns }