import {
  ClientReference,
  Center,
  Voicemail,
  DemographicFreeformAnswer,
} from "./../types/domain";
import axios, { AxiosResponse, AxiosError } from "axios";
import conf, { app, events, voice as voiceConfig } from "@/config";
import store from "@/store";
import clientService from "@/services/client";
import { Client, Reference } from "@/types/domain";
import Vue from "vue";
import isPlainObject from "lodash/isPlainObject";
import * as Twilio from "twilio-client/es5/twilio";
import { isEmpty } from "lodash";
import { session } from "./session";
import { EventBus } from "@/eventBus";

export const call = {
  incomingTimer: null,
  callTimer: null,
  postConfig: {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
  async updateCallDetails(data: object) {
    return new Promise((resolve, reject) => {
      axios
        .put(`${app.apiUrl}/call`, data)
        .then(async (res: AxiosResponse) => {
          const { id, languagePreference, enteredPin: pin } = res.data;
          await store.dispatch("call/update", {
            id,
            languagePreference,
            pin: typeof pin === "string" && pin.trim() ? pin.trim() : "",
            voicemails: await clientService.getVoicemails(),
          });

          resolve(res.data);
        })
        .catch((err: Error | AxiosError) => {
          console.error("error occurred on update call details endpoint", err);
          reject(err);
        });
    });
  },
  async getCallDetails(conn?: unknown) {
    return new Promise((resolve, reject) => {
      axios
        .get(`${app.apiUrl}/call`)
        .then(async (res: AxiosResponse) => {
          const data = res.data;

          console.warn(
            "[TWILIO CALL ACTIVITY] call details from backend",
            new Date().toISOString(),
            data
          );

          const props = {
            id: data.id,
            pin:
              typeof data.enteredPin === "string" && data.enteredPin.trim()
                ? data.enteredPin.trim()
                : "",
            voicemails: await clientService.getVoicemails(),
            languagePreference: data.languagePreference,
            callStartDate: data.callStartDate,
            queueStartDate: data.queueStartDate,
          };

          const client: Client = data.client;

          await store.dispatch("call/update", props);
          await store.dispatch("call/updateClient", client);

          resolve(res.data);
        })
        .catch((err: Error | AxiosError) => {
          console.error("error occurred on call details endpoint", err);
          reject(err);
        });
    });
  },
  async getCallDetailsStafferId(stafferId: string) {
    const params = { stafferId };

    return new Promise((resolve, reject) => {
      axios
        .get(`${app.apiUrl}/call/by-staffer-id`, { params })
        .then(async (res: AxiosResponse) => {
          const data = res.data;

          const props = {
            id: data.id,
            voicemails: await clientService.getVoicemails(),
            pin:
              typeof data.enteredPin === "string" && data.enteredPin.trim()
                ? data.enteredPin.trim()
                : "",
            languagePreference: data.languagePreference,
            callStartDate: data.callStartDate,
            queueStartDate: data.queueStartDate,
          };

          const client: Client = data.client;

          await store.dispatch("call/update", props);
          await store.dispatch("call/updateClient", client);

          resolve(res.data);
        })
        .catch((err: Error | AxiosError) => {
          console.error(
            "error occurred on call details by staffer id endpoint",
            err
          );
          reject(err);
        });
    });
  },
  async warmHandOff(thirdParty: string, isIVR: boolean = false) {
    const params = { thirdParty, isIVR };

    return axios
      .post(
        `${app.apiUrl}/call/warm-hand-off/start`,
        {},
        {
          ...this.postConfig,
          params,
        }
      )
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async sendToVoicemail(voicemail: Voicemail): Promise<boolean> {
    return new Promise((resolve, reject) => {
      axios
        .post(
          `${app.apiUrl}/call/send-to-end-message?endMessageID=${voicemail.id}`
        )
        .then(() => {
          // @ts-ignore
          window.__callEndEventFlags = {
            id: store.getters["call/id"],
            hangupOnCaller: false,
            blockCaller: voicemail.blockCaller === true,
            assessCallOnLeave: true,
          };

          console.debug(
            "[TWILIO CALL ACTIVITY] sent caller to end message successfully",
            voicemail,
            // @ts-ignore
            window.__callEndEventFlags
          );
          resolve(true);
        })
        .catch(reject);
    });
  },
  async addCaller() {
    return axios
      .get(`${app.apiUrl}/call/warm-hand-off/add-caller`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async hangupParty() {
    return axios
      .get(`${app.apiUrl}/call/warm-hand-off/hangup-third-party`)
      .then((res: AxiosResponse) => {
        if (res.data) {
          return res.data;
        } else {
          Promise.reject(res);
        }
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async leave(inputs?: any) {
    const addArrayToFormData = (
      formDataObj: FormData,
      key: string,
      arr: any[]
    ) => {
      return arr.reduce((result: FormData, item: any, idx: number) => {
        result.append(`${key}[]`, item);
        return result;
      }, formDataObj);
    };

    const data = isPlainObject(inputs) ? inputs : {};

    const formData = new FormData();

    formData.set("hangupOnCaller", String(Boolean(data.hangupOnCaller)));
    formData.set("assessCallOnLeave", String(Boolean(data.assessCallOnLeave)));
    formData.set("blockCaller", String(Boolean(data.blockCaller)));

    addArrayToFormData(
      formData,
      "references",
      Array.isArray(data.references)
        ? data.references.map((ref: Reference) => Number(ref.id))
        : []
    );

    addArrayToFormData(
      formData,
      "clientReferences",
      Array.isArray(data.clientReferences)
        ? data.clientReferences.map((ref: ClientReference) => String(ref.ID))
        : []
    );

    addArrayToFormData(
      formData,
      "demographics",
      Array.isArray(data.demographics) ? data.demographics : []
    );

    addArrayToFormData(
      formData,
      "centers",
      Array.isArray(data.centers)
        ? data.centers.map((ref: Center) => Number(ref.id))
        : []
    );

    return axios
      .post(`${app.apiUrl}/call/leave`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res: AxiosResponse) => {
        // store.dispatch("call/clearCensusResponses");
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async updateResources(inputs?: any) {
    const addArrayToFormData = (
      formDataObj: FormData,
      key: string,
      arr: any[]
    ) => {
      return arr.reduce((result: FormData, item: any) => {
        result.append(`${key}[]`, item);
        return result;
      }, formDataObj);
    };

    const addJSONToFormData = (
      formDataObj: FormData,
      key: string,
      json: string
    ) => {
      formDataObj.set(key, json);
      return formDataObj;
    };

    const data = isPlainObject(inputs) ? inputs : {};

    const formData = new FormData();

    formData.set("callId", String(Number(data.callId)));

    addArrayToFormData(
      formData,
      "references",
      Array.isArray(data.references)
        ? data.references.map((ref: Reference) => Number(ref.id))
        : []
    );

    addArrayToFormData(
      formData,
      "clientReferences",
      Array.isArray(data.clientReferences)
        ? data.clientReferences.map((ref: ClientReference) => String(ref.ID))
        : []
    );

    addArrayToFormData(
      formData,
      "demographics",
      Array.isArray(data.demographics) ? data.demographics : []
    );

    addArrayToFormData(
      formData,
      "centers",
      Array.isArray(data.centers)
        ? data.centers.map((ref: Center) => Number(ref.id))
        : []
    );

    const demographicFreeformAnswers = (() => {
      const answers = Array.isArray(data.demographicFreeformChoices)
        ? (data.demographicFreeformChoices as DemographicFreeformAnswer[])
        : ([] as DemographicFreeformAnswer[]);

      return answers.reduce(
        (
          fold: Record<string, string>,
          freeformAnswer: DemographicFreeformAnswer
        ) => {
          if (
            typeof freeformAnswer.answer === "string" &&
            freeformAnswer.answer.trim().length
          ) {
            fold[String(freeformAnswer.optionId)] = freeformAnswer.answer;
          }
          return fold;
        },
        {}
      );
    })();

    if (
      isPlainObject(demographicFreeformAnswers) &&
      !isEmpty(demographicFreeformAnswers)
    ) {
      addJSONToFormData(
        formData,
        "demographicFreeformChoices",
        JSON.stringify(demographicFreeformAnswers)
      );
    }

    return axios
      .patch(
        `${app.apiUrl}/call/update?callId=${Number(data.callId)}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async didNotAnswer(options?: { disconnect: boolean }) {
    return new Promise((resolve, reject) => {
      const deviceStatus = Vue.prototype.$twilio.getDeviceStatus();
      const connectionStatus = Vue.prototype.$twilio.getConnectionStatus();

      const sessionStatus = store.getters["session/status"];
      const callStatus = store.getters["call/status"];

      const incomingCallStatus =
        sessionStatus === conf.agent.statuses.incomingCall ||
        [
          voiceConfig.status.incoming,
          voiceConfig.status.answering,
          voiceConfig.status.taking,
        ].includes(callStatus);

      const predicate =
        incomingCallStatus ||
        deviceStatus === Twilio.Device.Status.Busy ||
        [
          Twilio.Connection.State.Pending,
          Twilio.Connection.State.Ringing,
          Twilio.Connection.State.Connecting,
          Twilio.Connection.State.Open,
          Twilio.Connection.State.Reconnecting,
        ].includes(connectionStatus);

      if (predicate) {
        axios
          .post(`${app.apiUrl}/call/did-not-answer`)
          .then(async () => {
            const client: Client = store.getters["call/client"];
            Vue.prototype.$buefy.snackbar.open({
              message: isPlainObject(client)
                ? `${client.name} missed call`
                : `Missed Call`,
              type: "is-warning",
              position: "is-bottom-left",
              actionText: "Clear",
              indefinite: true,
              queue: false,
            });
            await store.dispatch("call/stopTimer");
            await store.dispatch("call/reset");
            resolve(true);
          })
          .catch((err) => {
            const timeout = setTimeout(async () => {
              try {
                const isAuthenticated = Boolean(store.getters["auth/loggedIn"]);
                await session.reset(!isAuthenticated);
                // eslint-disable-next-line vue/custom-event-name-casing
                EventBus.$emit("realtime/stop");
                EventBus.$emit(events.twilio.deviceReset);
                window.location.reload();
              } catch (error) {
                console.error(
                  "[call/did-not-answer error] session reset error",
                  error
                );
              } finally {
                console.error("[call/did-not-answer error] err from api", err);
                clearTimeout(timeout);
                reject(err);
              }
            }, 5 * 1000);
          });
      } else {
        resolve(true);
      }
    });
  },
  async transfer(stafferId: string | number) {
    const params = { stafferId };
    return store
      .dispatch("call/update", { transferredTo: stafferId })
      .then(() => {
        return axios.post(
          `${app.apiUrl}/call/transfer-to-staffer`,
          {},
          { ...this.postConfig, params }
        );
      })
      .then(() => true)
      .catch(async (err: AxiosError) => {
        try {
          await store.dispatch("call/update", { transferredTo: null });
        } catch (error) {
          console.error(
            "error occurred while doing transfer (resetting call transfer ref",
            error
          );
        } finally {
          console.error("error occurred while doing transfer", err);
          throw err;
        }
      });
  },
  async cancel() {
    return axios
      .get(`${app.apiUrl}/call/cancel`)
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async addSupervisor(stafferId: string | number) {
    const params = { stafferId };
    return axios
      .post(
        `${app.apiUrl}/call/add-supervisor`,
        {},
        { ...this.postConfig, params }
      )
      .then((res: AxiosResponse) => {
        return res.data;
      })
      .catch((err: AxiosError) => {
        throw err;
      });
  },
};
