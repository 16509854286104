import isPlainObject from "lodash/isPlainObject";
import { RootState } from "@/types/store";
import { GetterTree, Module, MutationTree, ActionTree } from "vuex";
import Vue from "vue";
import { propset } from "@/config/helpers";

interface LayoutState {
  sidebars: { left: boolean; right: boolean };
}
export function initialState(): LayoutState {
  return {
    sidebars: {
      left: true,
      right: true
    }
  };
}

const namespaced: boolean = true;

const layoutState: LayoutState = initialState();

const getters: GetterTree<LayoutState, RootState> = {
  sidebars: state => state.sidebars,
  leftSidebar: state => state.sidebars.left,
  rightSidebar: state => state.sidebars.right
};

const mutations: MutationTree<LayoutState> = {
  setSidebarState(state, payload: { side: "left" | "right"; state: boolean }) {
    Vue.prototype.$set(state.sidebars, payload.side, payload.state);
  },
  reset(state) {
    propset(state, initialState());
  }
};

const actions: ActionTree<LayoutState, RootState> = {
  toggleSidebar(
    { commit },
    payload: { side: "left" | "right"; state: boolean }
  ) {
    if (
      isPlainObject(payload) &&
      ["left", "right"].includes(payload.side) &&
      typeof payload.state === "boolean"
    ) {
      commit("setSidebarState", payload);
    }
  },
  reset({ commit }) {
    commit("reset");
  }
};

export const layoutStore: Module<LayoutState, RootState> = {
  namespaced,
  state: layoutState,
  getters,
  actions,
  mutations
};
