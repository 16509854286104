





























































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { reference as referenceService } from "@/services/reference";
import isEqual from "lodash/isEqual";
import { Reference } from "@/types/domain";
import { EventBus } from "@/eventBus";
import conf, { events } from "@/config";

@Component({
  name: "Tree",
  components: {
    Tree
  }
})
export default class Tree extends Vue {
  @Prop() private reference!: Reference;
  @Prop() private parent!: Reference;
  @Prop() private top!: any;
  @Prop(String) private path!: string;
  @Prop(Boolean) private omitBottomBorder!: boolean;

  private types = conf.referenceTypes;
  private status: boolean | null = null;
  private children: any[] = [];
  private showChildren: boolean = false;

  mounted() {
    EventBus.$on(
      "set-active-top-level-reference-category",
      (referenceId: string | number) => {
        if (
          !this.parent &&
          String(this.reference.id).trim() !== String(referenceId).trim()
        ) {
          this.showChildren = false;
        }
      }
    );
  }

  get active() {
    return this.showChildren && this.children && this.children.length;
  }

  private async setChildren() {
    if (this.children && this.children.length > 0) {
      this.showChildren = !this.showChildren;
      if (!this.parent) {
        EventBus.$emit(
          "set-active-top-level-reference-category",
          this.reference.id
        );
      }
      return;
    }

    if (
      this.reference &&
      this.reference.id &&
      this.reference.type &&
      this.reference.type === conf.referenceTypes.node
    ) {
      const children = await referenceService.children(
        Number(this.reference.id)
      );
      this.children = children;

      this.showChildren = !this.showChildren;
      if (!this.parent) {
        EventBus.$emit(
          "set-active-top-level-reference-category",
          this.reference.id
        );
      }
    }
  }

  private refObject() {
    return {
      ...this.reference,
      parent: this.parent ? { ...this.parent } : null
    };
  }

  private preview() {
    EventBus.$emit(events.reference.preview, {
      reference: this.reference,
      parent: this.parent,
      path: `${this.path}>${this.reference.title}`
    });
  }
}
