import { VoiceState, RootState } from "@/types/store";
import { GetterTree, Module, MutationTree, ActionTree } from "vuex";
import Vue from "vue";
import { propset } from "../../config/helpers";

export function initialState(): VoiceState {
  return {
    microphone: false,
    muted: false,
    supervision: {
      status: false,
      surveil: false,
      agent: null,
    },
    iso366Data: null,
  };
}

const namespaced: boolean = true;

const voiceState: VoiceState = initialState();

const getters: GetterTree<VoiceState, RootState> = {
  microphone: (state) => state.microphone,
  muted: (state) => state.muted,
  supervision: (state) => state.supervision,
  supervisionStatus: (state) => state.supervision.status,
  surveillanceStatus: (state) => state.supervision.surveil,
  iso366Data: (state) => state.iso366Data,
};

const mutations: MutationTree<VoiceState> = {
  setMicrophoneAccess(state, microphone: boolean) {
    state.microphone = microphone;
  },
  setMuted(state, muted) {
    state.muted = muted;
  },
  setSupervision(state, supervision: object) {
    propset(state.supervision, supervision);
  },
  reset(state) {
    propset(state, initialState());
  },
  setISO366Data(
    state,
    data: Record<string, Record<string, string | string[] | number | number[]>>
  ) {
    Vue.prototype.$set(state, "iso366Data", data);
  },
};

const actions: ActionTree<VoiceState, RootState> = {
  updateMicrophoneAccess({ commit }, microphone) {
    commit("setMicrophoneAccess", microphone);
  },
  mute({ commit }, status) {
    const isMuted = Vue.prototype.$twilio.mute(status);
    const condition = status ? isMuted : !isMuted;

    if (condition) {
      commit("setMuted", status);
    }
  },
  updateSupervision({ commit }, supervision) {
    if (
      Object.entries(supervision).length === 0 &&
      supervision.constructor === Object
    ) {
      // TODO: Throw error;
      return;
    }

    commit("setSupervision", supervision);
  },
  reset({ commit }) {
    commit("reset");
  },
  updateISO366Data(
    { commit },
    payload: Record<
      string,
      Record<string, string | string[] | number | number[]>
    >
  ) {
    if (payload) {
      commit("setISO366Data", payload);
    }
  },
};

export const voiceStore: Module<VoiceState, RootState> = {
  namespaced,
  state: voiceState,
  getters,
  actions,
  mutations,
};
