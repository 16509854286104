import isPlainObject from "lodash/isPlainObject";
import { EventBus } from "@/eventBus";
import events from "@/config/events";
import store from "@/store";
import { voice } from "@/config";
import { call } from "@/services/call";
import Vue from "vue";
import conf from "@/config";
import { session } from "@/services/session";
import { agent } from "@/services/agent";
import * as Twilio from "twilio-client/es5/twilio";

export default () => {
  EventBus.$on(events.twilio.deviceSetup, () => {
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(() => {
        store.dispatch("voice/updateMicrophoneAccess", true);
        Vue.prototype.$twilio.setup();
      })
      .catch(async (err: any) => {
        console.error("access to microphone was denied", err);

        try {
          const dnd = conf.agent.statuses.dnd;
          await agent.updateStatus(dnd);
          await store.dispatch("session/updateStatus", dnd);
          return;
        } catch (error) {
          console.error(
            "failed to put user into dnd mode after no answer to an incoming call",
            error
          );
          return;
        }
      });
  });

  EventBus.$on(events.twilio.deviceReset, () => {
    try {
      Vue.prototype.$twilio.destroy();
    } catch (error) {
      console.error(
        "error occurred while attempting to destroy twilio device",
        error
      );
    }
  });

  EventBus.$on(events.twilio.ready, async () => {
    const callStatus = store.getters["call/status"];
    if (!callStatus || callStatus === voice.status.connecting) {
      EventBus.$emit(events.call.reset);
      await store.dispatch("call/updateStatus", voice.status.ready);
    }
  });

  EventBus.$on(events.twilio.incoming, async (conn: Twilio.Connection) => {
    const supervising = store.getters["voice/supervisionStatus"];

    if (supervising) {
      EventBus.$emit(events.call.supervise);
      return;
    }

    try {
      await store.dispatch("call/updateStatus", voice.status.incoming);
      await session.updateStatus(conf.agent.statuses.incomingCall);
      await call.getCallDetails(conn);

      const callStatus = store.getters["call/status"];
      const sessionStatus = store.getters["session/status"];

      if (call.incomingTimer) {
        console.warn(
          "[TWILIO CALL ACTIVITY]handling incoming call...clearing existing call ringing timer (in events.twilio.incoming hook)",
          call.incomingTimer,
          new Date().toISOString()
        );
        // @ts-ignore
        clearTimeout(call.incomingTimer as NodeJS.Timeout);
        call.incomingTimer = null;
      }

      console.warn(
        "[TWILIO CALL ACTIVITY] handling incoming call",
        new Date().toISOString(),
        callStatus,
        sessionStatus,
        conn.parameters.CallSid,
        conn.status(),
        conn.parameters,
        conn.callerInfo,
        call.incomingTimer
      );

      if (
        [
          Twilio.Connection.State.Ringing,
          Twilio.Connection.State.Pending,
        ].includes(conn.status()) &&
        conf.agent.statuses.incomingCall === sessionStatus &&
        voice.status.incoming === callStatus
      ) {
        // @ts-ignore
        call.incomingTimer = setTimeout(async () => {
          try {
            const callStatusIncomingTimer = store.getters["call/status"];
            const sessionStatusIncomingTimer = store.getters["session/status"];
            const activeConnection: Twilio.Connection = Vue.prototype.$twilio.getConnection() as Twilio.Connection;
            console.warn(
              "[TWILIO CALL ACTIVITY] handling incoming call... about to process call as unanswered call",
              new Date().toISOString(),
              callStatusIncomingTimer,
              sessionStatusIncomingTimer,
              call.incomingTimer,
              activeConnection && activeConnection.status(),
              activeConnection && activeConnection.parameters.CallSid,
              activeConnection && activeConnection.parameters
            );

            const predicate =
              [
                Twilio.Connection.State.Ringing,
                Twilio.Connection.State.Pending,
              ].includes(conn.status()) &&
              conf.agent.statuses.incomingCall === sessionStatus &&
              voice.status.incoming === callStatus;

            if (predicate) {
              await call.didNotAnswer();
            } else {
              console.warn(
                "[TWILIO CALL ACTIVITY] handling incoming call... will NOT process call as unanswered call due to predicate failure",
                new Date().toISOString()
              );
            }
          } catch (error) {
            const err =
              error instanceof Error ? error.message : new Error(error).message;

            EventBus.$emit(
              "error-notification",
              `Error: failed processing of unanswered call. Please contact your system administrator. Details: ${err}`
            );

            console.error(
              "error processing agent not answering call...",
              error
            );
          } finally {
            if (call.incomingTimer) {
              console.warn(
                "[TWILIO CALL ACTIVITY] handling incoming call...clearing existing call ringing timer (in events.twilio.incoming hook => call.incomingTimer handler)",
                call.incomingTimer,
                new Date().toISOString()
              );
              // @ts-ignore
              clearTimeout(call.incomingTimer as NodeJS.Timeout);
              call.incomingTimer = null;
            }
          }
        }, 15 * 1000); // 15 seconds

        console.warn(
          "[TWILIO CALL ACTIVITY] handling incoming call...new call ringing timer set",
          call.incomingTimer,
          new Date().toISOString()
        );
      } else {
        console.warn(
          "[TWILIO CALL ACTIVITY] handling incoming call... some weird sh*t is happening cuz this log shouldn't be display. call has already transitioned to a state beyond 'incoming/ringing'",
          new Date().toISOString(),
          callStatus,
          sessionStatus,
          conn.status(),
          conn.parameters.CallSid,
          call.incomingTimer
        );
      }
    } catch (error) {
      const err =
        error instanceof Error ? error.message : new Error(error).message;

      EventBus.$emit(
        "error-notification",
        `Error: incoming call processing error.Please contact your system administrator. Details: ${err}`
      );

      console.error("incoming call processing error", err);
    }
  });

  EventBus.$on(events.twilio.cancel, async () => {
    try {
      const callStatus = store.getters["call/status"];
      // When a caller hangs up before a staffer can answer the call
      if (callStatus === voice.status.incoming) {
        await call.cancel();
        EventBus.$emit(events.call.reset);
        return;
      }

      // EventBus.$emit(
      //   "error-notification",
      //   `Error: unexpected call cancellation. Please contact your system administrator.`
      // );
    } catch (error) {
      const err =
        error instanceof Error ? error.message : new Error(error).message;

      EventBus.$emit(
        "error-notification",
        `Error: unexpected error occurred on cancelled call processing. Please contact your system administrator. Details: ${err}`
      );

      console.error("cancelled call processing error", err);
    }
  });

  EventBus.$on(events.twilio.connect, async (conn: Twilio.Connection) => {
    try {
      // TODO: Handle supervision connection
      const supervising = store.getters["voice/supervisionStatus"];
      if (call.incomingTimer) {
        console.warn(
          "[TWILIO CALL ACTIVITY] processing connected call...clearing existing call ringing timer (in events.twilio.connect hook)",
          call.incomingTimer,
          new Date().toISOString()
        );
        // @ts-ignore
        clearTimeout(call.incomingTimer as NodeJS.Timeout);
        call.incomingTimer = null;
      }

      if (supervising) {
        Vue.prototype.$twilio.mute();
        store.dispatch("voice/mute", true);
        await store.dispatch("call/updateStatus", voice.status.supervising);
        const surveilling = store.getters["voice/surveillanceStatus"];
        const status = surveilling
          ? conf.agent.statuses.surveillingCall
          : conf.agent.statuses.supervisingCall;
        await session.updateStatus(status);
      } else {
        const callStatus = store.getters["call/status"];
        const sessionStatus = store.getters["session/status"];
        console.warn(
          "[TWILIO CALL ACTIVITY] processing connected call",
          new Date().toISOString(),
          call.incomingTimer,
          callStatus,
          sessionStatus,
          conn.status(),
          conn.parameters.CallSid,
          conn.parameters
        );

        store.dispatch("voice/mute", false);
        await store.dispatch("call/updateStatus", voice.status.ongoing);
        await session.updateStatus(conf.agent.statuses.inCall);
      }

      store.dispatch("call/setStartDate");
      store.dispatch("call/startTimer");

      EventBus.$emit(events.call.start, conn);
    } catch (error) {
      const err =
        error instanceof Error ? error.message : new Error(error).message;

      EventBus.$emit(
        "error-notification",
        `Error: unexpected error occurred on connected call processing. Please contact your system administrator. Details: ${err},`
      );
      console.error("call connection attempt error", error);
    }
  });

  EventBus.$on(events.twilio.disconnect, async (conn: Twilio.Connection) => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    const isTransfer = Boolean(store.getters["call/transferredTo"]);
    await store.dispatch("call/stopTimer");

    console.warn(
      "[TWILIO CALL ACTIVITY] disconnected/ended call",
      new Date().toISOString(),
      call.incomingTimer,
      store.getters["call/id"],
      callStatus,
      sessionStatus,
      // @ts-ignore
      window.__callEndEventFlags,
      conn.status(),
      conn.parameters.CallSid,
      conn.parameters
    );

    if ([voice.status.incoming].includes(callStatus)) {
      await store.dispatch("call/updateStatus", voice.status.ready);
      EventBus.$emit(events.call.reset);
      return;
    }

    if ([voice.status.supervising].includes(callStatus)) {
      await store.dispatch("call/updateStatus", voice.status.ready);
      EventBus.$emit(events.call.reset);
      return;
    }

    if (callStatus === voice.status.ongoing && isTransfer) {
      await store.dispatch("call/update", {
        transferredTo: null,
        status: voice.status.ready,
      });
      EventBus.$emit(events.call.reset);
      return;
    }

    const callId = store.getters["call/id"];

    try {
      if (isNaN(Number(callId))) {
        console.warn(
          "[TWILIO CALL ACTIVITY] call end flags",
          new Date().toISOString(),
          // @ts-ignore
          window.__callEndEventFlags
        );

        // @ts-ignore
        const callEndEventFlags = window.__callEndEventFlags || {};
        const shouldHangupOnCaller =
          isPlainObject(callEndEventFlags) && callEndEventFlags.id === callId
            ? callEndEventFlags.hangupOnCaller
            : true;

        const shouldBlockCaller =
          isPlainObject(callEndEventFlags) && callEndEventFlags.id === callId
            ? callEndEventFlags.blockCaller
            : false;

        const data = {
          references: [],
          clientReferences: [],
          centers: [],
          demographics: [],
          assessCallOnLeave: true,
          hangupOnCaller: shouldHangupOnCaller,
          blockCaller: shouldBlockCaller,
        };

        const id = await call.leave(data);

        if (isNaN(Number(id))) {
          await store.dispatch("call/updateStatus", voice.status.ready);
          EventBus.$emit(events.call.reset);
        } else {
          await store.dispatch("call/update", {
            id,
            status: voice.status.ended,
          });
          await session.updateStatus(conf.agent.statuses.assessingCall);
        }
      } else {
        await store.dispatch("call/updateStatus", voice.status.ended);
        await session.updateStatus(conf.agent.statuses.assessingCall);
      }

      // @ts-ignore
      delete window.__callEndEventFlags;
    } catch (error) {
      console.error(
        "failed to process twilio disconnect event",
        callId,
        callStatus,
        error
      );
      EventBus.$emit(
        "error-notification",
        `Error: Call termination failure. Please contact your system administrator.,`
      );
    }
  });
};
