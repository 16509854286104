import isPlainObject from "lodash/isPlainObject";
import { ClientReference, Center } from "./../types/domain";
import { EventBus } from "@/eventBus";
import { events, voice } from "@/config";
import { call } from "@/services/call";
import { session } from "@/services/session";
import store from "@/store";
import conf from "@/config";
import Vue from "vue";
import { EndCallOptions } from "@/types/events";
import { Reference } from "../types/domain";
import * as Twilio from "twilio-client/es5/twilio";

export default () => {
  EventBus.$on(events.call.reset, async () => {
    const status = conf.agent.statuses.available;
    try {
      console.debug(
        "[TWILIO ACTIVITY] call state reset triggered...",
        new Date().toISOString()
      );
      await session.updateStatus(status);
      await store.dispatch("call/stopTimer");
      await store.dispatch("call/reset");
      await store.dispatch("voice/updateSupervision", {
        status: false,
        surveil: false,
        agent: null,
      });
    } catch (err) {
      console.error(
        "an error occurred while running call reset event handler",
        err
      );
    }
  });

  EventBus.$on(events.call.answer, async () => {
    try {
      store.dispatch("call/updateStatus", voice.status.answering);
      Vue.prototype.$twilio.takeCall();
      const conn: Twilio.Connection = Vue.prototype.$twilio.getConnection() as Twilio.Connection;

      const id = conn.parameters.CallSid;
      const callStatus = store.getters["call/status"];
      const sessionStatus = store.getters["session/status"];
      console.warn(
        "[TWILIO CALL ACTIVITY] connection info for answered call...",
        new Date().toISOString(),
        callStatus,
        sessionStatus,
        id,
        conn.status(),
        conn.parameters,
        conn.callerInfo
      );

      await call.updateCallDetails({ id });
    } catch (error) {
      const err =
        error instanceof Error ? error.message : new Error(error).message;

      EventBus.$emit(
        "error-notification",
        `Error: unexpected error occurred on answered call processing. Please contact your system administrator. Details: ${err}`
      );

      console.error(
        "answered call processing error",
        new Date().toISOString(),
        err
      );
    }
  });

  EventBus.$on(events.call.supervise, async () => {
    const supervision = store.getters["voice/supervision"];
    const surveilling = supervision.surveil;

    try {
      const status =
        conf.agent.statuses[
          surveilling ? "surveillingCall" : "supervisingCall"
        ];

      await call.getCallDetailsStafferId(supervision.agent);

      store.dispatch("call/updateStatus", voice.status.supervising);
      await session.updateStatus(status);

      Vue.prototype.$twilio.takeCall({ audio: false });

      EventBus.$emit(events.call.start);
    } catch (err) {
      // TODO: throw err and reset;
      throw err;
    }
  });

  EventBus.$on(events.call.start, (conn: Twilio.Connection) => {
    if (call.incomingTimer) {
      console.warn(
        "[TWILIO CALL ACTIVITY] clearing existing call ringing timer (in events.call.start hook)",
        call.incomingTimer,
        new Date().toISOString()
      );
      // @ts-ignore
      clearTimeout(call.incomingTimer as NodeJS.Timeout);
      call.incomingTimer = null;
    }

    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];

    console.warn(
      "[TWILIO CALL ACTIVITY] Starting call (in events.call.start hook)...",
      new Date().toISOString(),
      call.incomingTimer,
      callStatus,
      sessionStatus,
      conn.status(),
      conn.parameters,
      conn.callerInfo
    );
  });

  EventBus.$on(events.call.wrappingUp, async (opts: object) => {
    const sessionStatus = conf.agent.statuses.wrappingUpCall;
    await session.updateStatus(sessionStatus);
  });

  EventBus.$on(events.call.handingOff, async () => {
    store.dispatch("call/updateStatus", voice.status.handingOff);
  });

  EventBus.$on(events.call.end, async (opts: EndCallOptions) => {
    try {
      // @ts-ignore
      delete window.WrapUp;
      const {
        id = "",
        hangupOnCaller = true,
        blockCaller = false,
        assessCallOnLeave = true,
      } = isPlainObject(opts) ? opts : {};
      // @ts-ignore
      window.__callEndEventFlags = {
        id: id || store.getters["call/id"],
        hangupOnCaller,
        blockCaller,
        assessCallOnLeave,
      };

      console.warn(
        "[TWILIO CALL ACTIVITY] ending call...",
        new Date().toISOString(),
        // @ts-ignore
        window.__callEndEventFlags
      );

      await Vue.prototype.$twilio.hangup();
    } catch (error) {
      const err =
        error instanceof Error ? error.message : new Error(error).message;
      EventBus.$emit(
        "error-notification",
        `Error: Cannot end call. Please contact your system administrator. Details: ${err}`
      );

      console.error(
        "[TWILIO CALL ACTIVITY] call end hook error",
        new Date().toISOString(),
        err
      );
    }
  });

  EventBus.$on(events.reference.verify, (reference: Reference) => {
    store.dispatch("call/addReference", reference);
  });

  EventBus.$on(events.clientReference.verify, (reference: ClientReference) => {
    store.dispatch("call/addClientReference", reference);
  });

  EventBus.$on(events.center.verify, (center: Center) => {
    store.dispatch("call/addCenter", center);
  });
};
