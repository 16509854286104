




































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { EventBus } from "@/eventBus";
import conf, { events, voice as voiceConfig } from "@/config";
import {
  Client,
  ClientReference,
  ClientReferenceApiResponse,
} from "@/types/domain";
import { client as clientService } from "@/services/client";
import axios, { AxiosResponse, AxiosError } from "axios";
import isEmpty from "lodash/isEmpty";
import capitalize from "lodash/capitalize";
const FlexSearch = require("flexsearch");

@Component
export default class ClientReferences extends Vue {
  private categories: string[] = [];
  private types: string[] = [];
  private category: string = "";
  private type: string = "";
  private search: string = "";
  private references: ClientReference[] = [];
  private queryIndex!: any;
  private queryResults: ClientReference[] = [];
  private selectedReference: ClientReference | null = null;
  get totalReferences() {
    return Array.isArray(this.references) ? this.references.length : 0;
  }
  get clientId() {
    const client: Client = this.$store.getters["call/client"];
    return client && client.id ? client.id : null;
  }
  get query() {
    return { category: this.category, type: this.type, search: this.search };
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get resourceslabel() {
    switch (this.tenant) {
      case "shl": {
        return "reference";
      }
      default: {
        return "resource";
      }
    }
  }

  formatlabel(label: string, casing: string) {
    switch (casing) {
      case "lowercase": {
        return label.toLowerCase();
      }
      case "uppercase": {
        return label.toUpperCase();
      }
      case "capitalize": {
        return capitalize(label);
      }
      default: {
        return label;
      }
    }
  }

  transformlabel(label: string, transformation: string) {
    switch (transformation) {
      case "optional-pluralize": {
        return `${label}(s)`;
      }
      case "pluralize": {
        return `${label}s`;
      }
      default: {
        return label;
      }
    }
  }

  @Watch("totalReferences", { immediate: true })
  private onReferencesChange(newVal: number, oldVal: number) {
    if (!isNaN(newVal) && newVal !== oldVal && newVal > 0) {
      this.queryIndex && this.queryIndex.remove(this.references);
      this.queryIndex && this.queryIndex.add(this.references);
      this.find();
    } else {
      this.queryIndex && this.queryIndex.remove();
      this.find();
    }
  }
  @Watch("query", { deep: true })
  private onQueryUpdate() {
    this.find();
  }
  @Watch("selectedReference", { deep: true })
  private onSelectedReferenceUpdate() {
    if (this.selectedReference) {
      EventBus.$emit(events.clientReference.preview, this.selectedReference);
    }
  }
  private mounted() {
    this.queryIndex = FlexSearch.create({
      doc: {
        id: "ID",
        field: ["Issue", "Type", "Name"],
      },
    });
    this.useSubscriptions();
  }
  private useSubscriptions() {
    EventBus.$on(events.call.start, () => {
      this.reset();
      this.getReferences();
    });
    EventBus.$on(events.call.reset, this.reset);
    EventBus.$on(events.interactions.clearPreviewPanel, () => {
      this.selectedReference = null;
    });
  }
  get callStatus() {
    return this.$store.getters["call/status"];
  }
  get sessionStatus() {
    return this.$store.getters["session/status"];
  }
  get isHandlingCall() {
    return (
      [
        voiceConfig.status.ongoing,
        voiceConfig.status.wrappingUp,
        voiceConfig.status.handingOff,
        voiceConfig.status.supervising,
        voiceConfig.status.ended,
      ].includes(this.callStatus) &&
      [
        conf.agent.statuses.inCall,
        conf.agent.statuses.wrappingUpCall,
        conf.agent.statuses.supervisingCall,
        conf.agent.statuses.surveillingCall,
        conf.agent.statuses.assessingCall,
      ].includes(this.sessionStatus)
    );
  }

  private getReferences() {
    clientService
      .getReferences(String(this.clientId))
      .then((data: ClientReferenceApiResponse) => {
        const responsePayload = data;
        this.references = responsePayload.references;
        this.types = responsePayload.types;
        this.categories = responsePayload.categories;
      })
      .catch((error: AxiosError) => {
        console.error("error fetching client references", error);
        this.references = [];
        this.types = [];
        this.categories = [];
        this.queryResults = [];
      });
  }

  private find() {
    const filters = Object.assign(
      this.category.trim() ? { Issue: this.category.trim() } : {},
      this.type.trim() ? { Type: this.type.trim() } : {}
    );
    if (this.search.trim()) {
      this.queryIndex.search(this.search, {
        field: ["Name"],
        suggest: true,
        where: filters,
        callback: (results: ClientReference[]) => {
          this.queryResults = results;
        },
      });
    } else if (!isEmpty(filters)) {
      this.queryResults = this.queryIndex.where(filters);
    } else {
      this.queryResults = this.references;
    }
  }
  private reset() {
    this.category = "";
    this.type = "";
    this.search = "";
    this.references = [];
    this.types = [];
    this.categories = [];
    this.queryResults = [];
  }
  beforeDestroy() {
    this.queryIndex.destroy();
  }
}
