import isPlainObject from "lodash/isPlainObject";
import { AgentService } from "../types/services";
import axios, { AxiosResponse, AxiosError } from "axios";
import { app } from "@/config";
import store from "@/store";
import { Queue } from "@/types/domain";

export const agent: AgentService = {
  async updateStatus(status) {
    const data = { status };
    return axios
      .put(`${app.apiUrl}/staffers`, data)
      .then((res: AxiosResponse) => {
        return Promise.all([
          Promise.resolve(res.data),
          store.dispatch("session/updateStatus", res.data.status),
          store.dispatch(
            "session/updateStatusTimestamp",
            Number(res.data.statusChangedAt)
          ),
        ]);
      })
      .then(([responseData]) => responseData)
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async updateQueueNoRotationStatuses(
    payload: Record<number | string, boolean>
  ) {
    return axios
      .put(`${app.apiUrl}/staffers`, {
        queueRotations: payload,
        status: store.getters["session/status"] || "AVAILABLE",
      })
      .then((res) => {
        return Promise.all([
          store.dispatch(
            "session/updateQueueRotations",
            isPlainObject(res.data.queueRotations)
              ? res.data.queueRotations
              : {}
          ),
          store.dispatch("session/updateStatus", res.data.status),
          store.dispatch(
            "session/updateStatusTimestamp",
            Number(res.data.statusChangedAt)
          ),
        ]);
      })
      .then(() => payload)
      .catch((err: AxiosError) => {
        throw err;
      });
  },
  async getAssignedQueues(_: Record<string, unknown>): Promise<Queue[]> {
    return new Promise((resolve) => {
      // const refQueues = Array.isArray(staff.callQueues)
      //   ? staff.callQueues.map((q: Record<string, unknown>) => String(q.id))
      //   : [];

      try {
        const queues = JSON.parse(
          sessionStorage.getItem("thl-assigned-queues") || JSON.stringify([])
        ) as Queue[];

        if (Array.isArray(queues)) {
          resolve(queues as Queue[]);
          return;
        }

        // if (queues.every((q: Queue) => refQueues.includes(q.id))) {
        //   resolve(queues as Queue[]);
        // } else {
        //   axios
        //     .get(`${app.apiUrl}/queue/self`, {
        //       headers: {
        //         Authorization: "Bearer " + store.getters["auth/authToken"],
        //       },
        //     })
        //     .then((res: AxiosResponse<Queue[]>) => {
        //       queues = Array.isArray(res.data) ? res.data : ([] as Queue[]);
        //       sessionStorage.setItem(
        //         "thl-assigned-queues",
        //         JSON.stringify(queues)
        //       );
        //       resolve(queues);
        //     })
        //     .catch((err: AxiosError) => {
        //       console.error(
        //         "failure to fetch thl assigned queues (on sse update)",
        //         err.response
        //       );
        //       sessionStorage.setItem("thl-assigned-queues", JSON.stringify([]));
        //       resolve([] as Queue[]);
        //     });
        // }
      } catch (error) {
        console.error(
          "unable to fetch assigned queues from session storage",
          error
        );
        resolve([] as Queue[]);
      }
    });
  },
};
