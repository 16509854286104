




import { Component, Vue, Watch } from "vue-property-decorator";
import MainLayout from "../components/Main.vue";
import { session } from "../services/session";
import { EventBus } from "../eventBus";
import conf, { events, voice as voiceConfig } from "@/config";
import SessionAssessment from "@/components/voice/modals/SessionAssessment/Main.vue";

@Component({
  components: {
    MainLayout,
    SessionAssessment
  }
})
export default class Home extends Vue {
  private agentStatuses = conf.agent.statuses;

  get isAuthenticated() {
    return this.$store.getters["auth/loggedIn"];
  }
}
