export interface Agent {
  firstName: string;
  lastName: string;
  displayName: string;
  id: number | string;
  key?: number | string | undefined;
  role: string;
  status: string;
  statusChangedAt: number;
}

export interface Client {
  id: number;
  incomingNumber: string;
  language: number;
  name: string;
  outgoingNumber: string;
  ivr: ClientIVR[];
  demographics: ClientDemographic[];
  requiresCensus: boolean;
}

export interface Queue {
  id: string;
  name: string;
  abbreviation: string;
  orderPriority: number;
  soundUrl: string;
  clients: number[];
  staff: number[];
}

export interface ClientDemographic {
  id: number;
  name: string;
  description: string;
  isActive: boolean;
  isBold: boolean;
  isMultiselect: boolean;
  isRequired: boolean;
}

export interface ClientIVR {
  id: number;
  name: string;
  phoneNumber: string;
}

export interface Voicemail {
  id: number;
  name: string;
  isActive: boolean;
  blockCaller?: boolean;
}

export interface Resource {
  type: string;
  content: string;
  title?: string;
  body?: string;
}

export enum ReferenceTypes {
  NODE = "NODE",
  LEAF = "LEAF",
}

export interface Reference {
  id: number;
  title: string;
  body: string;
  ordinalPosition: number;
  type: ReferenceTypes;
  path?: string;
}

export interface ClientReferenceApiResponse {
  references: ClientReference[];
  categories: string[];
  types: string[];
}

export interface ClientReference {
  ID: string;
  Issue: string;
  Type: string;
  Name: string;
  Location: string;
  Phone: string;
  HoursOfOperation: string;
  Website: string;
  Requirements: string;
  Description: string;
  MandatoryReportingRequirement: string;
  path?: string;
}

export interface OnCallAdvocate {
  id: number;
  name: string;
  shift: string;
  phone: string;
  notes: string;
  priority: number;
  start: string;
  end: string;
  shiftTimestamps: {
    start: number;
    end: number;
  };
}

export interface Center {
  id: number;
  name: string;
  state: string;
  zipCode: string;
  businessPhone: string;
  hotlinePhone: string;
  tty: string;
  hotline: string;
  url: string;
  latitude: string;
  distance: number;
  options: CenterOption[];
  services: CenterService[];
}

export interface CensusFormQuestion {
  id: number;
  label: string;
  description: string;
  options: CensusFormQuestionOption[];
  required: boolean;
  multiple: boolean;
  input: boolean;
}

export interface CensusFormQuestionOption {
  id: number;
  label: string;
  position: number;
}
export interface CenterOption {
  name: string;
  value: string;
  language: string;
}

export interface CenterService {
  name: string;
  value: string;
  language: string;
}

export enum CenterSearchType {
  STATE = "STATE",
  ZIP = "ZIP",
}

export interface Session {
  id: number | string | null;
  queueRotations: Record<number, boolean>;
  callQueues?: Array<{
    id: number;
    name: string;
    orderPriority: number;
    abbreviation: string;
  }>;
  role: string | null;
  displayName: string | null;
  firstName: string | null;
  lastName: string | null;
  capabilityToken: string | null;
  status: string | null;
  statusChangedAt: number;
  debug?: Record<string, unknown> | null;
}

export interface Call {
  id: string | number | null;
  languagePreference: string | null;
  callStartDate: string | Date | null;
  queueStartDate: string | Date | null;
  callEndDate: string | Date | null;
  callLengthSecs: number;
  hangupOnCaller: boolean;
  // census?: { [key: string]: number | string } | null;
}

export interface Caller {
  id: string;
  client: Client;
  startTime: string;
}

export interface AssessmentNest {
  sessionAssessmentQuestion: AssessmentQuestion;
}

export interface AssessmentQuestion {
  id: number;
  questionText: string;
  ordinalPosition: number;
  startDate: number;
  endDate: number | null;
  active: boolean;
  sessionAssessmentAnswerList: AssessmentAnswer[];
}

export interface AssessmentAnswer {
  id: number;
  answerText: string;
  ordinalPosition: number;
  terminationAnswer: boolean;
  freeFormAnswer: boolean;
}

export interface AssessmentChoice {
  id: number;
  freeform: string | null;
}

export interface AssessmentDetails {
  id: number;
  client: Client;
  name: null;
  hotlineType: string;
  active: boolean;
}
export interface AssessmentAPIResponse {
  sessionAssessment: AssessmentDetails;
  sessionAssessmentQuestionList: AssessmentNest[];
}

export interface SessionAssessment {
  id: number;
  active: boolean;
}
export interface SessionAssessmentClient {
  id: number;
  name: string;
}

export interface SessionAssessmentQuestion {
  id: number;
  text: string;
  position: number;
  options: number[];
}

export interface SessionAssessmentQuestionOption {
  id: number;
  text: string;
  position: number;
  freeform: boolean;
  termination: boolean;
}

export interface SessionAssessmentResponse {
  question: number;
  position: number;
  option: number;
  freeform: boolean;
  termination: boolean;
  text: string;
}

export interface SessionAssessmentQuestionnaire {
  client: SessionAssessmentClient;
  assessment: SessionAssessment;
  completed: boolean;
  questions: SessionAssessmentQuestion[];
  options: SessionAssessmentQuestionOption[];
  responses: SessionAssessmentResponse[];
}

export interface DemographicQuestion {
  id: number;
  name: string;
  description: string;
  demographicChoices: DemographicQuestionOption[];
  isRequired: boolean;
  isMultiselect: boolean;
  isInput?: boolean;
  defaultChoices: number[];
  isActive: boolean;
}

export interface DemographicQuestionOption {
  id: number;
  choice: string;
  ordinalPosition: number;
  isFreeform?: boolean | null;
}

export interface DemographicFreeformAnswer {
  optionId: number;
  answer: string;
}
