




























































import Vue from "vue";
import conf, { voice, events } from "@/config";
import { Component, Prop } from "vue-property-decorator";
import { agent } from "@/services/agent";
import { queue } from "@/services/queue";
import { call } from "@/services/call";
import { session } from "@/services/session";
import PostCallAssessmentModal from "./modals/SessionAssessment/Main.vue";
import IncomingCall from "./call/Incoming.vue";
import { EventBus } from "../../eventBus";
import OngoingCall from "./call/Ongoing.vue";
import WrapUp from "./modals/WrapUp.vue";
import WarmHandOff from "./modals/HandOff.vue";
import { Caller, Client } from "../../types/domain";
import startCase from "lodash/startCase";
import cloneDeep from "lodash/cloneDeep";
import isPlainObject from "lodash/isPlainObject";
import startcase from "lodash/startCase";
import truncate from "lodash/truncate";
import getPropValue from "lodash/get";

import moment from "moment";

const statuses = conf.agent.statuses;

@Component({
  components: {
    IncomingCall,
    OngoingCall,
    WrapUp,
    WarmHandOff,
    PostCallAssessmentModal,
  },
})
export default class VoiceHeader extends Vue {
  private statuses = statuses;
  private voiceStatus = voice.status;
  private showWrapUpModal: boolean = false;
  private showWarmHandOffModal: boolean = false;
  private warmHandOffPhone: number | string | null = null;
  private showAssessmentModal: boolean = false;
  private s3Baseurl: string = process.env.VUE_APP_S3_BASEURL || "";
  private onCallIconImgSrc: string = `${this.s3Baseurl}/icons/on_call.svg`;

  get queues() {
    const queues = this.$store.getters["queue/all"];
    return Array.isArray(queues) ? cloneDeep(queues) : [];
  }

  get role() {
    return this.$store.getters["session/role"];
  }

  get dnd() {
    return this.$store.getters["session/status"] === statuses.dnd;
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get callHandOffState() {
    return this.$store.getters["call/callHandOffState"];
  }

  get showOngoingCallMenu() {
    return (
      this.callStatus === this.voiceStatus.ongoing ||
      this.callStatus === this.voiceStatus.answering ||
      this.callStatus === this.voiceStatus.supervising ||
      this.callStatus === this.voiceStatus.wrappingUp ||
      this.callStatus === this.voiceStatus.handingOff
    );
  }

  get callActionsBgStyling() {
    return this.dnd ? "bg-orange-dark" : "bg-primary-dark";
  }

  get incomingCallStatus() {
    return (
      this.sessionStatus === statuses.incomingCall &&
      this.callStatus === this.voiceStatus.incoming
    );
  }

  get pendingSessionAssessment() {
    const callStatus = this.callStatus;
    const sessionStatus = this.sessionStatus;
    return (
      this.callStatus === voice.status.ended &&
      this.sessionStatus === conf.agent.statuses.assessingCall
    );
  }

  get isSupervising() {
    return (
      this.sessionStatus === this.statuses.supervisingCall ||
      this.sessionStatus === this.statuses.surveillingCall ||
      this.callStatus === voice.status.supervising
    );
  }

  get isMuted() {
    return this.$store.getters["voice/muted"];
  }

  get callLength() {
    return this.$store.getters["call/prop"]("callLengthSecs");
  }

  get showMicrophone() {
    return !this.isSupervising && !this.isMuted;
  }

  get client(): Client {
    return this.$store.getters["call/client"];
  }

  get clientName() {
    return isPlainObject(this.client) &&
      typeof this.client.name === "string" &&
      this.client.name.trim().length
      ? truncate(startcase(this.client.name.trim()), {
          length: 50,
          separator: /,? +/,
          omission: "...",
        })
      : "";
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get sessionassessmentlabel() {
    switch (this.tenant) {
      case "shl": {
        return "Call Wrap Up";
      }
      default: {
        return "Session Assessment";
      }
    }
  }

  private formatCallTimer() {
    return moment()
      .startOf("day")
      .seconds(this.callLength)
      .format("H:mm:ss");
  }

  private created() {
    EventBus.$on(events.call.start, () => {
      this.showAssessmentModal = false;
    });

    EventBus.$on(events.call.reset, () => {
      this.showAssessmentModal = false;
    });
    EventBus.$on(events.call.wrappingUp, this.wrapUp);
    EventBus.$on(events.call.handingOff, this.warmHandOff);

    EventBus.$on("open-warm-hand-off-and-set-phone-number", (phone: string) => {
      if (
        !this.callHandOffState &&
        !this.pendingSessionAssessment &&
        typeof phone === "string" &&
        phone.trim() &&
        (this.callStatus === voice.status.ongoing ||
          this.callStatus === voice.status.wrappingUp ||
          this.callStatus === voice.status.handingOff) &&
        (this.sessionStatus === conf.agent.statuses.inCall ||
          this.sessionStatus === conf.agent.statuses.wrappingUpCall)
      ) {
        this.warmHandOffPhone = phone.trim();
        this.showWarmHandOff();
      }
    });
  }

  private async toggleDnd() {
    const status = this.dnd ? statuses.available : statuses.dnd;
    return Promise.resolve()
      .then(() => {
        const connection = Vue.prototype.$twilio.getConnection();

        if (this.incomingCallStatus && connection) {
          return call.didNotAnswer();
        }

        return agent.updateStatus(status);
      })
      .then(() => this.$store.dispatch("session/updateStatus", status))
      .then(() => true)
      .catch((error) => console.error("DND action toggle failed", error));
  }

  private async wrapUp() {
    if (
      this.sessionStatus === statuses.inCall ||
      this.callStatus === voice.status.ongoing ||
      this.callStatus === this.voiceStatus.wrappingUp
    ) {
      try {
        await this.$store.dispatch(
          "call/updateStatus",
          voice.status.wrappingUp
        );
        await session.updateStatus(statuses.wrappingUpCall);
        // await agent.updateStatus(statuses.wrappingUpCall);
        this.showWrapUp();
      } catch (err) {
        console.error("Could not update agent status to wrap up.", err);
      }
    } else {
      console.warn(
        "invalid state to trigger wrap up modal...",
        new Date().toISOString(),
        this.sessionStatus === statuses.inCall,
        this.callStatus === voice.status.ongoing,
        this.callStatus === this.voiceStatus.wrappingUp,
        this.callStatus === this.voiceStatus.handingOff
      );
    }
  }

  private async warmHandOff() {
    if (
      // this.sessionStatus === statuses.inCall ||
      this.callStatus === voice.status.ongoing ||
      this.callStatus === voice.status.wrappingUp ||
      this.callStatus === voice.status.handingOff
    ) {
      try {
        if (this.callStatus !== voice.status.handingOff) {
          await this.$store.dispatch(
            "call/updateStatus",
            voice.status.handingOff
          );
        }

        if (this.sessionStatus !== statuses.wrappingUpCall) {
          await session.updateStatus(statuses.wrappingUpCall);
        }

        this.showWarmHandOff();
      } catch (err) {
        console.error("Could not update agent status to warm hand off.", err);
      }
    } else {
      console.warn(
        "invalid state to trigger warm hand off modal...",
        new Date().toISOString(),
        this.callStatus === voice.status.ongoing,
        this.callStatus === this.voiceStatus.wrappingUp,
        this.callStatus === this.voiceStatus.handingOff
      );
    }
  }

  private showWrapUp() {
    this.showWrapUpModal = true;
  }

  private async closeWrapUpModal() {
    if (
      this.sessionStatus === statuses.wrappingUpCall &&
      this.callStatus !== voice.status.ended
    ) {
      try {
        await this.$store.dispatch("call/updateStatus", voice.status.ongoing);
        await session.updateStatus(statuses.inCall);
        // await agent.updateStatus(statuses.inCall);

        this.showWrapUpModal = false;
      } catch (err) {
        console.error("Could not update agent status to in-call", err);
      }
    } else {
      this.showWrapUpModal = false;
    }
  }

  private showWarmHandOff() {
    this.showWarmHandOffModal = true;
  }

  private async closeWarmHandOffModal() {
    if (
      this.sessionStatus === statuses.wrappingUpCall &&
      this.callStatus !== voice.status.ended &&
      !this.callHandOffState
    ) {
      try {
        await this.$store.dispatch("call/updateStatus", voice.status.ongoing);
        await session.updateStatus(statuses.inCall);
        // await agent.updateStatus(statuses.inCall);

        this.showWarmHandOffModal = false;
      } catch (err) {
        console.error("Could not update agent status to in-call", err);
      }
    } else {
      this.showWarmHandOffModal = false;
    }
  }

  private toggleAssessmentModal() {
    this.showAssessmentModal = !this.showAssessmentModal;
  }
}
