import { MutationTree } from 'vuex';
import { AuthState } from '@/types/store';
import { initialState } from './index';
import { propset } from '@/config/helpers';

export const mutations: MutationTree<AuthState> = {
  setLoggedIn(state, bool: boolean) {
    state.loggedIn = bool;
  },
  setAuthToken(state, token) {
    state.authToken = token;
  },
  setRefreshToken(state, token) {
    state.refreshToken = token;
  },
  setRetryState(state, refresh) {
    state.retry.refresh = refresh;
  },
  setRetryCall(state, call) {
    state.retry.call = call;
  },
  reset(state) {
    const init = initialState();
    propset(state, init);
  }
};
