






























































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Agent } from "@/types/domain";
import conf, {
  session as sessionConfig,
  events,
  voice as voiceConfig,
} from "@/config";
import { call } from "@/services/call";
import { agent as agentService } from "@/services/agent";
import { session as sessionService } from "@/services/session";
import { failure, success } from "@/config/helpers";
import { EventBus } from "@/eventBus";
import isBoolean from "lodash/isBoolean";
import moment from "moment";

import {
  Connection as TwilioConnection,
  Device as TwilioDevice,
} from "twilio-client/es5/twilio";

const INTERVAL_TIMEOUT = 5 * 1000; // 5 seconds (in milliseconds)

@Component
export default class Buddy extends Vue {
  @Prop() private agent!: Agent;
  private surveil: boolean = false;
  private statuses = conf.agent.statuses;
  private roles = conf.agent.roles;
  private noRotation: boolean = false;
  private dndToggleState: boolean = false;
  private s3Baseurl: string = process.env.VUE_APP_S3_BASEURL || "";
  private onCallIconImgSrc: string = `${this.s3Baseurl}/icons/on_call.svg`;
  private now: number = new Date().getTime();
  private interval!: NodeJS.Timeout;

  mounted() {
    this.interval = setInterval(() => {
      this.now = new Date().getTime();
    }, INTERVAL_TIMEOUT);
  }

  @Watch("sessionStatus", { immediate: true })
  async onSessionStatusChange(val: unknown, previousval: unknown) {
    switch (val) {
      case conf.agent.statuses.available: {
        if (this.noRotation) this.noRotation = false;
        if (this.dndToggleState) this.dndToggleState = false;
        return;
      }

      case conf.agent.statuses.dnd: {
        if (!this.dndToggleState) this.dndToggleState = true;
        return;
      }
      // case conf.agent.statuses.noRotation: {
      //   if (!this.noRotation) this.noRotation = true;
      //   return;
      // }
      default: {
        //...noop
        return;
      }
    }
  }

  @Watch("noRotation")
  private async toggleRotationStatus(val: unknown, previousval: unknown) {
    try {
      if (isBoolean(val) && val !== previousval) {
        const shouldSetToNoRotation =
          val && conf.agent.statuses.available === this.sessionStatus;

        const shouldSetToAvailable =
          !val && conf.agent.statuses.available !== this.sessionStatus;

        if (shouldSetToNoRotation) {
          await sessionService.updateStatus(conf.agent.statuses.noRotation);
        } else if (shouldSetToAvailable) {
          await sessionService.updateStatus(conf.agent.statuses.available);
        }
      }
    } catch (error) {
      console.error("no rotation toggle action failed", error);
    }
  }

  @Watch("dndToggleState")
  private async toggleDndStatus(val: unknown, previousval: unknown) {
    try {
      if (isBoolean(val) && val !== previousval) {
        const shouldSetToDnd = val && !this.dnd;
        const shouldSetToAvailable =
          !val && conf.agent.statuses.available !== this.sessionStatus;

        if (shouldSetToDnd || shouldSetToAvailable) {
          await this.toggleDnd();
        }
      }
    } catch (error) {
      console.error("dnd toggle action failed", error);
    }
  }

  get microphoneAccess() {
    return this.$store.getters["voice/microphone"];
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }
  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get thereIsAnIncomingCall() {
    const connectionStatus = Vue.prototype.$twilio.getConnectionStatus();
    return (
      this.sessionStatus === conf.agent.statuses.incomingCall ||
      [
        voiceConfig.status.incoming,
        voiceConfig.status.answering,
        voiceConfig.status.taking,
      ].includes(this.callStatus) ||
      [TwilioConnection.State.Pending, TwilioConnection.State.Ringing].includes(
        connectionStatus
      )
    );
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get showRotationToggle() {
    const isMe = !this.notMe;
    const iamASupervisor = [conf.agent.roles.supervisor].includes(this.role);
    return (
      ["shl", "SHL"].includes(this.tenant) &&
      this.microphoneAccess &&
      isMe &&
      iamASupervisor &&
      [voiceConfig.status.ready, null].includes(this.callStatus) &&
      (this.sessionStatus === this.statuses.available ||
        this.sessionStatus === this.statuses.noRotation)
    );
  }

  get showDndToggle() {
    const isMe = !this.notMe;
    return (
      this.microphoneAccess &&
      isMe &&
      [voiceConfig.status.ready, null].includes(this.callStatus) &&
      [
        this.statuses.available,
        this.statuses.noRotation,
        this.statuses.dnd,
      ].includes(this.sessionStatus)
    );
  }

  get role() {
    return this.$store.getters["session/role"];
  }

  get notMe() {
    return this.agent.id !== this.$store.getters["session/id"];
  }

  get available() {
    return [this.statuses.available, this.statuses.noRotation].includes(
      this.sessionStatus
    );
  }

  get dnd() {
    return this.sessionStatus === this.statuses.dnd;
  }

  get canAcceptTransfer() {
    const userIsAvailable =
      this.agent &&
      [this.statuses.available, this.statuses.noRotation].includes(
        this.agent.status
      );

    const iamInACall =
      this.sessionStatus === this.statuses.inCall ||
      this.callStatus === voiceConfig.status.ongoing;

    const iamASupervisor = [
      conf.agent.roles.vas,
      conf.agent.roles.supervisor,
    ].includes(this.role);

    return (
      this.microphoneAccess &&
      this.notMe &&
      userIsAvailable &&
      iamInACall &&
      iamASupervisor
    );
  }

  get canSupervise() {
    return (
      this.microphoneAccess &&
      this.available &&
      this.agent &&
      this.agent.status === this.statuses.inCall &&
      [conf.agent.roles.vas, conf.agent.roles.supervisor].includes(this.role) &&
      this.notMe
    );
  }

  get isSupervising() {
    return (
      this.microphoneAccess &&
      this.sessionStatus === this.statuses.supervisingCall
    );
  }

  get initials() {
    const first = this.agent.firstName;
    const last = this.agent.lastName;
    return first.substr(0, 1) + last.substr(0, 1);
  }

  get statusIcon() {
    const icons: { [key: string]: string } = sessionConfig.statusIcons;
    return icons[this.agent.status];
  }

  private get statusClass() {
    const statuses = this.statuses;

    switch (this.agent.status) {
      case statuses.dnd:
        return "avatar-orange";
      case statuses.inCall:
        return "avatar-primary";
      default:
        return "avatar-slate";
    }
  }

  private async toggleDnd() {
    if (this.microphoneAccess) {
      const status = this.dnd ? this.statuses.available : this.statuses.dnd;
      return call
        .didNotAnswer()
        .then(() => agentService.updateStatus(status))
        .then(() => true)
        .catch((error) => console.error("DND action toggle failed", error));
    } else {
      EventBus.$emit(
        "error-notification",
        "Microphone access is required to operate on the THL"
      );
    }
  }

  private async supervise() {
    if (this.microphoneAccess && this.canSupervise) {
      const supervision = {
        status: true,
        surveil: this.surveil,
        agent: this.agent.id,
      };

      if (this.surveil && [conf.agent.roles.vas].includes(this.role)) {
        return;
      }

      try {
        const $menuBtn = document.getElementById("menu-trigger-btn");
        if ($menuBtn) {
          $menuBtn.click();
        }
        this.$store.dispatch("voice/updateSupervision", supervision);

        await call.addSupervisor(this.agent.id);
      } catch (err) {
        failure(
          this,
          "Error: Cannot supervise the call. Please contact your system administrator."
        );
        throw err;
      }
    } else if (!this.microphoneAccess) {
      EventBus.$emit(
        "error-notification",
        "Microphone access is required to operate on the THL"
      );
    }
  }

  private async transfer() {
    if (this.microphoneAccess && this.canAcceptTransfer) {
      try {
        const $menuBtn = document.getElementById("menu-trigger-btn");
        if ($menuBtn) {
          $menuBtn.click();
        }

        await call.transfer(this.agent.id);
        success(this, "Successful call transfer.");
      } catch (err) {
        failure(
          this,
          "Error: Unable to transfer the call. Please contact your system administrator."
        );
        throw err;
      }
    } else if (!this.microphoneAccess) {
      EventBus.$emit(
        "error-notification",
        "Microphone access is required to operate on the THL"
      );
    }
  }

  private statusDuration(agent: Agent) {
    if (!agent.statusChangedAt) return "";
    const duration = this.now - agent.statusChangedAt;
    return this.showTimestampAsDuration(duration);
  }

  private showTimestampAsDuration(timestampInput: number): string {
    if (!timestampInput) {
      return "";
    }

    const toMilliseconds = (
      timestamp: number,
      unit: "milliseconds" | "seconds" | "minutes" = "milliseconds"
    ) => {
      let value: number = Number.NaN;
      switch (unit) {
        case "milliseconds":
          value = typeof timestamp === "number" ? timestamp : Number.NaN;
          break;

        case "seconds":
          value = typeof timestamp === "number" ? timestamp * 1000 : Number.NaN;
          break;

        case "minutes":
          value =
            typeof timestamp === "number" ? timestamp * 60 * 1000 : Number.NaN;
          break;
        default:
      }

      return value;
    };

    const resolver = (
      timestamp: number,
      unit: "milliseconds" | "seconds" | "minutes" = "milliseconds"
    ) => {
      if (timestamp < 0) {
        return "0 min";
      }

      const duration =
        unit === "milliseconds" ? timestamp : toMilliseconds(timestamp, unit);

      if (isNaN(duration)) {
        return "0 min";
      }

      const minutes = Math.floor(duration / (60 * 1000));
      const labels = {
        minute: "min",
        hour: "hr",
      };

      const format = `${minutes > 59 ? `H [${labels.hour}]` : ""} m [${
        labels.minute
      }]`;

      return moment
        .utc(moment.duration(duration / 1000, "s").asMilliseconds())
        .format(format);
    };

    return resolver(timestampInput);
  }

  beforeDestroy() {
    this.interval && clearInterval(this.interval);
  }
}
