import cloneDeep from "lodash/cloneDeep";
import { MutationTree } from "vuex";
import { CallState, HandOffStates } from "@/types/store";
import { propset } from "@/config/helpers";
import { initialState } from "./index";
import {
  Client,
  Reference,
  ClientReference,
  Center,
  SessionAssessmentResponse,
  Voicemail,
} from "@/types/domain";
import uniqBy from "lodash/uniqBy";
import Vue from "vue";
import { call } from "@/services/call";

export const mutations: MutationTree<CallState> = {
  setStatus(state, status: string) {
    state.status = status;
  },
  setClient(state, client: Client | null) {
    state.client = client;
  },
  setTransferredTo(state, to) {
    state.transferredTo = to;
  },
  setCurrent(state, current) {
    // Updates all properties of the current call;
    propset(state, current);
  },
  setProp(state, prop) {
    // Updates an individual property of the current call;
    propset(state, prop);
  },
  setHandOffState(state, newState: HandOffStates) {
    if ([0, 1, 2].includes(newState)) {
      state.handOffState = newState;
    }
  },
  reset(state) {
    propset(state, initialState());
    if (call.callTimer) {
      // @ts-ignore
      clearInterval(call.callTimer);
    }
    sessionStorage.removeItem("wrap-up-modal");
    sessionStorage.removeItem("hand-off-modal");
    sessionStorage.removeItem("session-assessment-modal");
    // @ts-ignore
    delete window.WrapUp;
    // @ts-ignore
    delete window.__callEndEventFlags;
  },
  resetCall(state) {
    propset(state, initialState());
    if (call.callTimer) {
      // @ts-ignore
      clearInterval(call.callTimer);
      call.callTimer = null;
    }
    // propset(state, initialState().current);
    // state.references = [];
    // state.clientReferences = [];
    // state.current.callLengthSecs = 0;
  },
  setStartDate(state, date) {
    state.current.callStartDate = date;
  },
  setVoicemails(state, voicemails: Voicemail[]) {
    state.voicemails = cloneDeep(voicemails);
  },
  updateNotepad(state, content: string) {
    state.notepad = typeof content === "string" ? content : "";
  },
  incrementCallLength(state) {
    state.current.callLengthSecs += 1;
  },
  clearCallLength(state) {
    state.current.callLengthSecs = 0;
    if (call.callTimer) {
      // @ts-ignore
      clearInterval(call.callTimer);
      call.callTimer = null;
    }
  },
  addReference(state, reference: Reference) {
    state.references = uniqBy(state.references.concat(reference), "id");
  },
  removeReference(state, reference: Reference) {
    state.references = state.references.filter(
      (r: Reference) => reference.id !== r.id
    );
  },
  addCenter(state, center: Center) {
    const centers: Center[] = Array.isArray(state.centers) ? state.centers : [];
    state.centers = uniqBy(centers.concat(center), "id");
  },
  removeCenter(state, center: Center) {
    const centers: Center[] = Array.isArray(state.centers) ? state.centers : [];
    state.centers = centers.filter((c: Center) => center.id !== c.id);
  },
  addClientReference(state, reference: ClientReference) {
    const clientReferences: ClientReference[] = Array.isArray(
      state.clientReferences
    )
      ? state.clientReferences
      : [];

    state.clientReferences = uniqBy(clientReferences.concat(reference), "ID");
  },
  removeClientReference(state, reference: ClientReference) {
    const clientReferences: ClientReference[] = Array.isArray(
      state.clientReferences
    )
      ? state.clientReferences
      : [];

    state.clientReferences = clientReferences.filter(
      (r: ClientReference) => reference.ID !== r.ID
    );
  },
  setCensusResponses(
    state,
    { question, selected }: { question: number; selected: number[] }
  ) {
    const not = (x: any) => !x;
    const noop = not(
      !isNaN(Number(question)) &&
        Array.isArray(selected) &&
        selected.every((response: any) => !isNaN(Number(response)))
    );

    if (noop) {
      console.warn(
        "human, best check yourself fool! no census responses update for you"
      );
    } else {
      if (!state.census) {
        Vue.prototype.$set(state, "census", {});
      }

      Vue.prototype.$set(state.census, `${question}.selected`, selected);

      const selectedSet = new Set(selected);

      for (const key in state.census) {
        if (!key.startsWith(String(question)) || !key.endsWith("freeform"))
          continue;
        const [, freeformOptionId] = key.split(".");
        if (selectedSet.has(Number(freeformOptionId))) continue;
        Vue.prototype.$delete(state.census, key);
      }
    }
  },
  setCensusFreeFormResponse(
    state,
    {
      question,
      option,
      input,
    }: { question: number; option: number; input: string }
  ) {
    const not = (x: any) => !x;
    const noop = not(
      !isNaN(Number(question)) &&
        !isNaN(Number(option)) &&
        typeof input === "string"
    );

    if (noop) {
      console.warn(
        "human, best check yourself fool! no census free form responses update for you"
      );
    } else {
      if (!state.census) {
        Vue.prototype.$set(state, "census", {});
      }

      const selected = state.census[`${question}.selected`] as number[];
      if (!Array.isArray(selected) || !selected.includes(option)) {
        return;
      }

      Vue.prototype.$set(state.census, `${question}.${option}.freeform`, input);
    }
  },
  setSessionAssessmentState(state, data: SessionAssessmentResponse[]) {
    if (!state.assessment) {
      Vue.prototype.$set(state, "assessment", []);
    }
    Vue.prototype.$set(state, "assessment", data);
  },
  clearSessionAssessmentState(state) {
    if (state.assessment) {
      Vue.prototype.$set(state, "assessment", null);
    }
  },
  clearCensusResponses(state) {
    if (state.census) {
      Vue.prototype.$set(state, "census", null);
      Vue.prototype.$buefy.toast.open({
        type: "is-success",
        position: "is-top-right",
        message: "Census data cleared.",
      });
    }
  },
};
