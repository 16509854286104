











































import { Component, Vue } from "vue-property-decorator";
import conf, { events, voice } from "@/config";
import { EventBus } from "@/eventBus";
import { Client } from "@/types/domain";
import moment from "moment";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import startcase from "lodash/startCase";
import truncate from "lodash/truncate";
import { AxiosError } from "axios";
import { call } from "@/services/call";

@Component
export default class OngoingCall extends Vue {
  private agentStatuses = conf.agent.statuses;
  private moment = moment;
  private s3Baseurl: string = process.env.VUE_APP_S3_BASEURL || "";
  private onCallIconImgSrc: string = `${this.s3Baseurl}/icons/on_call.svg`;
  private endCallIconImgSrc: string = `${this.s3Baseurl}/icons/end_call.svg`;
  private handOffCallIconImgSrc: string = `${this.s3Baseurl}/icons/hand_off_call.svg`;
  private blockCallerIconImgSrc: string = `${this.s3Baseurl}/icons/block_caller.svg`;

  get callLength() {
    return this.$store.getters["call/prop"]("callLengthSecs");
  }

  get isMuted() {
    return this.$store.getters["voice/muted"];
  }

  get isSupervising() {
    return (
      this.sessionStatus === this.agentStatuses.supervisingCall ||
      this.sessionStatus === this.agentStatuses.surveillingCall ||
      this.callStatus === voice.status.supervising
    );
  }

  get showMicrophone() {
    return !this.isSupervising && !this.isMuted;
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get callId() {
    return this.$store.getters["call/id"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get callHandOffState() {
    return this.$store.getters["call/handOffState"];
  }

  get client(): Client {
    return this.$store.getters["call/client"];
  }

  get clientName() {
    return isPlainObject(this.client) &&
      typeof this.client.name === "string" &&
      this.client.name.trim().length
      ? truncate(startcase(this.client.name), {
          length: 50,
          separator: /,? +/,
          omission: "...",
        })
      : "";
  }

  get clientIncominPhoneNumber() {
    return isPlainObject(this.client) &&
      typeof this.client.incomingNumber === "string" &&
      this.client.incomingNumber.trim().length
      ? this.client.incomingNumber.trim()
      : "";
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get showBlockCallerBtn() {
    switch (this.tenant) {
      case "ncvc": {
        return false;
      }

      default: {
        /** This is temporary until we push this feature to production */
        return process.env.VUE_APP_ENV !== "production";
      }
    }
  }

  private mute() {
    this.$store.dispatch("voice/mute", !this.isMuted);
  }

  private wrapUpCall() {
    if (
      this.callStatus === voice.status.ongoing ||
      this.callStatus === voice.status.wrappingUp ||
      this.callStatus === voice.status.handingOff
    ) {
      this.$emit("wrapUpCall");
    } else if (this.isSupervising) {
      Vue.prototype.$twilio.hangup();
      EventBus.$emit(events.call.reset);
    }
  }

  private warmHandOff() {
    if (
      this.callStatus === voice.status.ongoing ||
      this.callStatus === voice.status.handingOff
    ) {
      this.$emit("warmHandOff");
    }
  }

  // private blockCaller() {
  //   const isInValidCallStatus = [
  //     voice.status.ongoing,
  //     voice.status.wrappingUp,
  //   ].includes(this.callStatus);

  //   if (isInValidCallStatus) {
  //     const opts = {
  //       id: this.callId,
  //       hangupOnCaller: true,
  //       blockCaller: true,
  //       assessCallOnLeave: true,
  //     };
  //     EventBus.$emit(events.call.end, opts);
  //   } else {
  //     this.$buefy.toast.open({
  //       type: "is-danger",
  //       message: "Error: unable to block caller.",
  //     });
  //     console.warn("failed to block caller");
  //   }
  // }
}
