import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { AuthState, RootState } from '@/types/store';

export function initialState(): AuthState {
    return {
        loggedIn: false,
        authToken: null,
        refreshToken: null,
        retry: { refresh: false, call: null },
    };
}

const state: AuthState = initialState();

const namespaced: boolean = true;

export const authStore: Module<AuthState, RootState> = {
    namespaced,
    state,
    getters,
    actions,
    mutations,
};
