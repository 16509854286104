







import { Component, Vue, Watch } from "vue-property-decorator";
import auth from "@/services/auth";
import { session } from "./services/session";
import { EventBus } from "./eventBus";
import conf from "@/config";
import { app } from "@/config";
import events from "@/config/events";
import voiceConfig, { iso366Data } from "@/config/voice";
import axios, { AxiosResponse, AxiosError } from "axios";
import { Queue, Session } from "./types/domain";
import { buildQueueCollection } from "./subscriptions/realtime";
import isPlainObject from "lodash/isPlainObject";

@Component
export default class App extends Vue {
  tenantAbbr: string = process.env.VUE_APP_TENANT || "";

  get isAuthenticated() {
    return this.$store.getters["auth/loggedIn"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get sessionState(): Session {
    return this.$store.getters["session/state"];
  }

  get isHandlingCall() {
    return (
      [
        voiceConfig.status.ongoing,
        voiceConfig.status.wrappingUp,
        voiceConfig.status.handingOff,
        voiceConfig.status.supervising,
        voiceConfig.status.ended,
      ].includes(this.callStatus) &&
      [
        conf.agent.statuses.inCall,
        conf.agent.statuses.wrappingUpCall,
        conf.agent.statuses.supervisingCall,
        conf.agent.statuses.surveillingCall,
        conf.agent.statuses.assessingCall,
      ].includes(this.sessionStatus)
    );
  }

  @Watch("isAuthenticated", { immediate: true })
  handler(newValue: boolean, oldValue: boolean) {
    if (
      typeof newValue === "undefined" ||
      (newValue === false && oldValue === true)
    ) {
      this.resetSession(true);
    } else if (newValue) {
      console.debug("[APP] initialization after auth...");
      // eslint-disable-next-line vue/custom-event-name-casing
      EventBus.$emit("realtime/init");
      EventBus.$emit(events.twilio.deviceSetup);
      this.getAssignedQueues();
      this.getISO366Data();
    } else {
      this.resetSession();
    }
  }

  getAssignedQueues() {
    return new Promise((resolve) => {
      axios
        .get(`${app.apiUrl}/queue/self`, {
          headers: {
            Authorization: "Bearer " + this.$store.getters["auth/authToken"],
          },
        })
        .then((res: AxiosResponse<Queue[]>) => {
          const queues = Array.isArray(res.data) ? res.data : [];
          sessionStorage.setItem("thl-assigned-queues", JSON.stringify(queues));
          return Promise.all([
            queues,
            buildQueueCollection({ ...this.sessionState }, []),
          ]);
        })
        .then(([queues, callers]) =>
          Promise.all([queues, this.$store.dispatch("queue/update", callers)])
        )
        .then(([queues]) => resolve(queues))
        .catch((err: AxiosError) => {
          const error =
            err instanceof Error ? err.message : new Error(err).message;

          EventBus.$emit(
            "error-notification",
            `Error: Unable to fetch your assigned queues at this time. Details: ${error}`
          );
          console.error("failure to fetch thl assigned queues", err.response);
          sessionStorage.setItem("thl-assigned-queues", JSON.stringify([]));
          resolve([]);
        });
    });
  }

  getISO366Data() {
    return new Promise((resolve) => {
      const data = this.$store.getters["voice/iso366Data"];
      const fromSessionStorage = JSON.parse(
        sessionStorage.getItem("thl-iso366-data") || JSON.stringify(null)
      );

      if (!data && !isPlainObject(fromSessionStorage)) {
        console.debug(
          "[ISO366 Data loading starts...]",
          new Date().toISOString()
        );
        for (let idx = 0, length = iso366Data.raw.length; idx < length; idx++) {
          const record = iso366Data.raw[idx];
          iso366Data.index[record.country_name] = record;
        }
        console.debug(
          "[ISO366 Data loading ended...]",
          new Date().toISOString()
        );

        this.$store
          .dispatch("voice/updateISO366Data", iso366Data.index)
          .then(() => {
            console.debug(
              "[ISO366 Data is saved in the store...]",
              new Date().toISOString()
            );
            sessionStorage.setItem(
              "thl-iso366-data",
              JSON.stringify(iso366Data.index)
            );
            resolve(true);
          })
          .catch(console.error);
      } else if (isPlainObject(fromSessionStorage)) {
        this.$store
          .dispatch("voice/updateISO366Data", fromSessionStorage)
          .then(() => {
            console.debug(
              "[ISO366 Data is saved in the store (using data from session storage)...]",
              new Date().toISOString()
            );
            resolve(true);
          })
          .catch(console.error);
      } else {
        console.warn(
          "[ISO 366 Data] failed to load both from config and session storage"
        );
      }
    });
  }

  mounted() {
    const $title = document.getElementById("document-title");
    if ($title) {
      $title.innerHTML = `${this.tenantAbbr.toUpperCase()} Telephone Hotline`;
    }

    const oncustomevt = (e: CustomEvent) => {
      EventBus.$emit("open-warm-hand-off-and-set-phone-number", e.detail);
    };

    const onbeforeunload = () => {
      this.resetSession(!this.isAuthenticated);
      window.removeEventListener("beforeunload", onbeforeunload);
      //@ts-ignore
      window.removeEventListener(
        "open-warm-hand-off-and-set-phone-number",
        oncustomevt
      );
      EventBus.$off();
    };

    window.addEventListener("beforeunload", onbeforeunload);

    //@ts-ignore
    window.addEventListener(
      "open-warm-hand-off-and-set-phone-number",
      oncustomevt
    );
  }

  private async resetSession(destroy: boolean = false) {
    try {
      await session.reset(destroy);
      // eslint-disable-next-line vue/custom-event-name-casing
      EventBus.$emit("realtime/stop");
      EventBus.$emit(events.twilio.deviceReset);
    } catch (error) {
      console.error("session reset error", error);
    }
  }
}
