import Config from "@/config";
import Vue from "vue";
import Vuex, { StoreOptions } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { RootState } from "@/types/store";
import { authStore } from "./modules/auth";
import { queueStore } from "./voice/queue";
import { agentStore } from "./modules/agent";
import { sessionStore } from "./modules/session";
import { layoutStore } from "./modules/layout";
import { voiceStore } from "./voice";
import { callStore } from "./voice/call";
import cloneDeep from "lodash/cloneDeep";

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  state: {
    application: "THL",
  },
  getters: {
    application: (state) => state.application,
    config: () => Object.freeze(cloneDeep(Config)),
  },
  actions: {
    destroyStore({ commit }) {
      // TODO: reset all modules
      commit("auth/reset");
      commit("queue/reset");
      commit("agent/reset");
      commit("session/reset");
      commit("voice/reset");
      commit("call/reset");
      commit("layout/reset");
    },
  },
  modules: {
    auth: authStore,
    queue: queueStore,
    agent: agentStore,
    session: sessionStore,
    voice: voiceStore,
    call: callStore,
    layout: layoutStore,
  },
  plugins: [createPersistedState({ key: "thl", storage: sessionStorage })],
};

export default new Vuex.Store<RootState>(store);
