









































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  AssessmentQuestion,
  SessionAssessmentQuestion,
  SessionAssessmentQuestionOption,
  SessionAssessmentResponse,
} from "@/types/domain";
import { AssessmentAnswer } from "@/types/domain";

@Component
export default class QuestionnaireQuestion extends Vue {
  @Prop() private question!: SessionAssessmentQuestion;
  @Prop(Array) private options!: SessionAssessmentQuestionOption[];
  @Prop() private response!: SessionAssessmentResponse | null;
  private selection: number | null = null;

  @Watch("response", { immediate: true, deep: true })
  private onResponseChange(val: SessionAssessmentResponse) {
    if (val && val.option !== this.selection) {
      this.selection = val.option;
    }
  }

  freeforminput(option: SessionAssessmentQuestionOption) {
    const predicate =
      this.response &&
      this.response.freeform &&
      this.response.option === option.id;

    if (!predicate) return "";
    return this.response && predicate
      ? this.response.text.trim() === "Other"
        ? ""
        : this.response.text.trim()
      : "";
  }

  private onFreeformInputChange(
    question: SessionAssessmentQuestion,
    option: SessionAssessmentQuestionOption,
    val: string
  ) {
    if (typeof val === "string") {
      this.$emit("updateResponses", {
        question: question.id,
        position: question.position,
        option: option.id,
        text: val.trim(),
        freeform: option.freeform,
        termination: option.termination,
      });
    }
  }

  private onFreeformFocus(
    question: SessionAssessmentQuestion,
    option: SessionAssessmentQuestionOption,
    val: string
  ) {
    if (
      this.selection &&
      this.selection !== option.id &&
      typeof val === "string"
    ) {
      this.$emit("updateResponses", {
        question: question.id,
        position: question.position,
        option: option.id,
        text: val.trim(),
        freeform: option.freeform,
        termination: option.termination,
      });
      const $input = document.getElementById(
        `session-assessment-question-${question.id}-option-${option.id}`
      );
      $input && $input.focus();
    }
  }

  private updateRadioResponse(
    question: SessionAssessmentQuestion,
    option: SessionAssessmentQuestionOption
  ) {
    this.$emit("updateResponses", {
      question: question.id,
      position: question.position,
      option: option.id,
      text: option.text,
      freeform: option.freeform,
      termination: option.termination,
    });
  }
}
