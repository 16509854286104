import { MutationTree } from "vuex";
import { Session } from "@/types/domain";
import { initialState } from "./index";
import { propset } from "../../../config/helpers";
import Vue from "vue";
// import events from '@/config/events';
// import { EventBus } from '@/eventBus';
// import conf from '@/config';

export const mutations: MutationTree<Session> = {
  setSession(state, session: Session) {
    propset(state, session);
  },
  setStatus(state, status: string) {
    state.status = status;
  },
  setDebugInfo(state, info: null | Record<string, unknown>) {
    Vue.prototype.$set(state, "debug", info);
  },
  reset(state) {
    const init = initialState();
    propset(state, init);
  },
};
