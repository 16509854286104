




















































































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import conf, { events } from "@/config";
import { client } from "@/services/client";
import { call } from "@/services/call";
import {
  Client,
  AssessmentAnswer,
  AssessmentNest,
  AssessmentQuestion,
  AssessmentChoice,
  Call,
  Reference,
  ClientReference,
  Center,
  CensusFormQuestion,
  SessionAssessmentQuestion,
  SessionAssessmentQuestionnaire,
  SessionAssessmentQuestionOption,
  SessionAssessmentResponse,
  DemographicFreeformAnswer,
  CensusFormQuestionOption,
} from "@/types/domain";
import { EventBus } from "@/eventBus";
import QuestionnaireQuestion from "./Question.vue";
import { transformAssessmentDataIntoQuestionnaire } from "@/services/client";
import ErrorMessages from "@/components/common/Errors.vue";
import { success, info, failure } from "@/config/helpers";
import groupBy from "lodash/groupBy";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import getPropValue from "lodash/get";
import pickFromObject from "lodash/pick";
import flattenArray from "lodash/flatten";
import intersection from "lodash/intersection";
import capitalize from "lodash/capitalize";
import { isString } from "lodash";

@Component({
  components: {
    QuestionnaireQuestion,
    ErrorMessages,
  },
})
export default class SessionAssessment extends Vue {
  private assessment: SessionAssessmentQuestionnaire | null = null;
  private questionnaireCurrentQuestionIdx: number = 1;
  private errors: string[] = [];
  private censusQuestions: CensusFormQuestion[] = [];
  private censusCompleted: boolean = false;
  private resourcesCompleted: boolean = false;
  private questionnaireCompletedInput: boolean = false;
  private externalResources: Record<string, string> = {
    SHL_session_eval:
      process.env.VUE_APP_SHL_EXTERNAL_SESSION_ASSESSMENT_URL ||
      conf.launchpadUrl ||
      "#",
  };

  private mounted() {
    this.getCensusData();
    this.fetchAssessment();
    document.getElementById("resources-id").focus();
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get resourceslabel() {
    switch (this.tenant) {
      case "shl": {
        return "reference";
      }
      default: {
        return "resource";
      }
    }
  }

  get sessionassessmentlabel() {
    switch (this.tenant) {
      case "shl": {
        return "Call Wrap Up";
      }
      default: {
        return "Session Assessment";
      }
    }
  }

  get submitBtnActiveState() {
    if (this.assessment) {
      switch (this.tenant) {
        case "shl": {
          return (
            !this.resourcesCompleted ||
            !this.censusCompleted ||
            !this.questionnaireCompleted ||
            !this.questionnaireCompletedInput
          );
        }
        default: {
          return (
            !this.resourcesCompleted ||
            !this.censusCompleted ||
            !this.questionnaireCompleted
          );
        }
      }
    } else {
      switch (this.tenant) {
        case "shl": {
          return (
            !this.resourcesCompleted ||
            !this.censusCompleted ||
            !this.questionnaireCompletedInput
          );
        }
        default: {
          return !this.resourcesCompleted || !this.censusCompleted;
        }
      }
    }
  }

  formatlabel(label: string, casing: string) {
    switch (casing) {
      case "lowercase": {
        return label.toLowerCase();
      }
      case "uppercase": {
        return label.toUpperCase();
      }
      case "capitalize": {
        return capitalize(label);
      }
      default: {
        return label;
      }
    }
  }

  transformlabel(label: string, transformation: string) {
    switch (transformation) {
      case "optional-pluralize": {
        return `${label}(s)`;
      }
      case "pluralize": {
        return `${label}s`;
      }
      default: {
        return label;
      }
    }
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get client(): Client {
    return this.$store.getters["call/client"];
  }

  get clientId() {
    return isPlainObject(this.client) &&
      !isEmpty(String(this.client.id).trim()) &&
      !isNaN(Number(this.client.id))
      ? Number(this.client.id)
      : Number.NaN;
  }

  get callId() {
    return this.$store.getters["call/id"];
  }

  get clientName(): string {
    return isPlainObject(this.client) &&
      typeof this.client.name === "string" &&
      this.client.name.trim().length
      ? this.client.name
      : "";
  }

  get questionnaireQuestions(): SessionAssessmentQuestion[] {
    return this.assessment
      ? this.assessment.questions.sort((a, b) => a.position - b.position)
      : [];
  }

  get questionnaireQuestionsCount(): number {
    if (this.assessment) {
      const terminationQuestionResponse = this.questionnaireQuestionResponses.find(
        (r) => r.termination
      );
      if (terminationQuestionResponse) {
        const question = this.questionnaireQuestions.find(
          (q) => q.id === terminationQuestionResponse.question
        );
        if (question) {
          return question.position;
        }
      }

      return this.questionnaireQuestions.length;
    }

    return 0;
  }

  get questionnaireQuestionOptions(): SessionAssessmentQuestionOption[] {
    return this.assessment ? this.assessment.options : [];
  }

  get questionnaireQuestionResponses(): SessionAssessmentResponse[] {
    return this.assessment ? this.assessment.responses : [];
  }

  get questionnaireCurrentQuestion() {
    if (this.assessment && this.questionnaireCurrentQuestionIdx) {
      return this.questionnaireQuestions.length &&
        this.questionnaireQuestions[this.questionnaireCurrentQuestionIdx - 1]
        ? this.questionnaireQuestions[this.questionnaireCurrentQuestionIdx - 1]
        : null;
    }

    return null;
  }

  get questionnaireCompleted() {
    if (this.assessment) {
      if (this.questionnaireQuestions.length) {
        const thereAreNoMissingQuestions =
          this.questionnaireQuestionResponses.length ==
          this.questionnaireQuestionsCount;
        // &&
        // this.questionnaireCurrentQuestionIdx ===
        //   this.questionnaireQuestionsCount;
        this.assessment.completed = thereAreNoMissingQuestions;
        return thereAreNoMissingQuestions;
      } else {
        this.assessment.completed = true;
        return true;
      }
    }
    return false;
  }

  private displayCensusResponse(
    question: CensusFormQuestion,
    option: CensusFormQuestionOption
  ) {
    if (question.input) {
      const freeform = this.censusSubmission[
        `${question.id}.${option.id}.freeform`
      ];
      if (typeof freeform === "string" && freeform.trim().length) {
        return freeform.trim();
      }
      return "";
    } else {
      return option.label;
    }
  }

  private updateQuestionnaireResponses(response: SessionAssessmentResponse) {
    if (this.assessment) {
      const responses = this.assessment.responses
        .filter((r) => r.question !== response.question)
        .concat(response)
        .sort((a, b) => a.position - b.position);

      const hasTermination = responses.findIndex((r) => r.termination);

      const filteredResponses =
        hasTermination !== -1
          ? responses.slice(0, hasTermination + 1)
          : responses;

      Vue.prototype.$set(this.assessment, "responses", filteredResponses);

      this.$store.dispatch(
        "call/updateSessionAssessmentState",
        filteredResponses
      );
    }
  }

  private getQuestionResponse(question: SessionAssessmentQuestion) {
    const response = this.questionnaireQuestionResponses.find(
      (resp) => resp.question === question.id
    );
    return response ? response : null;
  }

  private reset() {
    this.assessment = null;
    this.questionnaireCurrentQuestionIdx = 1;
    this.questionnaireCompletedInput = false;
    this.errors = [];
    this.censusQuestions = [];
  }

  private async fetchAssessment() {
    try {
      if (this.client) {
        const assessments = await client.getAssessment(this.client.id);
        if (Array.isArray(assessments) && assessments.length) {
          this.assessment = transformAssessmentDataIntoQuestionnaire(
            assessments[0]
          );
          Vue.prototype.$set(
            this.assessment,
            "responses",
            this.$store.getters["call/assessment"]
          );
        } else {
          this.assessment = null;
        }
      } else {
        console.warn("unable to fetch assessment", this.client);
      }
    } catch (err) {
      console.error("error fetching assessment", err);
    }
  }

  get censusSubmission(): Record<string, string | number | number[]> | null {
    const census = this.$store.getters["call/census"];
    return isPlainObject(census) && !isEmpty(census) ? census : {};
  }

  get shouldDisplayCensus() {
    const predicate = !["shl"].includes(this.tenant);
    this.censusCompleted = predicate === false;
    return predicate;
  }

  get references() {
    return this.$store.getters["call/references"];
  }

  get clientReferences() {
    return this.$store.getters["call/clientReferences"];
  }

  get centers() {
    return this.$store.getters["call/centers"];
  }

  removeReference(reference: Reference) {
    return this.$store.dispatch("call/removeReference", reference);
  }
  removeClientReference(reference: ClientReference) {
    return this.$store.dispatch("call/removeClientReference", reference);
  }
  removeCenter(center: Center) {
    return this.$store.dispatch("call/removeCenter", center);
  }

  get censusResponses() {
    const savedResponses =
      isPlainObject(this.censusSubmission) && !isEmpty(this.censusSubmission)
        ? this.censusSubmission
        : {};

    const resolver = (
      censusQuestions: CensusFormQuestion[],
      censusResponses: Record<string, string | number | number[]>
    ) => {
      return censusQuestions.reduce(
        (
          census: Array<
            CensusFormQuestion & { responses: CensusFormQuestionOption[] }
          >,
          question: CensusFormQuestion
        ) => {
          const selectedResponses = getPropValue(
            censusResponses,
            `${question.id}.selected`
          );

          if (Array.isArray(selectedResponses) && selectedResponses.length) {
            const questionOptions = question.options.map(
              (option: CensusFormQuestionOption) => {
                return {
                  ...option,
                  id: Number(option.id),
                } as CensusFormQuestionOption;
              }
            );
            const questionResponses = selectedResponses.map((item: number) =>
              Number(item)
            );

            if (question.input && questionResponses.length) {
              const freeFormResponse = getPropValue(
                censusResponses,
                `${question.id}.${questionResponses[0]}.freeform`
              );

              if (
                isString(freeFormResponse) &&
                !isEmpty(freeFormResponse.trim())
              ) {
                return census.concat({
                  ...question,
                  responses: questionOptions.filter(
                    (questionOption: CensusFormQuestionOption) =>
                      questionResponses.includes(questionOption.id)
                  ),
                });
              }
            } else {
              return census.concat({
                ...question,
                responses: questionOptions.filter(
                  (questionOption: CensusFormQuestionOption) =>
                    questionResponses.includes(questionOption.id)
                ),
              });
            }
          }
          return census;
        },
        [] as Array<
          CensusFormQuestion & { responses: CensusFormQuestionOption[] }
        >
      );
    };
    const responses = resolver(this.censusQuestions, savedResponses);
    return responses.sort(
      (a: any, b: any) => Number(a.position) - Number(b.position)
    );
  }

  get censusFreeformResponses() {
    const savedResponses =
      isPlainObject(this.censusSubmission) && !isEmpty(this.censusSubmission)
        ? this.censusSubmission
        : {};
    return Object.keys(savedResponses)
      .filter((key) => key.endsWith("freeform"))
      .reduce((data: DemographicFreeformAnswer[], key: string) => {
        const [, optionId] = key.split(".");
        data.push({
          optionId: Number(optionId),
          answer: savedResponses[key] as string,
        });
        return data;
      }, []);
  }

  get censusSummary() {
    return this.shouldDisplayCensus
      ? flattenArray(
          this.censusResponses.map((question: any) => {
            return question.responses.map((response: any) =>
              Number(response.id)
            );
          })
        )
      : [];
  }

  get censusSubmissionValidation() {
    const questions = this.censusQuestions
      .filter((q: any) => q.required)
      .map((q: any) => Number(q.id));
    const responses = this.censusResponses.map((q: any) => Number(q.id));
    const validation =
      intersection(questions, responses).length === questions.length;

    return validation;
  }

  get censusSubmissionRequired() {
    return this.client && this.client.requiresCensus;
  }

  private getCensusData() {
    if (!isNaN(Number(this.clientId)) && this.shouldDisplayCensus) {
      client
        .getCensusData(this.clientId, false)
        .then((questions) => {
          this.censusQuestions = Array.isArray(questions) ? questions : [];
        })
        .catch((error) => {
          this.censusQuestions = [];
          console.error("failed to load census questions", error);
        });
    }
  }
  private pushError(message: string) {
    this.errors = this.errors.concat(message);
  }
  private clearErrors() {
    this.errors = [];
  }

  private submit() {
    if (this.censusSubmissionRequired && !this.censusResponses.length) {
      info(this, `Census submission is required.`);
      return;
    }

    if (this.censusResponses.length && !this.censusSubmissionValidation) {
      info(this, `Please complete all required census questions.`);
      return;
    }

    if (
      !this.resourcesCompleted ||
      !this.censusCompleted ||
      (this.assessment && !this.questionnaireCompleted)
    ) {
      const resourceslabel = this.transformlabel(
        this.formatlabel(this.resourceslabel, "capitalize"),
        "pluralize"
      );

      const message = ["Unable to submit."]
        .concat(
          !this.resourcesCompleted
            ? `${resourceslabel} confirmation is required.`
            : ""
        )
        .concat(
          !this.censusSubmission
            ? "Census responses confirmation is required."
            : ""
        )
        .concat(
          this.assessment && !this.questionnaireCompleted
            ? "Questionnaire completion is required."
            : ""
        );

      return info(this, message.join(" "));
    }

    if (["shl"].includes(this.tenant) && !this.questionnaireCompletedInput) {
      info(
        this,
        `Please acknowledge that you will complete a session assessment before another call.`
      );
      return;
    }

    if (
      this.assessment &&
      !isNaN(Number(this.clientId)) &&
      !isNaN(Number(this.callId))
    ) {
      call
        .updateResources({
          callId: this.callId,
          references: Array.isArray(this.references) ? this.references : [],
          clientReferences: Array.isArray(this.clientReferences)
            ? this.clientReferences
            : [],
          demographics: Array.isArray(this.censusSummary)
            ? this.censusSummary
            : [],
          demographicFreeformChoices: Array.isArray(
            this.censusFreeformResponses
          )
            ? this.censusFreeformResponses
            : [],
          centers: Array.isArray(this.centers) ? this.centers : [],
        })
        .then(() => {
          if (this.assessment) {
            const answers = this.questionnaireQuestionResponses.map((r) => ({
              id: r.option,
              freeform: r.freeform ? r.text : null,
            }));

            const shouldFilterFreeformAnswers = !client.hasSessionAssessmentQuestionnaireFreeformQuestionOptions(
              Number(this.clientId)
            );

            return client.submitAssessment({
              sessionAssessmentId: this.assessment.assessment.id,
              callId: this.callId,
              answers: shouldFilterFreeformAnswers
                ? answers.filter((a) => !a.freeform)
                : answers,
              notes: "",
            });
          }
          return Promise.resolve(true);
        })
        .then(() => {
          success(this, `${this.sessionassessmentlabel} submitted`);
          EventBus.$emit(events.call.reset);
          this.$emit("close");
          // @ts-ignore
          this.$parent.close();
        })
        .catch((err: Error) => {
          failure(
            this,
            "Submission failure. Please contact your system administrator."
          );
          console.error(
            "session assessment submission failed",
            this.clientId,
            this.callId,
            this.censusResponses,
            this.censusSummary,
            err
          );
        });
    } else {
      failure(
        this,
        "Submission failure. Please contact your system administrator."
      );
      console.error(
        "unable to submit assessment... invalid call id",
        this.clientId,
        this.callId,
        this.assessment,
        this.censusResponses,
        this.censusSummary
      );
    }
  }

  private close(issubmission = true) {
    if (!issubmission) {
      EventBus.$emit(events.call.reset);
      this.$emit("close");
      // @ts-ignore
      this.$parent.close();
      return;
    }

    if (this.censusSubmissionRequired && !this.censusResponses.length) {
      info(this, `Census submission is required.`);
      return;
    }

    if (this.censusResponses.length && !this.censusSubmissionValidation) {
      info(this, `Please complete all required census questions.`);
      return;
    }

    if (
      !this.resourcesCompleted ||
      !this.censusCompleted ||
      (this.assessment && !this.questionnaireCompleted)
    ) {
      const resourceslabel = this.transformlabel(
        this.formatlabel(this.resourceslabel, "capitalize"),
        "pluralize"
      );

      const message = ["Unable to submit."]
        .concat(
          !this.resourcesCompleted
            ? `${resourceslabel} confirmation is required.`
            : ""
        )
        .concat(
          !this.censusSubmission
            ? "Census responses confirmation is required."
            : ""
        )
        .concat(
          this.assessment && !this.questionnaireCompleted
            ? "Questionnaire completion is required."
            : ""
        );

      return info(this, message.join(" "));
    }

    if (["shl"].includes(this.tenant) && !this.questionnaireCompletedInput) {
      info(
        this,
        `Please acknowledge that you will complete a session assessment before another call.`
      );
      return;
    }

    if (!isNaN(Number(this.clientId)) && !isNaN(Number(this.callId))) {
      call
        .updateResources({
          callId: this.callId,
          references: Array.isArray(this.references) ? this.references : [],
          clientReferences: Array.isArray(this.clientReferences)
            ? this.clientReferences
            : [],
          demographics: Array.isArray(this.censusSummary)
            ? this.censusSummary
            : [],
          demographicFreeformChoices: Array.isArray(
            this.censusFreeformResponses
          )
            ? this.censusFreeformResponses
            : [],
          centers: Array.isArray(this.centers) ? this.centers : [],
        })
        .then(() => {
          success(this, `${this.sessionassessmentlabel} submitted`);
          EventBus.$emit(events.call.reset);
          this.$emit("close");
          // @ts-ignore
          this.$parent.close();
        })
        .catch((err: Error) => {
          failure(
            this,
            "Submission failure. Please contact your system administrator."
          );
          console.error(
            "session assessment submission failed",
            this.callId,
            err
          );
        });
    }
  }
}
