import cloneDeep from "lodash/cloneDeep";
import { GetterTree } from "vuex";
import { Session } from "@/types/domain";
import { RootState } from "@/types/store";

export const getters: GetterTree<Session, RootState> = {
  state: (state: Session) => cloneDeep(state),
  status: (state: Session) => state.status,
  id: (state: Session) => state.id,
  role: (state: Session) => state.role,
  displayName: (state: Session) => state.displayName,
  firstName: (state: Session) => state.firstName,
  lastName: (state: Session) => state.lastName,
  fullName: (state: Session) => `${state.firstName} ${state.lastName}`,
  capabilityToken: (state: Session) => state.capabilityToken,
  queueRotations: (state: Session) => state.queueRotations,
  callQueues: (state: Session) => state.callQueues,
  statusTimestamp: (state: Session) => state.statusChangedAt,
  debug: (state: Session) => state.debug,
};
