




















































import { Component, Vue, Watch } from "vue-property-decorator";
import Services from "./Services.vue";
import CenterSearch from "./CenterSearch.vue";
import Notes from "./Notes.vue";
import OnCall from "./OnCall.vue";
import Updates from "./Updates.vue";
import ClientReferences from "./ClientReferences.vue";
import { EventBus } from "../../../../eventBus";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import conf from "@/config";

import { Client } from "@/types/domain";
import capitalize from "lodash/capitalize";

@Component({
  components: {
    Services,
    CenterSearch,
    Notes,
    OnCall,
    Updates,
    ClientReferences,
  },
})
export default class ClientTabs extends Vue {
  private activeTab: number = 0;

  get client(): Client {
    return this.$store.getters["call/client"];
  }

  get clientName(): string {
    return isPlainObject(this.client) &&
      typeof this.client.name === "string" &&
      this.client.name.trim().length
      ? this.client.name
      : "";
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get resourceslabel() {
    switch (this.tenant) {
      case "shl": {
        return "reference";
      }
      default: {
        return "resource";
      }
    }
  }

  formatlabel(label: string, casing: string) {
    switch (casing) {
      case "lowercase": {
        return label.toLowerCase();
      }
      case "uppercase": {
        return label.toUpperCase();
      }
      case "capitalize": {
        return capitalize(label);
      }
      default: {
        return label;
      }
    }
  }

  transformlabel(label: string, transformation: string) {
    switch (transformation) {
      case "optional-pluralize": {
        return `${label}(s)`;
      }
      case "pluralize": {
        return `${label}s`;
      }
      default: {
        return label;
      }
    }
  }

  get shouldDisplayCentersSearch() {
    switch (this.tenant) {
      case "demo":
      case "nsah": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  get shouldDisplayClientReferences() {
    switch (this.tenant) {
      case "demo":
      case "ncvc":
      case "nsah": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  get shouldDisplayUpdatesContent() {
    switch (this.tenant) {
      case "demo":
      case "ncvc":
      case "nsah": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  get shouldDisplayOnCallSchedule() {
    switch (this.tenant) {
      case "demo":
      case "ncvc":
      case "nsah": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  get shouldDisplayServicesOfferred() {
    switch (this.tenant) {
      case "demo":
      case "ncvc":
      case "nsah": {
        return true;
      }
      default: {
        return false;
      }
    }
  }

  @Watch("activeTab", { immediate: true })
  onActiveTabChange(currentVal: number, previousVal: number) {
    EventBus.$emit("client-tabs-panels-active-tab", {
      previous: previousVal,
      active: currentVal,
    });
  }
}
