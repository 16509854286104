


































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/eventBus";
import events from "@/config/events";
import {
  Reference,
  Client,
  ClientReference,
  Center,
  OnCallAdvocate
} from "@/types/domain";
import conf, { voice } from "@/config";
import selectUniqItem from "lodash/uniq";
import startCase from "lodash/startCase";
import capitalize from "lodash/capitalize";

@Component
export default class ReferencePreview extends Vue {
  private showVerificationActions: boolean = true;
  private reference: Reference | null = null;
  private clientReference: ClientReference | null = null;
  private center: Center | null = null;
  private onCallAdvocate: OnCallAdvocate | null = null;
  private parent: Reference | null = null;
  private path: string | null = "";
  private verified: boolean = false;
  private statuses = voice.status;
  private selectedClientReferenceInfo: number = 0;
  private selectedCenterInfo: number = 0;

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get isAssessingCall() {
    return this.sessionStatus === conf.agent.statuses.assessingCall;
  }

  get maxContentWidth() {
    const $ref: Element = this.$refs["content-box"] as Element;
    return $ref && $ref.clientWidth ? $ref.clientWidth : 250;
  }

  get maxContentHeight() {
    const $ref: Element = this.$refs["content-box"] as Element;
    return $ref && $ref.clientHeight ? $ref.clientHeight : 400;
  }

  get clientName() {
    const client: Client = this.$store.getters["call/client"];
    return client && client.name ? client.name : "";
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get resourceslabel() {
    switch (this.tenant) {
      case "shl": {
        return "reference";
      }
      default: {
        return "resource";
      }
    }
  }

  formatlabel(label: string, casing: string) {
    switch (casing) {
      case "lowercase": {
        return label.toLowerCase();
      }
      case "uppercase": {
        return label.toUpperCase();
      }
      case "capitalize": {
        return capitalize(label);
      }
      default: {
        return label;
      }
    }
  }

  transformlabel(label: string, transformation: string) {
    switch (transformation) {
      case "optional-pluralize": {
        return `${label}(s)`;
      }
      case "pluralize": {
        return `${label}s`;
      }
      default: {
        return label;
      }
    }
  }

  private transformReferenceBody(reference: string) {
    const transformations = [
      this.transformPlainTextURLsToClickableLinks,
      this.makeTelephoneNumbersClickable
    ];

    return transformations.reduce((content: string, transformationFn) => {
      return transformationFn(content);
    }, reference);
  }

  private transformPlainTextURLsToClickableLinks(text: string) {
    return [
      /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim,
      /(^|[^\/])(www\.[\S]+(\b|$))/gim,
      /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim
    ].reduce((result: string, re: RegExp, idx: number) => {
      switch (idx) {
        case 0: {
          return result.replace(re, '<a href="$1" target="_blank">$1</a>');
        }
        case 1: {
          return result.replace(
            re,
            '$1<a href="http://$2" target="_blank">$2</a>'
          );
        }
        case 2: {
          return result.replace(re, '<a href="mailto:$1">$1</a>');
        }

        default: {
          return result;
        }
      }
    }, text);
  }

  private makeTelephoneNumbersClickable(text: string) {
    return [
      /(?:(?:\+?([1-9]|[0-9][0-9]|[0-9][0-9][0-9])\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([0-9][1-9]|[0-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/
    ].reduce((result: string, re: RegExp, idx: number) => {
      switch (idx) {
        case 0: {
          return result.replace(
            re,
            (match: string) =>
              `
              <a 
                href="#" 
                class="warm-hand-off-trigger" 
                onclick="triggerWarmHandOff('${match}')"
              >
                ${match}
              </a>`
          );
        }
        default: {
          return result;
        }
      }
    }, text);
  }

  private mounted() {
    EventBus.$on(
      events.reference.preview,
      ({
        reference,
        parent,
        path
      }: {
        reference: Reference;
        parent: Reference;
        path: string;
      }) => {
        this.reference = reference;
        this.parent = parent;
        this.path = path;
        this.center = null;
        this.clientReference = null;
        this.onCallAdvocate = null;
        this.toggleVerificationActions(true);
      }
    );

    EventBus.$on(
      events.clientReference.preview,
      (clientReference: ClientReference) => {
        this.reference = null;
        this.parent = null;
        this.path = null;
        this.clientReference = {
          ...clientReference,
          path: `
          Client ${this.transformlabel(
            this.formatlabel(this.resourceslabel, "capitalize"),
            "pluralize"
          )} > 
          ${startCase(this.clientName)} > 
          ${clientReference.Issue} > 
          ${clientReference.Type} > 
          ${clientReference.Name}
          `
        };
        this.onCallAdvocate = null;
        this.center = null;
        this.toggleVerificationActions(true);
      }
    );

    EventBus.$on(events.center.preview, (center: Center) => {
      this.reference = null;
      this.parent = null;
      this.path = null;
      this.clientReference = null;
      this.center = center;
      this.onCallAdvocate = null;
      this.toggleVerificationActions(true);
    });

    EventBus.$on(
      events.onCallAdvocate.preview,
      (onCallAdvocate: OnCallAdvocate) => {
        this.reference = null;
        this.parent = null;
        this.path = null;
        this.clientReference = null;
        this.center = null;
        this.onCallAdvocate = onCallAdvocate;
        this.toggleVerificationActions(true);
      }
    );

    EventBus.$on(events.call.reset, this.reset);
    EventBus.$on(events.call.end, this.reset);
  }

  private clientReferenceAsArray(someClientReference: ClientReference) {
    const infoRecordKeys = Object.keys(someClientReference).filter(
      key =>
        !["Client", "ID", "Issue", "Type", , "path"].includes(key) &&
        // @ts-ignore
        someClientReference[key].trim()
    );

    const infoRecordKeyLabel = (someClientReferenceProp: string) =>
      startCase(someClientReferenceProp.trim());
    return infoRecordKeys.map(key => ({
      label: infoRecordKeyLabel(key),
      // @ts-ignore
      text: someClientReference[key].trim()
    }));
  }

  private remove() {
    this.reference = null;
    this.parent = null;
    this.path = null;
    this.clientReference = null;
    this.center = null;
    this.onCallAdvocate = null;
    this.toggleVerificationActions(false);
    EventBus.$emit(events.interactions.clearPreviewPanel);
  }

  private cleanedPath(path: string) {
    return path
      .split(">")
      .slice(1)
      .join(" > ");
  }

  private pathAsHtml(path: string) {
    return path
      .split(">")
      .slice(1)
      .join(' <i class="icon ion-ios-arrow-forward" />  ');
  }

  private verify() {
    if (this.reference && this.path) {
      EventBus.$emit(events.reference.verify, {
        ...this.reference,
        path: this.cleanedPath(this.path)
      });

      this.toggleVerificationActions(false);

      this.$buefy.toast.open({
        message: "Reference confirmed",
        type: "is-success",
        position: "is-top-right",
        queue: true
      });
    } else if (this.clientReference) {
      EventBus.$emit(events.clientReference.verify, this.clientReference);

      this.toggleVerificationActions(false);

      this.$buefy.toast.open({
        message: "Reference confirmed",
        type: "is-success",
        position: "is-top-right",
        queue: true
      });
    } else if (this.center) {
      EventBus.$emit(events.center.verify, this.center);
      this.toggleVerificationActions(false);

      this.$buefy.toast.open({
        message: "Center confirmed",
        type: "is-success",
        position: "is-top-right",
        queue: true
      });
    } else if (this.onCallAdvocate) {
      EventBus.$emit(events.onCallAdvocate.verify, this.onCallAdvocate);
      this.toggleVerificationActions(false);
      this.$buefy.toast.open({
        message: "On Call Advocate confirmed",
        type: "is-success",
        position: "is-top-right",
        queue: true
      });
    } else {
      this.$buefy.toast.open({
        message:
          "Submission failure. Please contact your system administrator.",
        type: "is-danger",
        position: "is-top-right",
        queue: true
      });
    }
  }

  private toggleVerificationActions(bool: boolean | null = null) {
    if (typeof bool === "boolean") {
      this.showVerificationActions = bool;
      return;
    }

    this.showVerificationActions = !this.showVerificationActions;
  }

  private reset() {
    this.reference = null;
    this.clientReference = null;
    this.center = null;
    this.parent = null;
    this.onCallAdvocate = null;
    this.verified = false;
    this.showVerificationActions = true;
  }

  private isEmail(someString: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return re.test(someString);
  }
}
