















import { Component, Vue, Watch } from "vue-property-decorator";
import { Client } from "@/types/domain";
import { EventBus } from "@/eventBus";
import { events } from "@/config";
import { client as clientService } from "@/services/client";

@Component
export default class Services extends Vue {
  private data: string | null = "";

  get clientId() {
    const client: Client = this.$store.getters["call/client"];
    return client && client.id ? client.id : null;
  }

  mounted() {
    this.useSubscriptions();
  }

  private useSubscriptions() {
    EventBus.$on(events.call.reset, this.reset);
    // EventBus.$on(events.call.end, this.reset);
    EventBus.$on(events.call.start, this.fetchContent);
  }

  private async fetchContent() {
    if (this.clientId) {
      const data = await clientService.getServices(this.clientId);
      if (
        Array.isArray(data) &&
        data.length &&
        typeof data[0].text === "string" &&
        data[0].text.trim()
      ) {
        this.data = data[0].text;
      } else {
        this.data = null;
      }
    } else {
      this.reset();
    }
  }

  private reset() {
    this.data = null;
  }
}
