


















































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import conf, { events, voice } from "@/config";
import { EventBus } from "@/eventBus";
import { HandOffStates } from "@/types/store";
import { call } from "@/services/call";
import phoneValidator from "phone";
import pickProps from "lodash/pick";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import { iso366Data } from "@/config/voice";

@Component
export default class HandOff extends Vue {
  @Prop(String) warmHandOffPhone!: string;
  private phone: string = "";
  private phonecountry: string = "United States";
  errors: string[] = [];

  mounted() {
    if (this.handOffState) {
      const store = this.localstore("get");
      if (store && typeof store.phone === "string") {
        this.phone = store.phone;
      }
    }

    if (
      typeof this.warmHandOffPhone === "string" &&
      this.warmHandOffPhone.trim()
    ) {
      this.phone = this.warmHandOffPhone;
    }
  }

  get clientId() {
    const data = this.$store.getters["call/client"];
    return isPlainObject(data) && data.id ? data.id : "";
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get handOffState() {
    return this.$store.getters["call/handOffState"];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get pendingSessionAssessment() {
    return (
      this.callStatus === voice.status.ended &&
      this.sessionStatus === conf.agent.statuses.assessingCall
    );
  }

  get isMuted() {
    return this.$store.getters["voice/muted"];
  }

  get iso366Data(): Record<
    string,
    Record<string, string | string[] | number | number[]>
  > {
    return (
      this.$store.getters["voice/iso366Data"] || {
        "United States": iso366Data.raw[0],
      }
    );
  }

  get countries(): string[] {
    return Object.keys(this.iso366Data).sort();
  }

  get phonecountrycode(): string {
    return this.iso366Data[this.phonecountry].alpha3 as string;
  }

  get transfering() {
    if (this.phone && this.phoneValidation(this.phone)) {
      return phoneValidator(this.phone.trim(), this.phonecountrycode, true)[0];
    }

    return false;
  }

  private phoneValidation(input: any) {
    return (
      typeof input === "string" &&
      !isEmpty(phoneValidator(input.trim(), this.phonecountrycode, true))
    );
  }

  private transfer() {
    if (this.transfering) {
      // const phoneNumer = this.transfering.replace("+1", "");

      call.warmHandOff(this.transfering).then(
        () => {
          if (this.isMuted) {
            this.$store.dispatch("voice/mute", !this.isMuted);
          }

          this.$store.dispatch(
            "call/updateHandOffState",
            HandOffStates.ON_CALL_WITH_THIRD_PARTY
          );
        },
        (err) => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Error: unable to dial third party number.",
          });
          console.error("failed to dial third party number", err);
        }
      );
    }
  }

  private bringInCaller() {
    if (this.handOffState === HandOffStates.ON_CALL_WITH_THIRD_PARTY) {
      call
        .addCaller()
        .then(() => {
          this.$store.dispatch(
            "call/updateHandOffState",
            HandOffStates.ON_CALL_WITH_THIRD_PARTY_AND_CALLER
          );
        })
        .catch((err) => {
          console.error("Failed to add caller.", this.getErrorMessage(err));
        });
    }
  }

  private hangupThirdParty() {
    if (this.handOffState === HandOffStates.ON_CALL_WITH_THIRD_PARTY) {
      call
        .hangupParty()
        .then(() => {
          this.$store.dispatch("call/updateHandOffState", HandOffStates.IDLE);
        })
        .catch((err) => {
          console.error(
            "Could not hangup third party.",
            this.getErrorMessage(err)
          );
        });
    }
  }

  private sendDigit(digit: number | string) {
    if (
      this.handOffState === HandOffStates.ON_CALL_WITH_THIRD_PARTY &&
      Vue.prototype.$twilio.getConnection
    ) {
      Vue.prototype.$twilio.getConnection().sendDigits(String(digit));
    }
  }

  private leaveCall() {
    if (
      this.handOffState === HandOffStates.ON_CALL_WITH_THIRD_PARTY_AND_CALLER
    ) {
      if (
        [voice.status.ongoing, voice.status.handingOff].includes(
          this.callStatus
        )
      ) {
        this.$emit("closeModal");
        const opts = {
          hangupOnCaller: false,
        };
        EventBus.$emit(events.call.end, opts);
      }
    }
  }

  private getErrorMessage(err: any) {
    return err instanceof Error ? err.message : new Error(err).message;
  }

  private localstore(
    action: string,
    payload?: { phone: string }
  ): Record<string, any> | null {
    const noop = () => null;
    const store = JSON.parse(sessionStorage.getItem("hand-off-modal") || "{}");

    switch (action) {
      case "get": {
        return isPlainObject(store) ? store : noop();
      }

      case "set": {
        if (isPlainObject(payload)) {
          sessionStorage.setItem(
            "hand-off-modal",
            JSON.stringify(pickProps(payload, ["phone"]))
          );

          return this.localstore("get");
        }

        return noop();
      }

      default: {
        return noop();
      }
    }
  }

  private beforeDestroy() {
    this.localstore("set", {
      phone: this.handOffState && this.transfering ? this.phone : "",
    });
  }
}
