import isPlainObject from "lodash/isPlainObject";
import config, { app } from "@/config";
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import store from "@/store";
import Cookies from "js-cookie";
const logger = (...message: unknown[]) =>
  console.info("[AUTH SERVICE]", ...message);

axios.interceptors.request.use((reqConfig: Record<string, any>) => {
  reqConfig.headers = isPlainObject(reqConfig.headers) ? reqConfig.headers : {};
  const shouldAddBearerToken =
    reqConfig.url && reqConfig.url.includes(app.apiUrl);

  if (shouldAddBearerToken) {
    reqConfig.headers.Authorization =
      "Bearer " + store.getters["auth/authToken"];
  }

  return reqConfig;
});

axios.interceptors.response.use(
  (res: AxiosResponse) => res,
  (error: AxiosError) => {
    const status =
      error.response && !isNaN(error.response.status)
        ? error.response.status
        : Number.NaN;

    switch (status) {
      case 401: {
        logger("::AUTH TOKENS REFRESH REQUIRED   ", error.response);
        return store
          .dispatch("auth/refreshToken")
          .then(() => {
            error.config.headers = {
              Authorization: "Bearer " + store.getters["auth/authToken"],
            };
            error.config.baseURL = undefined;
            return axios.request(error.config);
          })
          .catch((err: unknown) => {
            console.error(
              "::AUTH TOKENS REFRESH FAILURE   ",
              new Error(err as any).message
            );
            store.dispatch("auth/logout");
          });
      }
      default: {
        return Promise.reject(error);
      }
    }
  }
);

const auth = {
  tokenApiUrl: `${process.env.VUE_APP_BACKEND_BASEURL}/oauth/token`,
  postConfig: {
    headers: {
      Authorization:
        "Basic " + btoa(config.auth.clientId + ":" + config.auth.secret),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  },
  storeAuthData(data: any) {
    const authData = {
      accessToken: data.access_token,
      refreshToken: data.refresh_token,
    };

    localStorage.setItem("authorizationData", JSON.stringify(authData));
  },
  login(data: { email: string; password: string }) {
    logger("Logging in.");

    const params =
      "grant_type=password&username=" +
      data.email +
      "&password=" +
      data.password +
      "&client_id=" +
      config.auth.clientId;

    return this.oauthRequest(params);
  },
  jwt() {
    const devToken =
      "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE1NTk4MzIwNjR9.pJ2oZP3udbYSic0KJ_dIK1yriboQte6BzSCTYWpF-ww";

    if (process.env.NODE_ENV === "development") {
      return devToken;
    }

    return Cookies.get("api_jwt");
  },
  logout() {
    return axios({
      url: `${app.apiUrl}/logout`,
      method: "POST",
    });
  },
  refreshToken() {
    logger("Refreshing token...");
  },
  oauthRequest(params: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const axiosConfig: AxiosRequestConfig = {
        url: this.tokenApiUrl,
        method: "POST",
        data: params,
        headers: this.postConfig.headers,
      };

      axios(axiosConfig)
        .then((res: AxiosResponse) => {
          resolve(res);
        })
        .catch((err: AxiosError) => {
          reject(err);
        });
    });
  },
};

export default auth;
