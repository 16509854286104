import Vue from "vue";
import { ActionTree } from "vuex";
import { AuthState, RootState } from "@/types/store";
import { OAuthResponseData } from "@/types/auth";
import auth from "@/services/auth";
import { session } from "@/services/session";
import conf, { events } from "@/config";
import { EventBus } from "@/eventBus";
import { AxiosResponse } from "axios";

import store from "@/store";
import router from "@/router";

export const actions: ActionTree<AuthState, RootState> = {
  async login({ commit }, formData: { email: string; password: string }) {
    return auth
      .login(formData)
      .then(async (res: any) => {
        const data: OAuthResponseData = res.data;
        commit("setAuthToken", data.access_token);
        commit("setRefreshToken", data.refresh_token);
        await session.generate();

        commit("setLoggedIn", true);

        EventBus.$emit("auth/success");

        Promise.resolve(res);
      })
      .catch((err: any) => {
        Promise.reject(err);
      });
  },
  async sso({ commit }, tokens: { accessToken: string; refreshToken: string }) {
    return Promise.resolve()
      .then(async (res: any) => {
        commit("setAuthToken", tokens.accessToken);
        commit("setRefreshToken", tokens.refreshToken);
        await session.generate();

        commit("setLoggedIn", true);

        EventBus.$emit("auth/success");

        Promise.resolve(res);
      })
      .catch((err: any) => {
        Promise.reject(err);
      });
  },
  async externalLogin(
    { commit },
    tokens: { accessToken: string; refreshToken: string }
  ) {
    return new Promise(async (resolve, reject) => {
      try {
        commit("setAuthToken", tokens.accessToken);
        commit("setRefreshToken", tokens.refreshToken);
        await session.generate();
        commit("setLoggedIn", true);
        EventBus.$emit("auth/success");
        resolve(true);
      } catch (error) {
        reject(error);
      }
    });
  },
  async logout() {
    return session
      .reset(true)
      .then(() => auth.logout())
      .then(() => {
        EventBus.$emit("realtime/stop");
        store.dispatch("destroyStore");
        setTimeout(() => {
          if (conf.env !== "development") {
            window.location.assign(conf.auth.logoutRedirect || "/login");
          } else {
            router.push({ path: "/login" });
          }
        });
      })
      .catch((err: unknown) => console.error("failed to logout", err));
  },
  reset({ commit }) {
    commit("reset");
  },
  refreshToken({ state, commit }) {
    if (state.refreshToken && state.authToken) {
      const params =
        "grant_type=refresh_token&refresh_token=" +
        state.refreshToken +
        "&client_id=" +
        conf.auth.clientId;

      commit("setRetryState", true);
      commit("setAuthToken", null);
      commit("setRefreshToken", null);

      return auth
        .oauthRequest(params)
        .then((res: AxiosResponse) => {
          const data: OAuthResponseData = res.data;

          if (!data.access_token || !data.refresh_token) {
            EventBus.$emit("realtime/stop");
            EventBus.$emit(events.twilio.deviceReset);
            session.reset(true);
            return Promise.reject(
              new Error(
                "[AUTH TOKENS REFRESH STORE ACTION] Invalid response payload."
              )
            );
          }

          console.info("[AUTH TOKENS REFRESH STORE ACTION] success...");

          commit("setAuthToken", data.access_token);
          commit("setRefreshToken", data.refresh_token);
          commit("setRetryState", false);
          return session.generate();
        })
        .then(() => {
          EventBus.$emit(events.twilio.deviceSetup);
          return true;
        })
        .catch((err) => {
          EventBus.$emit("realtime/stop");
          EventBus.$emit(events.twilio.deviceReset);
          session.reset(true);
          return Promise.reject(
            new Error(`[AUTH TOKENS REFRESH STORE ACTION] err: ${err}`)
          );
        });
    } else {
      EventBus.$emit("realtime/stop");
      EventBus.$emit(events.twilio.deviceReset);
      session.reset(true);
      return Promise.reject(
        new Error("[AUTH TOKENS REFRESH STORE ACTION] Invalid auth state.")
      );
    }
  },
};
