var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"census-panel"}},[_c('div',{staticClass:"card-content"},[(_vm.showForm)?_c('form',{attrs:{"id":"census-form"},on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_vm._m(0),(_vm.toggleErrors)?_c('div',{staticStyle:{"margin-bottom":"1.5rem"},attrs:{"id":"census-form-error-bag"}},_vm._l((_vm.errors),function(message,idx){return _c('ul',{key:(idx + "::" + (new Date().getTime()))},[_c('li',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(message))])])}),0):_vm._e(),_vm._l((_vm.questions),function(question){return _c('b-field',{key:question.id,attrs:{"label":((question.description) + " " + (question.required ? '*' : ''))}},[(!question.input)?_c('multiselect',{attrs:{"value":question.options.filter(
              function (option) { return option.label &&
                Array.isArray(_vm.responses[((question.id) + ".selected")]) &&
                _vm.responses[((question.id) + ".selected")].includes(option.id); }
            ),"options":question.options.filter(function (option) { return option.label; }),"multiple":question.multiple,"close-on-select":!question.multiple,"preserve-search":false,"clear-on-select":false,"show-labels":false,"placeholder":"","label":"label","track-by":"label"},on:{"input":function (value) {
              _vm.$store.dispatch('call/updateCensusResponses', {
                type: 'select',
                question: question.id,
                selected: question.multiple
                  ? value.map(function (item) { return item.id; })
                  : [value.id],
              });
            }}}):_vm._e(),_vm._l((question.options.filter(function (option) {
            return (
              option.freeform &&
              Array.isArray(_vm.responses[((question.id) + ".selected")]) &&
              _vm.responses[((question.id) + ".selected")].includes(option.id)
            );
          })),function(option){return _c('b-field',{key:option.id,staticClass:"freeform-field",attrs:{"label":_vm.questionFreeformOptionLabel(question, option)}},[_c('b-input',{attrs:{"value":_vm.responses[((question.id) + "." + (option.id) + ".freeform")] || '',"placeholder":""},nativeOn:{"change":function($event){return _vm.$store.dispatch('call/updateCensusResponses', {
                type: 'freeform',
                question: question.id,
                option: option.id,
                input: $event.target.value || '',
              })}}})],1)})],2)})],2):_c('div',{staticClass:"container",staticStyle:{"margin-top":"1.55rem","padding":"0 1rem"}},[_vm._m(1)])]),(_vm.showForm)?_c('div',{attrs:{"id":"census-form-footer"}},[_c('div',{staticClass:"buttons"},[_c('button',{staticClass:"button is-light",staticStyle:{"margin":"0 1rem"},attrs:{"type":"clear"},on:{"click":function($event){$event.preventDefault();return _vm.$store.dispatch('call/clearCensusResponses')}}},[_vm._v(" Clear ")]),_c('button',{staticClass:"button is-primary",staticStyle:{"margin":"0 1rem"},on:{"click":function($event){$event.preventDefault();return _vm.save($event)}}},[_vm._v(" Save ")])])]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"margin-bottom":"1.5rem"}},[_c('p',{staticClass:"fg"},[_vm._v("* Indicates required fields.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"fg flex"},[_c('i',{staticClass:"fg-primary ion-ios-information-circle-outline mar-r"}),_vm._v(" Demographic questions will be displayed (if available) once a call begins. ")])}]

export { render, staticRenderFns }