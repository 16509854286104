











































import { Component, Vue, Prop, PropSync, Watch } from "vue-property-decorator";
import { reference } from "@/services/reference";
import conf from "@/config";
import Tree from "./Tree.vue";
import { EventBus } from "@/eventBus";
import ReferenceSearch from "./Search.vue";

@Component({
  components: {
    Tree,
    ReferenceSearch,
  },
})
export default class References extends Vue {
  private references: any[] = [];
  private tab: number = 0;
  private loading: boolean = false;

  @Watch("tab")
  onTabChange(latest: number, depracated: number) {
    if (!isNaN(latest) && latest !== depracated) {
      this.changeTab(latest + 1);
    }
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get shouldDisplayReferencesNavTab() {
    switch (this.tenant) {
      case "demo":
      case "nsah": {
        return true;
      }

      default: {
        return false;
      }
    }
  }

  private async created() {
    this.toggleLoader(true);
    this.references = await this.getReferences();
    this.toggleLoader(false);

    const $el = document.getElementById(this.$el.id);
    if ($el) {
      const $unusedTabContentEl = $el.querySelector(".tab-content");
      if ($unusedTabContentEl) {
        $unusedTabContentEl.remove();
      }
    }
  }

  private async getReferences(tabID: number = 1) {
    return await reference.top(tabID);
  }

  private toggleTrees(opts: {
    treeId: number | string;
    parent: object | null;
  }) {
    const { treeId, parent } = opts;
    EventBus.$emit("toggleTrees", { treeId, parent: null });
  }

  private async changeTab(tabID: number = 1) {
    this.toggleLoader(true);
    this.references = await this.getReferences(tabID);

    this.toggleLoader(false);
  }

  private toggleLoader(state: boolean) {
    this.loading = state;
  }

  private hideSidebar() {
    this.$store.dispatch("layout/toggleSidebar", {
      side: "right",
      state: false,
    });
  }
}
