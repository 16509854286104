






















































































































import { Component, Vue } from "vue-property-decorator";
import { Agent, Caller } from "@/types/domain";
import { QueueState } from "@/types/store";
import { queue as queueService } from "@/services/queue";
import { EventBus } from "@/eventBus";
import conf, { session as sessionConfig, voice as voiceConfig } from "@/config";
import cloneDeep from "lodash/cloneDeep";
import startCase from "lodash/startCase";
import capitalize from "lodash/capitalize";
import TakeFromQueueModal from "./TakeFromQueue.vue";
import Buddy from "./Buddylist/Buddy.vue";
import isPlainObject from "lodash/isPlainObject";
import { agent } from "../../../services/agent";
import { failure } from "../../../config/helpers";

@Component({
  components: {
    Buddy,
    TakeFromQueueModal,
  },
})
export default class Sidebar extends Vue {
  private s3Baseurl: string = process.env.VUE_APP_S3_BASEURL || "";
  private onRotationIconImgSrc: string = `${this.s3Baseurl}/icons/on_rotation.svg`;
  private offRotationIconImgSrc: string = `${this.s3Baseurl}/icons/off_rotation.svg`;
  private showQueueModal: boolean = false;
  private takeFromQueueActionDisabled: boolean = false;
  private selectedQueue: QueueState | null = null;
  private queueIntervals: Record<string, NodeJS.Timeout> = {};
  private queueNotificationSoundStatus: Record<string, 0 | 1> = {};
  private queueNotificationSoundPlaylist: {
    interval?: NodeJS.Timeout;
    library: Array<{ label: string; sound: HTMLAudioElement }>;
  } = { library: [] };

  private get agents(): Agent[] {
    return this.$store.getters["agent/online"];
  }

  mounted() {
    this.initializeQueueNotificationSoundPlaylist();
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  get queues() {
    const queues = this.$store.getters["queue/all"];
    Array.isArray(queues) &&
      queues.forEach((q: QueueState, index: number) => {
        if (![0, 1].includes(this.queueNotificationSoundStatus[q.label])) {
          this.$set(this.queueNotificationSoundStatus, q.label, 1);
        }

        const queueNotificationSound = this.getQueueNotificationSound(q.sound);

        if (q.size > 0) {
          const queueNotificationSoundStatus = this.getQueueNotificationSoundStatus(
            q.label
          );

          this.queueIntervals[q.label] &&
            clearInterval(this.queueIntervals[q.label]);

          this.queueIntervals[q.label] = setInterval(() => {
            const checkSoundState =
              queueNotificationSound && queueNotificationSoundStatus;

            const checkSessionStatus = ![
              voiceConfig.status.incoming,
              voiceConfig.status.answering,
              voiceConfig.status.ongoing,
              voiceConfig.status.wrappingUp,
              voiceConfig.status.handingOff,
            ].includes(this.callStatus);

            const checkExistingPush = this.isInQueueNotificationSoundPlaylist(
              q.label
            );

            if (checkSoundState && checkSessionStatus && !checkExistingPush) {
              this.$set(
                this.queueNotificationSoundPlaylist,
                "library",
                this.queueNotificationSoundPlaylistLibrary.concat({
                  label: q.label,
                  sound: queueNotificationSound,
                })
              );
            }
          }, 5 * 1000);
        } else {
          this.queueIntervals[q.label] &&
            clearInterval(this.queueIntervals[q.label]);
          this.removeQueueNotificationSoundFromPlaylist(q.label);
        }
      });

    return Array.isArray(queues) ? queues : [];
  }

  get queueNoRotationStatus(): Record<string, 0 | 1> {
    if (!Array.isArray(this.queues)) return {};
    const noRotationStatuses: Record<number, boolean> = this.$store.getters[
      "session/queueRotations"
    ];

    return this.queues.reduce(
      (statuses: Record<string, 0 | 1>, queue: QueueState) => {
        if (
          isPlainObject(noRotationStatuses) &&
          typeof noRotationStatuses[queue.id] === "boolean"
        ) {
          return Object.assign({}, statuses, {
            [queue.label]: Number(noRotationStatuses[queue.id]),
          });
        }

        return Object.assign({}, statuses, {
          [queue.label]: Number(
            isPlainObject(noRotationStatuses) &&
              [null, undefined, 1, true].includes(noRotationStatuses[queue.id])
          ),
        });
      },
      {} as Record<string, 0 | 1>
    );
  }

  private showQueueNoRotationStatusToggle(queue: QueueState) {
    const never: number[] = [];
    return (
      ["ncvc", "NCVC", "demo", "DEMO", "nsah", "NSAH"].includes(this.tenant) &&
      [conf.agent.roles.vas, conf.agent.roles.supervisor].includes(this.role) &&
      !never.includes(queue.id)
    );
  }

  private async toggleQueueNoRotationStatus(
    queue: QueueState,
    status: boolean
  ) {
    try {
      const noRotationStatuses: Record<number, boolean> = this.$store.getters[
        "session/queueRotations"
      ];
      const payload = Object.assign(
        {},
        isPlainObject(noRotationStatuses) ? noRotationStatuses : {},
        { [queue.id]: status }
      );
      await agent.updateQueueNoRotationStatuses(payload);
    } catch (error) {
      console.error(
        "an error occured on attempt to update queue rotation statuses",
        error
      );
      failure(
        this,
        `Error: unable to update \'Rotation\' status for the ${this.formatQueueButtonLabel(
          queue.label
        )} queue.`
      );
    }
  }

  private initializeQueueNotificationSoundPlaylist() {
    this.queueNotificationSoundPlaylist.interval &&
      clearInterval(this.queueNotificationSoundPlaylist.interval);

    this.queueNotificationSoundPlaylist.interval = setInterval(() => {
      const library = this.queueNotificationSoundPlaylistLibrary;
      if (Array.isArray(library) && library.length) {
        library.forEach((s, idx) => {
          const timeout = setTimeout(() => {
            const checkSessionStatus = ![
              voiceConfig.status.incoming,
              voiceConfig.status.answering,
              voiceConfig.status.ongoing,
              voiceConfig.status.wrappingUp,
              voiceConfig.status.handingOff,
            ].includes(this.callStatus);

            if (
              checkSessionStatus &&
              this.queueNotificationSoundPlaylist.interval &&
              this.queueNotificationSoundPlaylistLibrary.find(
                (item) => item.label === s.label
              ) &&
              this.queueNotificationSoundStatus[s.label]
            ) {
              try {
                s.sound.play();
              } catch (error) {
                console.warn("queue sound play failed", s, error);
              } finally {
                this.removeQueueNotificationSoundFromPlaylist(s.label);
              }
            } else {
              console.warn(
                "queue notification sound predicate failed",
                new Date().toISOString(),
                checkSessionStatus,
                this.queueNotificationSoundPlaylist.interval,
                this.queueNotificationSoundPlaylistLibrary.find(
                  (item) => item.label === s.label
                ),
                this.queueNotificationSoundStatus[s.label]
              );
            }
            clearTimeout(timeout);
          }, (idx + 1.5) * 1000);
        });
      }
    }, 30 * 1000);
  }

  get queueNotificationSoundPlaylistLibrary() {
    const collection = this.queueNotificationSoundPlaylist.library;
    return Array.isArray(collection) ? collection : [];
  }

  private getQueueNotificationSound(queueSound: string) {
    if (typeof queueSound === "string" && queueSound.trim()) {
      try {
        return new Audio(queueSound.trim());
      } catch (error) {
        console.warn(
          "failed to fetch specified queue sound, will use default.mp3 for notification sound",
          error
        );
        return new Audio();
      }
    } else {
      return new Audio();
    }
  }

  private getQueueNotificationSoundStatus(queueLabel: string) {
    const status = this.queueNotificationSoundStatus[queueLabel];
    if (![0, 1].includes(status)) {
      this.$set(this.queueNotificationSoundStatus, queueLabel, 1);
      return 1;
    }

    return status;
  }

  private toggleQueueNotificationSoundStatus(queueLabel: string) {
    const status = this.queueNotificationSoundStatus[queueLabel];
    if (![0, 1].includes(status) || status === 1) {
      this.$set(this.queueNotificationSoundStatus, queueLabel, 0);
    } else {
      this.$set(this.queueNotificationSoundStatus, queueLabel, 1);
    }
  }

  private isInQueueNotificationSoundPlaylist(queue: string) {
    return Boolean(
      this.queueNotificationSoundPlaylistLibrary.find((s) => s.label === queue)
    );
  }

  private removeQueueNotificationSoundFromPlaylist(queue: string) {
    this.$set(
      this.queueNotificationSoundPlaylist,
      "library",
      this.queueNotificationSoundPlaylistLibrary.filter(
        (s) => s.label !== queue
      )
    );
    return this.queueNotificationSoundPlaylistLibrary;
  }

  get role() {
    return this.$store.getters["session/role"];
  }

  get dnd() {
    return this.$store.getters["session/status"] === conf.agent.statuses.dnd;
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  private queueSizeBtnBg() {
    return this.dnd ? "background: #F8AA4B;" : "background: #3986e3;";
  }

  private async takeFromQueue(queueButton: QueueState) {
    if (
      [conf.agent.roles.supervisor, conf.agent.roles.vas].includes(this.role)
    ) {
      this.showQueueModal = true;
      this.selectedQueue = queueButton;
    } else if (queueButton.size > 0) {
      const canTakeCallFromQueue =
        [null, voiceConfig.status.ready].includes(this.callStatus) &&
        [
          conf.agent.statuses.available,
          conf.agent.statuses.noRotation,
        ].includes(this.sessionStatus) &&
        !this.takeFromQueueActionDisabled;

      if (canTakeCallFromQueue) {
        try {
          const visitor: Caller | undefined = queueButton.visitors[0];
          await queueService.take(
            this.$store.getters["session/id"],
            visitor ? visitor.id : null
          );

          this.takeFromQueueActionDisabled = true;
          const timeout = setTimeout(() => {
            this.takeFromQueueActionDisabled = false;
            clearTimeout(timeout);
          }, 5000);
        } catch (error) {
          console.error("error occured while taking call from queue", error);
        }
      }
    }
  }

  private formatQueueButtonLabel(label: string) {
    if (typeof label === "string" && label.trim()) {
      return label.trim();
    }

    return "N/A";
  }

  private closeQueueModal() {
    this.showQueueModal = false;
    EventBus.$emit("reset-queue-modal-model");
  }

  private determineProp(agent: Agent) {
    // Passes the session as a prop if the buddy is the logged-in user.
    const session = this.$store.state.session;
    return agent.id === session.id ? session : agent;
  }

  private hideSidebar() {
    this.$store.dispatch("layout/toggleSidebar", {
      side: "left",
      state: false,
    });
  }

  private reset() {
    this.showQueueModal = false;
    this.takeFromQueueActionDisabled = false;
    this.selectedQueue = null;
    Object.values(this.queueIntervals).forEach(clearInterval);
    this.queueNotificationSoundPlaylist.interval &&
      clearInterval(this.queueNotificationSoundPlaylist.interval);
    this.queueNotificationSoundPlaylist = {
      library: [],
    };

    this.$set(this.$data, "queueNotificationSoundStatus", {});
  }

  beforeDestroy() {
    this.reset();
  }
}
