import cloneDeep from "lodash/cloneDeep";
import { MutationTree } from "vuex";
import { QueueState } from "@/types/store";
import { propset } from "@/config/helpers";
import findIndex from "lodash/findIndex";
import Vue from "vue";

const findQueueIndexByLabel = (queueCollection: QueueState[], label: string) =>
  findIndex(queueCollection, { label });

export const mutations: MutationTree<QueueState[]> = {
  setSize(state, payload: { label: string; size: number }) {
    const queueIdx: number = findQueueIndexByLabel(state, payload.label);
    if (queueIdx !== -1) {
      Vue.prototype.$set(state, queueIdx, {
        ...state[queueIdx],
        size: Number(payload.size)
      });
    }
  },
  setVisitors(state, payload: { label: string; visitors: any[] }) {
    const queueIdx: number = findQueueIndexByLabel(state, payload.label);
    if (queueIdx !== -1) {
      Vue.prototype.$set(state, queueIdx, {
        ...state[queueIdx],
        visitors: payload.visitors
      });
    }
  },
  setQueue(state, payload: QueueState) {
    const queueIdx: number = findQueueIndexByLabel(state, payload.label);
    if (queueIdx !== -1) {
      Vue.prototype.$set(state, queueIdx, payload);
    }
  },
  setQueues(state, payload: QueueState[]) {
    const visitorsCount = state.length;
    payload.forEach(queueItem => {
      state.push(queueItem);
    });
    state.splice(0, visitorsCount);
  },
  reset(state) {
    state.forEach((_: QueueState, index: number) => {
      state.splice(index, 1);
    });
  }
};
