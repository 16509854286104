



























import { Component, Vue, Prop, Emit } from "vue-property-decorator";
import ContentPanel from "./Content.vue";
import truncate from "lodash/truncate";
import startcase from "lodash/startCase";

@Component
export default class ModalListItem extends Vue {
  @Prop() private screen!: string;
  @Prop() private initials!: string;
  @Prop() private descriptor!: string;
  @Prop() private index!: string;
  @Prop() private name!: string;
  @Prop() private color!: string;
  @Prop() private info!: string;
  @Prop() private id!: string;
  @Prop() private selected!: boolean;
  private key!: any;

  @Emit("select")
  public selectItem(key: any) {
    return (this.key = key);
  }

  get formattedname() {
    switch (this.screen) {
      case "queue-modal": {
        const str =
          typeof this.name === "string" && this.name.trim()
            ? this.name.trim()
            : "N/A";

        return truncate(startcase(str), {
          length: 50,
          separator: /,? +/,
          omission: "..."
        });
      }

      default: {
        return this.name;
      }
    }
  }
}
