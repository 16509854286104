



















































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Multiselect from "vue-multiselect";
import voiceConfig from "@/config/voice";
import isPlainObject from "lodash/isPlainObject";
import isEmpty from "lodash/isEmpty";
import client from "@/services/client";
import conf, { events } from "@/config";
import {
  Client,
  CensusFormQuestion,
  CensusFormQuestionOption,
} from "@/types/domain";
import { EventBus } from "@/eventBus";

@Component({
  components: {
    Multiselect,
  },
})
export default class Census extends Vue {
  private errors: string[] = [];
  private questions: CensusFormQuestion[] = [];

  @Watch("rightSidebar", { immediate: true })
  private onSidebarToggle(status: boolean) {
    if (this.rightSidebar && this.isHandlingCall && this.noQuestionsData) {
      this.getCensusData();
    }
  }

  private mounted() {
    this.useSubscriptions();
  }

  private useSubscriptions() {
    EventBus.$on(events.call.start, () => {
      this.reset();
      this.getCensusData();
    });
    EventBus.$on(events.call.reset, this.reset);
  }

  get toggleErrors() {
    return Array.isArray(this.errors) && !isEmpty(this.errors);
  }

  get rightSidebar() {
    return this.$store.getters["layout/rightSidebar"];
  }

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get isHandlingCall() {
    return (
      [
        voiceConfig.status.ongoing,
        voiceConfig.status.wrappingUp,
        voiceConfig.status.handingOff,
        voiceConfig.status.supervising,
        voiceConfig.status.ended,
      ].includes(this.callStatus) &&
      [
        conf.agent.statuses.inCall,
        conf.agent.statuses.wrappingUpCall,
        conf.agent.statuses.supervisingCall,
        conf.agent.statuses.surveillingCall,
        conf.agent.statuses.assessingCall,
      ].includes(this.sessionStatus)
    );
  }

  get showForm() {
    return (
      this.shouldDisplayCensus &&
      Array.isArray(this.questions) &&
      !isEmpty(this.questions)
    );
  }

  get noQuestionsData() {
    return (
      this.shouldDisplayCensus &&
      Array.isArray(this.questions) &&
      isEmpty(this.questions)
    );
  }

  get client(): Client {
    return this.$store.getters["call/client"];
  }

  get clientId() {
    return isPlainObject(this.client) && this.client.id ? this.client.id : "";
  }

  get clientName(): string {
    return isPlainObject(this.client) && this.client.name
      ? this.client.name
      : "";
  }

  get shouldDisplayCensus() {
    const predicate = !["shl"].includes(this.tenant);
    return predicate;
  }

  get tenant() {
    return typeof conf.tenant === "string" && conf.tenant.trim()
      ? conf.tenant.trim()
      : "";
  }

  private async getCensusData() {
    if (this.clientId && this.shouldDisplayCensus) {
      client
        .getCensusData(this.clientId)
        .then((questions) => {
          this.questions = Array.isArray(questions) ? questions : [];
        })
        .catch((error) => {
          this.$buefy.toast.open({
            type: "is-danger",
            message: "Error: failed to retrieve census questions.",
          });
          this.questions = [];
          console.error("failed to load census questions", error);
        });
    }
  }

  get censusSubmission(): Record<string, string | number> | null {
    return this.$store.getters["call/census"];
  }

  get responses() {
    return this.censusSubmission &&
      isPlainObject(this.censusSubmission) &&
      !isEmpty(this.censusSubmission)
      ? this.censusSubmission
      : {};
  }

  private save() {
    this.$buefy.toast.open({
      type: "is-success",
      position: "is-top-right",
      message: "Cenus data saved.",
    });

    EventBus.$emit("toggle-right-sidebar-panel", 0);
  }

  private reset() {
    this.errors = [];
    this.questions = [];
  }

  private questionFreeformOptionLabel(
    question: CensusFormQuestion,
    option: CensusFormQuestionOption
  ) {
    if (question.input) return "";
    return `${question.description} - ${option.label}`;
  }
}
