const app = {
  tenant: process.env.VUE_APP_TENANT,
  baseUrl: process.env.VUE_APP_FRONTEND_BASEURL,
  apiUrl: `${process.env.VUE_APP_BACKEND_BASEURL}${process.env.VUE_APP_BACKEND_API_PATH}`,
  sseUrl: `${process.env.VUE_APP_BACKEND_BASEURL}${process.env.VUE_APP_BACKEND_SSE_PATH}`,
  appUrl: `${process.env.VUE_APP_BACKEND_BASEURL}${process.env.VUE_APP_BACKEND_APP_PATH}`,
  contexts: {
    voice: "THL",
    chat: "CHL",
    sms: "TXHL"
  }
};

export default app;
