





























import { Component, Vue } from "vue-property-decorator";
import { EventBus } from "@/eventBus";
import conf, { events, voice as voiceConfig } from "@/config";

@Component
export default class Notes extends Vue {
  private input: string = "";

  get callStatus() {
    return this.$store.getters["call/status"];
  }

  get sessionStatus() {
    return this.$store.getters["session/status"];
  }

  get isHandlingCall() {
    return (
      [
        voiceConfig.status.ongoing,
        voiceConfig.status.wrappingUp,
        voiceConfig.status.handingOff,
        voiceConfig.status.supervising,
        voiceConfig.status.ended
      ].includes(this.callStatus) &&
      [
        conf.agent.statuses.inCall,
        conf.agent.statuses.wrappingUpCall,
        conf.agent.statuses.supervisingCall,
        conf.agent.statuses.surveillingCall,
        conf.agent.statuses.assessingCall
      ].includes(this.sessionStatus)
    );
  }

  get placeholder() {
    return this.isHandlingCall
      ? ""
      : "Notepad will be enabled once a call begins";
  }

  get notes() {
    return this.$store.getters["call/notepad"];
  }

  private mounted() {
    this.reset();
    this.useSubscriptions();
  }

  private useSubscriptions() {
    EventBus.$on(events.call.start, () => {
      this.$store.dispatch("call/updateNotepad", "");
      this.reset();
    });
    EventBus.$on(events.call.reset, () => {
      this.$store.dispatch("call/updateNotepad", "");
      this.reset();
    });

    EventBus.$on(
      "client-tabs-panels-active-tab",
      (update: { previous: number; active: number }) => {
        if (
          update.previous === 5 &&
          update.active !== 5 &&
          !this.isHandlingCall
        ) {
          this.$store.dispatch("call/updateNotepad", "");
        } else if (this.isHandlingCall) {
          this.reset();
        }
      }
    );
  }
  private reset() {
    this.input = this.notes;
  }
}
