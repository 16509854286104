import _Vue from "vue";
import { events } from "@/config";
import { EventBus } from "@/eventBus";
import store from "@/store";
import { TwilioPlugin } from "@/types/twilio";
// import conf from "@/config";

import * as Twilio from "twilio-client/es5/twilio";
import { call } from "@/services/call";

export default function install(Vue: typeof _Vue): void {
  function broadcast(event: string, opts: any = null) {
    EventBus.$emit(event, opts);
  }

  const Device = new Twilio.Device();

  Device.on("ready", () => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    console.debug(
      "[TWILIO DEVICE EVENTS] twilio device is ready...",
      new Date().toISOString(),
      callStatus,
      sessionStatus
    );
    broadcast(events.twilio.ready);
  });

  Device.on("offline", () => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    console.debug(
      "[TWILIO DEVICE EVENTS] twilio device is offline",
      new Date().toISOString(),
      callStatus,
      sessionStatus
    );
    broadcast(events.twilio.offline);
  });

  Device.on("error", (error: any) => {
    console.error(
      "[TWILIO DEVICE EVENTS] twilio device error occurred",
      new Date().toISOString(),
      error
    );
  });

  Device.on("cancel", (conn: Twilio.Connection) => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    console.warn(
      "[TWILIO DEVICE EVENTS] twilio device connection cancellation event...",
      new Date().toISOString(),
      callStatus,
      sessionStatus,
      call.incomingTimer,
      conn.parameters.CallSid,
      conn.status(),
      conn.parameters,
      conn.callerInfo
    );
    broadcast(events.twilio.cancel);
  });

  Device.on("connect", (conn: Twilio.Connection) => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    console.warn(
      "[TWILIO DEVICE EVENTS]  twilio device connection event...",
      new Date().toISOString(),
      callStatus,
      sessionStatus,
      call.incomingTimer,
      conn.parameters.CallSid,
      conn.status(),
      conn.parameters,
      conn.callerInfo
    );
    broadcast(events.twilio.connect, conn);
  });

  Device.on("disconnect", (conn: Twilio.Connection) => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    console.warn(
      "[TWILIO DEVICE EVENTS] twilio device disconnection event...",
      new Date().toISOString(),
      callStatus,
      sessionStatus,
      call.incomingTimer,
      conn.parameters.CallSid,
      conn.status(),
      conn.parameters,
      conn.callerInfo
    );
    broadcast(events.twilio.disconnect, conn);
  });

  Device.on("incoming", (conn: Twilio.Connection) => {
    const callStatus = store.getters["call/status"];
    const sessionStatus = store.getters["session/status"];
    console.warn(
      "[TWILIO DEVICE EVENTS]  twilio device incoming connection event...",
      new Date().toISOString(),
      callStatus,
      sessionStatus,
      conn.parameters.CallSid,
      conn.status(),
      conn.parameters,
      conn.callerInfo
    );
    broadcast(events.twilio.incoming, conn);
  });

  const twilio: TwilioPlugin = {
    ref: Device,
    setup() {
      Device.setup(store.getters["session/capabilityToken"], {
        // debug: conf.env === "development",
        debug: true,
      });

      // const token = store.getters["session/capabilityToken"];
      // if (Device.isInitialized) {
      //   twilio.updateToken(token);
      // } else {
      //   Device.setup(store.getters["session/capabilityToken"], {
      //     debug: conf.env === "development",
      //   });
      // }
    },
    updateToken(token: string) {
      if (typeof token === "string" && token.trim().length) {
        Device.updateToken(token);
        return;
      }
    },
    destroy() {
      Device.destroy();
    },
    takeCall() {
      const connection = Device.activeConnection();
      const status = Device.status();

      if (![Twilio.Device.Status.Ready].includes(status)) {
        EventBus.$emit(
          "error-notification",
          "Error: unable to take incoming call. Invalid device state (must be 'ready')."
        );

        return;
      }

      const predicate =
        connection &&
        [
          Twilio.Connection.State.Pending,
          Twilio.Connection.State.Ringing,
          // Twilio.Connection.State.Connecting,
          // Twilio.Connection.State.Open,
        ].includes(connection.status());

      if (!predicate) {
        EventBus.$emit(
          "error-notification",
          "Error: unable to take incoming call. Invalid call state (must be either 'pending' or 'ringing')."
        );
        return;
      }

      try {
        const callStatus = store.getters["call/status"];
        const sessionStatus = store.getters["session/status"];
        console.warn(
          "[TWILIO DEVICE EVENT]... accepting call",
          new Date().toISOString(),
          callStatus,
          sessionStatus,
          call.incomingTimer,
          connection?.parameters.CallSid,
          connection?.status(),
          connection?.parameters,
          connection?.callerInfo
        );
        connection?.accept();
        return;
      } catch (error) {
        const err =
          error instanceof Error ? error.message : new Error(error).message;

        EventBus.$emit(
          "error-notification",
          `Error: unexpected error occurred while attempting to accept incoming call. Details: ${err}`
        );

        console.error(
          "[TWILIO DEVICE EVENT] unexpected error occurred while attempting to accept incoming call",
          error
        );
        return;
      }
    },
    hangup() {
      Device.disconnectAll();
    },
    mute(status: boolean) {
      const conn = Device.activeConnection();
      if (conn) {
        conn.mute(status);
        return conn.isMuted();
      }
      return true;
    },
    getDeviceStatus() {
      try {
        if (!Device.isInitialized) {
          return "offline";
        }

        return Device.status();
      } catch (e) {
        console.error("error to get device status", e);
        return "offline";
      }
    },
    getConnection() {
      return Device.activeConnection();
    },
    getConnectionStatus() {
      const conn = Device.activeConnection();
      return (conn?.status() as string) || "";
    },
  };

  Vue.prototype.$twilio = twilio;
}
